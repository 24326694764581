import React, { Component } from 'react';
import { Table, Button, Breadcrumb, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import Uploader from './upload';
import { $get } from '../../helpers/remote';
import FetchMetric from './fetch-metric';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';

import './advertiser.less';
const { Search } = Input;

class Advertiser extends Component {
    state = {
        data: [],
        pagination: {
            pageSize: 20,
            page: 1,
        },
        loading: false,
        advertiserName: null,
        providerName: null,
        providerId: 0,
        fetchModalVisible: false,
        uploadModalVisible: false,
        searchValue: '',
    }
    async componentDidMount() {
        const advertiserId = this.props.match.params.advertiserId;
        const item = await $get(`/providers/0/advertisers/${advertiserId}`, {}, { hideLoading: true });
        this.setState({ advertiserName: item.name });
        const provider = await $get(`/providers/${item.providerId}`, {}, { hideLoading: true });
        this.setState({ providerName: provider.agencyName, providerId: provider.id });
        this.fetch();
    }
    handleTableChange = (pagination) => {
        this.setState({
            pagination: pagination,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current,
        });
    };
    get pagination() {
        return {
            ...this.state.pagination,
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100', '500', '1000'],
        };
    }
    get columns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '名称',
                dataIndex: 'name',
                render: (text, record) => <div className="name">
                    {record.portrait && <img src={`${record.portrait.replace('http://', 'https://')}/0/0`} alt={text} />}&nbsp;
                    {text}
                </div>
            },
            {
                title: 'appid',
                dataIndex: 'appId',
            },
            {
                title: '操作',
                render: (text, record) => <div className="operations">
                    <Button onClick={() => this.openUploadModal(record.id)}>投放</Button>
                </div>,
            },
        ];
    }
    fetch = async (params) => {
        const advertiserId = this.props.match.params.advertiserId;
        const { page, pageSize } = params || this.state.pagination;
        const name = this.state.searchValue;
        this.setState({ loading: true });
        const [metrics, count] = await $get(`/providers/0/advertisers/${advertiserId}/metrics`, { page, pageSize, name }, { hideLoading: true });
        if (count === 0) {
            this.setState({ fetchModalVisible: true });
        } else {
            const pagination = { ...this.state.pagination };
            pagination.total = count;
            this.setState({
                loading: false,
                data: metrics,
                pagination,
            });
        }
    };
    onFetchModalClose = () => {
        this.setState({ fetchModalVisible: false });
        this.fetch();
    }
    onCampaignModalClose = () => {
        this.setState({ campaignModalVisible: false });
    }
    openUploadModal = (metricId) => {
        this.setState({ uploadModalVisible: true, metricId })
    }
    onUploadModalClose = () => {
        this.setState({ uploadModalVisible: false });
    }

    handleSearch = async (value) => {
        this.setState({
            searchValue: value,
        },
            () =>  this.fetch()
        );
    }

    render() {
        const { advertiserName, providerName, fetchModalVisible, uploadModalVisible, metricId } = this.state;
        const { providerId, advertiserId } = this.props.match.params;
        return <div className="page-provider">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/providers">服务商列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{providerName || ''}</Breadcrumb.Item>
                <Breadcrumb.Item>{advertiserName || ''}</Breadcrumb.Item>
            </Breadcrumb>
            <Button icon={<ReloadOutlined />} onClick={() => this.setState({ fetchModalVisible: true })}>刷新列表</Button>&nbsp;
            <Button icon={<PlusOutlined />} onClick={() => this.openUploadModal()}>批量添加投放计划</Button>
            <Search
                placeholder="请输入广告主名称"
                onSearch={value => this.handleSearch(value)}
                style={{ width: 200, margin: '20px' }}
            />
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.data}
                pagination={this.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
            />
            <FetchMetric visible={fetchModalVisible} providerId={providerId} advertiserId={advertiserId} onClose={this.onFetchModalClose} />
            <Uploader visible={uploadModalVisible} onClose={this.onUploadModalClose} metricId={metricId} advertiserId={advertiserId} providerId={providerId} />
        </div>
    }
}
export default withRouter(Advertiser);
