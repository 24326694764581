import { Button, Descriptions, Modal, Table } from 'antd';
import React from 'react';
import moment from 'moment';

const LogTypes = {
    'CREATE': '任务创建',
    'STOP': '任务暂停',
    'SYNC-FROM-MP': '从微信同步数据',
    'DELETE': '任务删除',
    'SYNC-TO-OUTER': '推送数据',
    'EDIT_STRATEGIES': '编辑策略',
    'RUN_STRATEGY_UNMEET': '策略条件不符合',
    'RUN_STRATEGY_MEET': '策略条件符合',
    'RUN_STRATEGY_NO_OPERATION': '计划已符合目标，跳过更改',
    'RUN_STRATEGY_OPERATION_SUCCESS': '策略执行成功',
    'RUN_STRATEGY_OPERATION_FAILED': '策略执行失败',
    'GET_PITCHER_STRATEGY': '命中投手包策略',
    'GET_ADVERTISER_STRATEGY': '命中广告主策略',
    'GET_AI_STRATEGY': '命中智能策略',
    'GET_JOB_STRATEGY': '命中计划策略',
    'GET_BASIC_STRATEGY': '命中基础包策略',
    'STRATEGY_AI_SUCCESS': '智能策略条件符合',
    'STRATEGY_AI_UNMEET': '智能策略条件不符合',
    'EDIT_STRATEGY_GROUP': '修改计划策略组',
}

export default function JobLogs({ logs }) {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            render: type => {
                return LogTypes[type] || type;
            },
        },
        {
            title: '时间',
            dataIndex: '__time__',
            render: text => moment(Number(text) * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '操作',
            key: 'operations',
            render(_, record) {
                const msg = JSON.parse(record.data || '{}');
                return <Button onClick={() => {
                    Modal.info({
                        title: '日志详情',
                        width: '80%',
                        content: <div>
                            <Descriptions>
                                {
                                    Object.keys(msg).map(key => {
                                        let content = msg[key];
                                        if (typeof content === 'object') {
                                            content = JSON.stringify(content);
                                        }
                                        return <Descriptions.Item label={key} key={key}>
                                            {content}
                                        </Descriptions.Item>
                                    })
                                }
                            </Descriptions>
                        </div>
                    });
                }}>查看详情</Button>
            }
        }
    ]

    return (
        <Table
            pagination={false}
            dataSource={logs}
            rowKey="id"
            columns={columns}
        />
    )
}