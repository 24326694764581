import { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import './material-preview.less';

export default class MaterialPreview extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        url: PropTypes.string,
        urls: PropTypes.arrayOf(PropTypes.string),
        width: PropTypes.number,
        height: PropTypes.number,
    }
    static defaultProps = {
        width: 250,
    }
    render() {
        const { type, url, urls = [], width, height } = this.props;
        if (type === 'IMAGES' || type === 'MULTIPLE') {
            return <div className="ui-material-preview-images">
                {urls.map(u => 
                    u.includes('.mp4')
                    ?
                        <Popover key={u} placement="right" content={<video width="200" src={u} alt="预览" className="popover" controls />}>
                            <video className="image" src={u} alt="预览"/>
                        </Popover>
                    :
                        <Popover key={u} placement="right" content={<img src={u} alt="预览" className="popover" />}>
                            <img className="image" src={u} alt="预览"/>
                        </Popover>
                )}
            </div>
        }
        return <div className="ui-material-preview">
            {
                type === 'VIDEO'
                    ? <video controls width={width} height={height}>
                        <source src={url} type="video/mp4" />
                    </video>
                    : <Popover placement="right" content={<img src={url} alt="预览" className="popover" />}>
                        {
                            height === 'undefined' ?
                                <img style={{ maxWidth: `${width}px`, height: 'auto' }} src={url} alt="预览"/>
                                :
                                <img style={{ maxWidth: `${width}px`, height: `${height}px` }} src={url} alt="预览"/>
                        }
                    </Popover>
            }
        </div>
    }
}
