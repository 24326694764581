import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Collapse, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { $get, $post, $put } from '../../helpers/remote';
import StrategyEditorForm from './edit-form';

const { Panel } = Collapse;
export default function StrategyGroupEditor({ strategyGroupId: id, onFinish, isSelf }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [strategyGroup, setStrategyGroup] = useState(null);
    const [form] = Form.useForm();
    const [copyData, setCopyData] = useState('');

    useEffect(() => {
        if (visible && id) {
            $get(`/strategy-groups/${id}`).then(setStrategyGroup);
        }
    }, [visible, id]);

    const formData = useMemo(() => {
        if (!visible) {
            setStrategyGroup(null);
            form.resetFields();
            setShowDelete(false);
            return null;
        }
        return strategyGroup;
    }, [strategyGroup, visible, form]);

    const checkForm = () => {
        const values = form.getFieldValue();
        const { title } = values;
        const strategyId = Number(values.strategyId);
        const { strategies } = strategyGroup || {};
        if (!title) {
            throw new Error('请填写策略分组名称');
        }
        const strategyIds = strategies ? strategies.map(o => o.id) : [];
        return { title, strategyIds: strategyId ? [...strategyIds, strategyId] : strategyIds };
    }

    const handleCloseStrategy = async (e, strategy) => {
        e.stopPropagation();
        console.log('close ', strategy.id);
        const newStrategies = strategyGroup.strategies.filter(o => (o.id + o.title) !== (strategy.id + strategy.title));
        setStrategyGroup({ ...strategyGroup, strategies: newStrategies });
    }

    const handleInput = async () => {
        const values = form.getFieldValue();
        const strategyId = Number(values.strategyId);
        if (!strategyId) return;
        const strategies = strategyGroup ? strategyGroup.strategies : [];
        if (strategies.find(o => o.id === strategyId)) {
            message.error('请勿重复添加');
            return;
        }
        const strategy = await $get(`/strategies/${strategyId}`);
        if (!strategy) {
            message.error('填写的策略id不存在或已删除');
            return;
        }
        setStrategyGroup({ ...(strategyGroup || {}), strategies: [...strategies, strategy] });
        form.setFieldsValue({ strategyId: '' });
    }

    const handleSubmit = async () => {
        console.log(strategyGroup);
        let datas = {};
        try {
            datas = checkForm();
        } catch (err) {
            message.error(err.message);
            return;
        }
        setLoading(true);
        const formData = form.getFieldValue();
        if (!id) {
            try {
                await $post('/strategy-groups', datas, { throwException: 'showMessage' });
                setLoading(false);
                setVisible(false);
                onFinish && onFinish();
            } catch (e) {
                setLoading(false);
            }
        } else {
            try {
                await $put(`/strategy-groups/${id}`, datas, { throwException: 'showMessage' });
                setLoading(false);
                setVisible(false);
                onFinish && onFinish();
            } catch (e) {
                setLoading(false);
            }
        }
        form.setFieldsValue(formData);
    }

    const handleCancel = async (e) => {
        e.stopPropagation();
        setShowDelete(false);
    }

    const handleAddStrategy = () => {
        setCopyData('');
        const strategies = strategyGroup !== null ? strategyGroup.strategies : [];
        setStrategyGroup({ ...strategyGroup, strategies: [...strategies, { title: `新建策略${new Date().getTime()}` }] });
    }

    const getStrategyChange = (values, title) => {
        const id = values.id;
        let strategies = strategyGroup.strategies;
        let index = strategyGroup.strategies.findIndex(o => o.id === id);
        if (index === -1) {
            index = strategyGroup.strategies.findIndex(o => o.title === title);
        }
        strategies[index] = values;
        setStrategyGroup({ ...strategyGroup, strategies});
    }

    const copyClick = (strategy) => {
        const newStrategy = {
            ...strategy,
            title: `${strategy.title}【复制#${strategy.id}】`
        }
        if (!newStrategy.execTimeGroups || !newStrategy.execTimeGroups.length) {
            newStrategy.execTimeGroups = [];
        }
        delete newStrategy.id;
        setStrategyGroup({
            ...strategyGroup,
            strategies: [
                ...strategyGroup.strategies,
                newStrategy
            ]
        })
        setCopyData(newStrategy);
    }
    return (
        <>
            {id ? <Button onClick={() => setVisible(true)}>{isSelf ? '编辑' : '查看'}</Button> : <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setVisible(true)}
            >添加策略分组</Button>}
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                destroyOnClose
                footer={null}
                width={800}
                title={isSelf ? id ? '编辑策略分组' : '添加策略分组' : '查看策略分组'}
            >
                {(id && !formData) ? null : <div className="ui-strategy-editor">
                    <Form initialValues={formData} form={form}>
                        <Form.Item 
                            label="分组名称" 
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入分组名称'
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            label="策略ID" 
                            name="strategyId"
                        >
                            <Input onPressEnter={handleInput} />
                        </Form.Item>
                        {
                            strategyGroup && strategyGroup.strategies && strategyGroup.strategies.length 
                            ? 
                                <Collapse accordion>
                                    {strategyGroup.strategies.map(o => <>
                                        <Panel 
                                            header={`${o.title}，id:${o.id ? o.id : '暂无'}`}
                                            key={o.id}
                                            style={{position: 'relative'}}
                                            extra={isSelf && <>
                                                <Popconfirm
                                                    title="确认移除策略？"
                                                    visible={showDelete === o.id + o.title}
                                                    onCancel={(e) => handleCancel(e)}
                                                    onConfirm={(e) => handleCloseStrategy(e, o)}
                                                    key={o.id + '1'}
                                                />
                                                <CloseOutlined onClick = { e => {
                                                    e.stopPropagation();
                                                    setShowDelete(o.id + o.title);
                                                }} key={o.id + '2'}/>
                                            </>}>
                                            {isSelf && <div style={{ position: 'absolute', top: '6px', right: '45px' }}>
                                                <Button onClick={() => copyClick(o)} type="primary" key={o.id + 'b'}>复制</Button>
                                            </div>}
                                            <StrategyEditorForm strategyId={o.id} visible={visible} onFinish={onFinish} setVisible={null} key={o.id + '3'} isSelf={isSelf} getStrategyChange={getStrategyChange} copyData={copyData} title={o.title}/>
                                        </Panel>
                                    </>)}
                                </Collapse>
                            :
                                ''
                        }
                        {isSelf && <div>
                            <Button onClick={handleAddStrategy} type="primary" loading={loading}>添加策略</Button>
                            &emsp;&emsp;<Button onClick={handleSubmit} type="primary" loading={loading}>{id ? '修改' : '保存'}</Button>
                        </div>}
                    </Form>
                </div>}
            </Modal>
        </>
    )
}
