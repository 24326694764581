import React, { useEffect, useState, useRef } from 'react';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Select, message, Cascader } from 'antd';
import UUID from 'readableuuid';
import { conditionTypes } from './conditions';

import './condition-group.less';

export default function ConditionGroup({ value, onChange, conditions, isAi }) {
    const valueInitedRef = useRef(null);
    const [groups, setGroups] = useState([]);
    const defaultConditionType = conditionTypes.find(o => o.default);

    const handleAddGroup = () => {
        setGroups(prev => [...prev, {
            id: UUID(),
            conditions: [
                {
                    id: UUID(),
                    error: true,
                }
            ],
        }]);
    }

    const handleAddCondition = (groupId) => {
        const group = groups.find(g => g.id === groupId);
        if (!group) {
            throw new Error('group未找到:' + groupId);
        }
        setGroups(prev => prev.map(g => {
            if (g.id !== groupId) {
                return g;
            }
            return {
                ...g,
                conditions: [
                    ...g.conditions,
                    {
                        id: UUID(),
                        error: true,
                    }
                ]
            }
        }))
    }

    const handleDelCondition = (groupId, conditionId) => {
        const group = groups.find(g => g.id === groupId);
        if (!group) {
            throw new Error('group未找到:' + groupId);
        }
        setGroups(prev => prev.map(g => {
            if (g.id !== groupId) {
                return g;
            }
            return {
                ...g,
                conditions: g.conditions.filter(o => o.id !== conditionId)
            }
        }))
        setGroups(prev => prev.filter(g => g.conditions.length));
    }

    const handleConditionChange = (groupId, conditionId, key, value) => {
        // 每次更改选择初始化输入框|选择框
        if (key === 'name') {
            // 清除当前条件  保存index
            let index = 0;
            const conditions = groups.find(o => o.id === groupId).conditions.filter((c,i) => {
                if (c.id === conditionId) {
                    index = i;
                } else {
                    return true;
                }
                return false;
            });
            // 插入一个新的条件
            conditions.splice(index, 0, {
                    id: UUID(),
                    [key]: value,
                    value: ''
                });
            setGroups(groups.map(o => {
                if (o.id !== groupId) {
                    return o;
                }
                return {
                    ...o,
                    conditions
                }
            }));
        }
        setGroups(groups => {
            return groups.map(group => {
                if (group.id !== groupId) {
                    return group;
                }
                return {
                    ...group,
                    conditions: group.conditions.map(condition => {
                        if (condition.id !== conditionId) {
                            return condition;
                        }
                        return {
                            ...condition,
                            [key]: value,
                        };
                    })
                }
            });
        })
    }

    const checkValue = ({ groupId, conditionId, name, value, operator }) => {
        if (!name) {
            return;
        }
        if (Array.isArray(value)) {
            value = value[value.length-1];
        }
        const condition = groups.find(o => o.id === groupId)?.conditions.find(o => o.id === conditionId);
        const val = value === undefined ? condition.value : value;
        const op =  operator === undefined ? condition.operator : operator;
        const type = conditions.find(o => o.name === name)?.type;
        let result = true;
        let msg = '';
        if (type !== 'enum' && !op && type !== 'pos_enum') {
            result = false;
            msg = '请选择操作符';
        }
        const rule = conditions.find(o => o.name === name)?.rule;
        // 版位清空为数组，单独判断。
        if (rule && Array.isArray(val)) {
            result = false;
            msg = rule.msg || '请填写完整';
        };
        if (rule && !rule.reg.test(val)) {
            result = false;
            msg = rule.msg || '请填写完整';
        }
        if (result) {
            handleConditionChange(groupId, conditionId, 'error', false);
        } else {
            message.error(msg);
            handleConditionChange(groupId, conditionId, 'error', true);
        }
    }

    useEffect(() => {
        onChange(groups);
    }, [groups, onChange]);

    useEffect(() => {
        if (!valueInitedRef.current) {
            valueInitedRef.current = true;
            setGroups(value || []);
        }
    }, [value]);

    return <div className="strategy-condition-groups">
        {groups.map((group, gindex) => <div className={`group ${gindex === groups.length - 1 ? 'last' : ''}`} key={group.id}>
            {group.conditions.map((condition, cindex) => { 
                const CONDITION = conditions.find(o => o.name === condition.name);
                return <div className="condition" key={condition.id}>
                    { group.conditions.length > 1 && <div className="label">{cindex !== 0 && '并且'}</div> }
                    <Select 
                        className="type"
                        disabled={isAi}
                        value={(condition && conditions.find(o => o.name === condition.name)?.conditionType) || defaultConditionType?.value} 
                        onChange={(value) => {
                        handleConditionChange(group.id, condition.id, 'name', conditions.find(o => o.conditionType === value).name);
                    }}>
                        {conditionTypes.map(c => <Select.Option value={c.value} key={c.value}>{c.label}</Select.Option>)}
                    </Select>
                    <div className="space" />
                    <Select 
                        className={condition.error ? 'error' : ''}
                        value={condition.name} 
                        onChange={(value) => {
                        handleConditionChange(group.id, condition.id, 'name', value);
                        if (CONDITION?.type === 'enum') {
                            handleConditionChange(group.id, condition.id, 'error', true);
                        }
                    }}>
                        {(conditions.filter(o => condition && condition.name 
                            ? conditions.find(c => c.name === condition.name).conditionType === o.conditionType 
                            : defaultConditionType.value === o.conditionType
                        )).map(c => <Select.Option value={c.name} key={c.name}>{c.label}</Select.Option>)}
                    </Select>
                    <div className="space" />
                    {
                        CONDITION?.type === 'enum'
                        ? 
                            <Select
                                className={condition.error ? 'error': ''}
                                value={condition.value}
                                style={{ width: 220 }}
                                onChange={(value) => {
                                    handleConditionChange(group.id, condition.id, 'value', value);
                                    checkValue({ groupId: group.id, conditionId: condition.id, name: condition.name, value });
                                }}
                            >
                                {
                                    CONDITION?.options.map(o => <Select.Option value={o.value} key={o.value}>{ o.title }</Select.Option>)
                                }
                            </Select>
                        : CONDITION?.type === 'pos_enum'
                        ?
                            <Cascader
                              placeholder="请选择版位"
                              defaultValue={condition.value}
                              options={CONDITION?.options}
                              changeOnSelect
                              onChange={(value) => {
                                    handleConditionChange(group.id, condition.id, 'value', value);
                                    checkValue({ groupId: group.id, conditionId: condition.id, name: condition.name, value: value });
                              }}
                            >
                            </Cascader>
                        :
                            <>
                                <Select
                                    className={condition.error ? 'error' : ''}
                                    value={condition.operator}
                                    style={{ width: 220 }}
                                    onChange={(value) => {
                                        handleConditionChange(group.id, condition.id, 'operator', value);
                                        checkValue({ groupId: group.id, conditionId: condition.id, name: condition.name, operator: value });
                                    }}
                                >
                                    <Select.Option value="<">小于</Select.Option>
                                    <Select.Option value="<=">小于或等于</Select.Option>
                                    <Select.Option value=">">大于</Select.Option>
                                    <Select.Option value=">=">大于或等于</Select.Option>
                                    <Select.Option value="!=">不等于</Select.Option>
                                </Select>
                                <div className="space" />
                                <Input
                                    className={condition.error ? 'error' : ''}
                                    value={condition.value}
                                    onChange={(e) => {
                                        e.persist();
                                        handleConditionChange(group.id, condition.id, 'value', e.target.value)
                                    }}
                                    onBlur={(e) => {
                                        checkValue({ groupId: group.id, conditionId: condition.id, name: condition.name, value: e.target.value });
                                    }}
                                />
                            </>
                    }
                    <div className="space" />
                    <Button type="text" onClick={() => handleDelCondition(group.id, condition.id)} icon={<CloseOutlined />}></Button>
                </div>}
            )}
            <Button onClick={() => handleAddCondition(group.id)} icon={<PlusCircleOutlined />}>添加“并且”</Button>
        </div>)}
        <div>
            <Button onClick={handleAddGroup} icon={<PlusCircleOutlined />}>添加"或"组</Button>
        </div>
    </div>
}