const STEPS = [
    'INIT',
    'START',
    'CHECK',
    'FETCH_DMP',
    'PREPARE_TEMPLATE',
    'SEARCH_BEHAVIOR_INTEREST',
    'PREPARE_MATERIAL',
    'GET_CAMPAIN_TOKEN',
    'CREATE_CAMPAIN',
    'CREATE_LANDING_PAGE',
    'WARMUP_MATERIAL',
    'FETCH_CAMPAIN',
    'UPLOAD_IMAGE',
    'PREPARE_MATERIAL_TEMPLATE',
    'UPDATE_MATERIAL',
    'SUBMIT_MATERIAL',
];
export default STEPS;