import React, { useState, useEffect, useCallback } from 'react';
import { Modal, InputNumber, Button, Result } from 'antd';
import moment from 'moment';
import { $post } from '../../helpers/remote';

export default function SyncJob({ visible, onClose }) {
    const [dayCount, setDayCount] = useState(3);
    const [syncCount, setSyncCount] = useState(0);
    const [syncErrorCount, setSyncErrorCount] = useState(0);
    const [syncing, setSyncing] = useState(false);
    const [lastJob, setLastJob] = useState(null);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (visible) {
            setDayCount(3);
            setSyncCount(0);
            setSyncErrorCount(0);
            setSyncing(false);
            setLastJob(null);
            setFinished(false);
        }
    }, [visible]);

    const fetch = useCallback(async () => {
        if (!visible) {
            return;
        }
        try {
            const job = await $post('/jobs/sync', { dayCount, lastId: lastJob ? lastJob.id : null }, { throwException: true, hideLoading: true });
            if (job) {
                setLastJob(job);
                setSyncCount(syncCount + 1);
            } else {
                setFinished(true);
            }
        } catch (e) {
            setSyncErrorCount(syncErrorCount + 1);
        }
    }, [visible, lastJob, syncCount, syncErrorCount, dayCount]);

    useEffect(() => {
        if (syncing && !finished) {
            fetch(lastJob);
        }
    }, [syncing, lastJob, syncErrorCount, finished, fetch])

    const startSyncing = () => {
        setSyncing(true);
    }

    return <Modal
        visible={visible}
        onCancel={onClose}
        title="手动刷新数据"
        footer={null}
    >
        {!syncing && !finished && <div>
            <div>刷新几天内的数据，默认3</div>
            <div><InputNumber value={dayCount} onChange={value => setDayCount(value)} /></div>
            <div><Button onClick={startSyncing}>开始刷新</Button></div>
        </div>}
        {syncing && !finished && <div>
            <p>正在刷新，目前已刷新数量：{syncCount}</p>
            <p>上一个任务日期：{lastJob ? moment(lastJob.initedAt).format('MM-DD HH:mm') : ''}</p>
            <p>失败数量：{syncErrorCount}</p>
        </div>}
        {finished && <Result
            status="success"
            title="刷新完成"
            subTitle={`共刷新成功${syncCount},失败${syncErrorCount}`}
        />}
    </Modal>
}
