import { Form, Select, Radio, Input, Button, Modal, Tag, Tooltip, message, Popconfirm } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { $get, $patch, $delete } from '../../helpers/remote';
import './game.less';

export default function BindStrategyGroup({ strategyGroupId: id, strategyGroupType, onClose }) {
    const formRef = useRef(React.Component(Form));
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gameTypes, setGameTypes] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [formData, setFormData] = useState({});
    const [jobs, setJobs] = useState([]);
    const [strategyGroups, setStrategyGroups] = useState([]);
    const [radioEnable, setRadioEnable] = useState(false);
    const [advertisers, setAdvertisers] = useState([[], 0]);
    const [bindAdvertisers, setBindAdvertisers] = useState([]);

    useEffect(() => {
        if (visible) {
            const hide = message.loading('加载中...', 0);
            $get(`/strategies/game-types`, {}, { hideLoading: true }).then(async(res) => {
                await getAdvertisers();
                await getBindAdvertisers();
                const initData = { type: 'PITCHER' };
                setGameTypes(res);
                if (id) {
                    const s = await $get(`/strategy-groups/${id}`, {}, { hideLoading: true });
                    if (s) {
                        const type = getType(s);
                        if (type === 'FREE') {
                            setRadioEnable(true);
                        }
                        if (type === 'JOB') {
                            formRef.current.setFieldsValue({
                                type
                            });
                            setFormData({ ...initData, type });
                            setJobs(s.jobs);
                        } else if (type === 'FREE') {
                            formRef.current.setFieldsValue(initData);
                            setFormData(initData);
                        } else if (type === 'PITCHER' || type === 'BASIC') {
                            const gameId = s.game.gameId;
                            const gameInfos = await $get(`/strategies/game-info?gameId=${gameId}`, {}, { hideLoading: true });
                            if (!gameInfos || !gameInfos.length) {
                                message.error('未找到策略绑定的游戏包');
                                return;
                            }
                            const gameTypeId = gameInfos[0].gameTypeId;
                            getGameList(gameTypeId);
                            formRef.current.setFieldsValue({
                                type: type,
                                gameTypeId,
                                gameId,
                            });
                            setFormData({ ...initData, gameId, gameTypeId, type });
                        } else if (type === 'ADVERTISER') {
                            const advertiserIds = s.advertisers && s.advertisers.length ? s.advertisers.map(o => o.id) : [];
                            formRef.current.setFieldsValue({
                                type: type,
                                advertiserIds,
                            });
                            setFormData({ ...initData, advertiserIds, type });
                        } else {
                            message.error('未知类型');
                        }
                    }
                } else {
                    setRadioEnable(true);
                    formRef.current.setFieldsValue(initData);
                    setFormData(initData);
                }
                hide();
            });
        }
    }, [visible, id]);

    async function getBindAdvertisers() {
        const bindAdvertisers = await $get('/strategy-groups/advertisers/bind');
        setBindAdvertisers(bindAdvertisers || []);
    }

    function getType(s) {
        if (s.jobs.length) return 'JOB';
        if (s.game || (s.advertisers && s.advertisers.length) || s.gameGroup) {
            return s.type;
        }
        return 'FREE';
    }

    async function getGameList(gameTypeId) {
        if (!gameTypeId) return;
        $get(`/strategies/games?gameTypeId=${gameTypeId}`).then(setGameList);
    }

    async function getAdvertisers() {
        const advertisers = await $get(`/advertisers`, {}, { hideLoading: true });
        setAdvertisers(advertisers);
    }

    function handleChange(values) {
        const data = { ...formData, ...values };
        setFormData(data);
        if (values.gameTypeId && (values.gameTypeId !== formData.gameTypeId)) {
            getGameList(values.gameTypeId);
            formRef.current.setFieldsValue({
                gameId: '',
                gameGroupId: ''
            });
            setFormData({ ...data, gameId: '', gameGroupId: '' });
        }
    }

    async function handleSubmit() {
        // 绑定策略
        // 如游戏包／计划已绑定过策略，则覆盖，重新绑定
        const values = await formRef.current.validateFields();
        if (!values) {
            return;
        }
        setLoading(true);
        console.log(values);
        const { type, gameId, advertiserIds } = values;
        let strategyGroupIds = [];
        if (id) {
            strategyGroupIds = [id]; 
        } else {
            const strategyGroupId = Number(values.strategyGroupId);
            strategyGroupIds = strategyGroups.map(o => o.id);
            if (strategyGroupId && !strategyGroupIds.includes(strategyGroupId)) {
                strategyGroupIds.push(strategyGroupId);
            }
        }
        if (strategyGroupIds.length > 1) {
            message.error('每条计划／游戏包只能绑定一个策略包');
            return;
        }
        let errorCount = 0;
        if (type === 'JOB') {
            // 绑定计划策略
            const customId = Number(values.customId);
            const customIds = jobs.map(o => o.customId);
            if (customId && !customIds.includes(customId)) {
                customIds.push(customId);
            }
            // 将原绑定的计划解绑
            // await $delete(`/strategy-groups/${id}/jobs`);
            // 绑定策略
            try {
                await $patch(`/jobs/strategy-group`, { strategyGroupId: strategyGroupIds[0], customIds }, { hideLoading: true, throwException: true });
            } catch (e) {
                message.error(e.response && e.response.data && e.response.data.message) || String(e);
                errorCount++;
            }
        } else if (type === 'PITCHER' || type === 'BASIC') {
            // 绑定游戏包策略
            const gameName = gameList.find(o => o.id === gameId).game_name;
            try {
                // 将原绑定的游戏解绑
                await $delete(`/strategy-groups/${id}/game`);
                await $patch(`/strategy-groups/games/${gameId}/strategy-group`, { type, strategyGroupId: strategyGroupIds[0], gameName }, { hideLoading: true, throwException: true });
            } catch (e) {
                message.error(e.response && e.response.data && e.response.data.message) || String(e);
                errorCount++;
            }
        } else if (type === 'ADVERTISER') {
            try {
                await $patch(`/strategy-groups/advertisers`, { advertiserIds, strategyGroupId: strategyGroupIds[0] }, { hideLoading: true, throwException: true })
            } catch(e) {
                message.error(e.response && e.response.data && e.response.data.message) || String(e);
                errorCount++;
            }
        }
        setLoading(false);
        if (!errorCount) {
            message.success('策略绑定成功');
            setRadioEnable(false);
            //handleClose();
        }
    }

    async function handleUnbind() {
        if (!id || !strategyGroupType) {
            message.error('id和策略类型不能为空');
            return;
        }
        if (strategyGroupType === 'JOB') {
            await $delete(`/strategy-groups/${id}/jobs`);
        } else if (strategyGroupType === 'ADVERTISER'){
            await $patch(`/strategy-groups/advertisers`, { strategyGroupId: id, advertiserIds: [] });
        } else {
            await $delete(`/strategy-groups/${id}/game`);
        }
        message.success('解绑成功');
        onClose();
    }

    function handleInput(){
        if (!/^\d+$/.test(formData.customId)) return;
        const customId = Number(formData.customId);
        if (jobs.find(o => o.customId === customId)) {
            message.error('请勿重复添加');
            return;
        }
        setJobs([...jobs, { customId }]);
        formRef.current.setFieldsValue({
            customId: ''
        });
        setFormData({ ...formData, customId: '' });
    }

    function handleCloseJob(job) {
        const newJobs = jobs.filter(o => o.customId !== job.customId);
        setJobs(newJobs);
    }

    function handleStrategyGroupIdInput(){
        if (!/^\d+$/.test(formData.strategyGroupId)) return;
        const strategyGroupId = Number(formData.strategyGroupId);
        if (strategyGroups.find(o => o.id === strategyGroupId)) {
            message.error('请勿重复添加');
            return;
        }
        setStrategyGroups([...strategyGroups, { id: strategyGroupId }]);
        formRef.current.setFieldsValue({
            strategyGroupId: ''
        });
        setFormData({ ...formData, strategyGroupId: '' });
    }

    function handleCloseStrategy(strategy) {
        console.log('close ', strategy.id);
        const newStrategyGroups = strategyGroups.filter(o => o.id !== strategy.id);
        setStrategyGroups(newStrategyGroups);
        console.log(newStrategyGroups);
    }

    function handleClose() {
        setVisible(false);
        formRef.current.resetFields();
        setJobs([]);
        setStrategyGroups([]);
        onClose && onClose();
    }
    function advertisersChange(advertiserIds) {
        if (advertiserIds && advertiserIds.length) {
            const filterAdvertiserIds = advertiserIds.filter(o => !bindAdvertisers.find(b => b.id === o && b.strategyGroup.id !== id));
            formRef.current.setFieldsValue({
                advertiserIds: filterAdvertiserIds || []
            });
            setFormData({ ...formData, advertiserIds: filterAdvertiserIds || [] });
        }
    }
    
    return (
        <>
            <Button
                style={{marginLeft: '10px'}}
                onClick={() => setVisible(true)}
            >{id ? '绑定' : '批量绑定策略'}</Button>
            { id && (strategyGroupType === 'JOB' || strategyGroupType === 'PITCHER' || strategyGroupType === 'BASIC' || strategyGroupType === 'ADVERTISER')
                ?
                <Popconfirm title="删除任务后不可恢复，是否删除？" onText="确认删除" cancelText="点错了" onConfirm={() => handleUnbind()} >
                    <Button danger style={{marginLeft: '10px'}}>解绑</Button>
                </Popconfirm>
                : ''
            }
            <Modal
                className="strategy-select-game"
                visible={visible}
                onCancel={() => handleClose()}
                destroyOnClose
                footer={null}
                width={800}
                title={id ? `绑定策略#${id}` : '批量绑定策略'}
            >
                <Form onValuesChange={handleChange} ref={formRef}>
                    { id ? '' : <>
                        <Form.Item 
                            label="策略ID" 
                            name="strategyGroupId"
                            rules={[
                                {
                                    required: strategyGroups.length ? false : true,
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        if (!value && strategyGroups.length) {
                                            return Promise.resolve();
                                        }
                                        if (/^\d+$/.test(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject('策略ID');
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input onPressEnter={handleStrategyGroupIdInput} />
                        </Form.Item>
                        <div style={{margin: '20px 0'}}>
                        {
                            strategyGroups.length 
                            ? 
                                strategyGroups.map((o, i) => <Tooltip title={o.title} placement="bottom" key={o.id}>
                                    <Tag closable onClose={() => handleCloseStrategy(o)}>{o.id}</Tag>
                                </Tooltip>)
                            :
                                ''
                        }
                        </div>
                    </>
                    }
                    <Form.Item label="策略类型" name="type">
                        <Radio.Group disabled={!radioEnable}>
                            <Radio value={'JOB'}>计划策略组</Radio>
                            <Radio value={'ADVERTISER'}>广告主策略</Radio>
                            <Radio value={'PITCHER'}>游戏包投手策略组</Radio>
                            <Radio value={'BASIC'}>游戏包基础策略组</Radio>
                        </Radio.Group>
                    </Form.Item>
                    { formData.type === 'PITCHER' || formData.type === 'BASIC'
                    ?
                        <div className="flex">
                            <Form.Item 
                                label="游戏" 
                                name="gameTypeId"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择游戏',
                                    },
                                ]}
                            >
                                <Select 
                                    filterOption={true}
                                    placeholder="请选择游戏"
                                >
                                    {gameTypes.map(c => <Select.Option value={c.id} key={c.id}>{c.game_name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item 
                                label="游戏包" 
                                name="gameId"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择游戏包',
                                    },
                                ]}
                            >
                                <Select 
                                    filterOption={true}
                                    placeholder="请选择游戏包"
                                >
                                    {gameList.map(c => <Select.Option value={c.id} key={c.id}>{c.game_name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                    : formData.type === 'JOB'
                    ?
                        <>
                            <Form.Item 
                                label="计划自定义ID" 
                                name="customId"
                                rules={[
                                    {
                                        required: jobs.length ? false : true,
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (!value && jobs.length) {
                                                return Promise.resolve();
                                            }
                                            if (/^\d+$/.test(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('自定义ID错误');
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input onPressEnter={handleInput} />
                            </Form.Item>
                            <div style={{marginBottom: '20px'}}>
                            {
                                jobs.length 
                                ? 
                                    jobs.map((o, i) => <Tooltip title={o.campain?.campainName} placement="bottom" key={o.customId}>
                                        <Tag closable onClose={() => handleCloseJob(o)}>{o.customId}</Tag>
                                    </Tooltip>)
                                :
                                    ''
                            }
                            </div>
                        </>
                    : formData.type === 'ADVERTISER'
                    ?
                        <div className="flex">
                            <Form.Item 
                                label="广告主" 
                                name="advertiserIds"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择广告主',
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder="请选择广告主"
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    onChange={(data) => advertisersChange(data)}
                                    style={{ width: '80%' }}
                                    filterOption={(input, option) =>
                                        option.children.includes(input) ? option : ''
                                    }
                                >
                                    {
                                        advertisers.map(c => 
                                            <Select.Option className={bindAdvertisers.find(b => b.id === c.id) ? 'disabled' : ''} value={c.id} key={c.id}>
                                                {`${c.provider.type}-${c.provider.agencyName}-${c.name}-${c.uid}`}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    : ''
                    }
                    <Button onClick={handleSubmit} type="primary" loading={loading}>{id ? '修改' : '保存'}</Button>
                </Form>
            </Modal>
        </>
    )
}

