import React, { Component } from 'react';
import _ from 'lodash';
import JobsContainer from '../../components/jobs';
import './index.less';
import { Button, Switch, Collapse, message } from 'antd';
import Filter from './filter';
import SyncJob from './sync-job';
import { $get } from '../../helpers/remote';
import moment from 'moment';

export default class Jobs extends Component {
    state = { filter: {}, syncVisible: false, users: [], userProfile: null };
    onFilterChange = _.debounce((data) => {
        if (data.title) {
            if (!data.createdAt || !data.createdAt.length) {
                message.warning('请补充开始时间和结束时间');
                return;
            }
            if (data.createdAt && data.createdAt.length > 1) {
                if (moment(data.createdAt[1]).diff(data.createdAt[0], 'days') > 7) {
                    message.warning('时间间隔不能超过7天');
                    return;
                }
            }
        }
        this.setState({
            filter: {
                status: data.status || 'all',
                reviewStatus: data.reviewStatus !== 'all' ? Number(data.reviewStatus) : undefined,
                createdAtStart: data.createdAt && data.createdAt[0] ? data.createdAt[0].startOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
                createdAtEnd: data.createdAt && data.createdAt[1] ? data.createdAt[1].endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
                title: data.title,
                id: data.id ? Number(data.id) : undefined,
                customId: data.customId ? Number(data.customId) : undefined,
                userId: data.userId
            },
        });
    }, 400)
    componentDidMount() {
        this.getProfile();
    }
    onSyncClose = () => this.setState({ syncVisible: false })
    getAllUsers = async () => {
        const res = await $get('/users/authorizedUsers');
        this.setState({
            users: res || []
        })
    }
    getProfile = async () => {
        const res = await $get('/users/profile');
        this.setState({
            userProfile: res || null
        })
        if (res && res.groupId) {
            await this.getAllUsers();
        }
    }
    render() {
        const { customData, showStatistic, filter, syncVisible, users, userProfile } = this.state;
        return <div className="page-job">
            <Collapse defaultActiveKey={['filter']} style={{ marginBottom: '20px' }}>
                <Collapse.Panel header="操作" key="operation">
                    <Button onClick={() => this.setState({ syncVisible: true })}>手动刷新统计数据</Button>
                </Collapse.Panel>
                <Collapse.Panel header="数据展示" key="column">
                    <div className="filter">
                        展示微信统计数据：<Switch checked={showStatistic} onChange={value => this.setState({ showStatistic: value })} />
                        展示自定义统计数据：<Switch checked={customData} onChange={value => this.setState({ customData: value })} />
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header="筛选" key="filter">
                    <Filter onChange={this.onFilterChange} users={users} userProfile={userProfile} />
                </Collapse.Panel>
            </Collapse>
            <JobsContainer
                filter={filter}
                customData={customData}
                showStatistic={showStatistic}
                users={users}
                userProfile={userProfile}
            />
            <SyncJob visible={syncVisible} onClose={this.onSyncClose} />
        </div>
    }
}
