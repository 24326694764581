import React, { Component } from 'react';
import { Table, Button, message } from 'antd';
import moment from 'moment';
import { copyIntoClipboard } from'../../utils/js/copy';
import { $get } from '../../helpers/remote';

export default class AdminUser extends Component {
    state = {
        providers: [],
        loading: false,
    }

    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        this.setState({ loading: true });
        const providers = await $get('/admin/providers');
        const pagination = { ...this.state.pagination };
        pagination.total = providers.length;
        this.setState({ providers, loading: false, pagination });
    }
    copyToken = (record) => {
        if (!record.latestTime) {
            message.error('无最新token，请扫码后重试');
        }
        const text = `
            function setCookie(key, value) {
                let cookie = \`$\{key}=$\{value}; domain=.a.weixin.qq.com\`;
                document.cookie = cookie;
            };
            setCookie('MMAD_TICKET', '${record.MMAD_TICKET}');
            setCookie('MM_TICKET', '${record.MM_TICKET}');
            setCookie('data_ticket', '${record.data_ticket}');
            setCookie('open_id', '${record.open_id}');
            setCookie('ADUSER_OPEN_ID', '${record.ADUSER_OPEN_ID}');
            setCookie('data_bizuin', '${record.data_bizuin}');
        `
        copyIntoClipboard(text);
    }
    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '名称',
                dataIndex: 'name',
            },
            {
                title: '所属用户ID',
                dataIndex: 'userId',
            },
            {
                title: '所属用户手机号',
                dataIndex: 'userPhone',
            },
            {
                title: '最近一次的登录时间',
                dataIndex: 'latestTime',
                render: time => time ? moment(time).format('YYYY-MM-DD HH:mm') : '无'
            },
            {
                title: '操作',
                render: (_, record) => {
                    return <div className="provider-operation">
                        <Button onClick={() => this.copyToken(record)}>复制最新token</Button>
                    </div>;
                }
            }
        ];
        return columns;
    }
    render() {
        return <div className="page-admin-provider">
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.providers}
                loading={this.state.loading}
                pagination={this.state.pagination}
            />
        </div>
    }
}