import React, { Component } from 'react';
import { Table, DatePicker, Radio } from 'antd';
import moment from 'moment';

import { $get } from '../../helpers/remote';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default class StrategyStats extends Component {
    state = {
        strategies: [],
        loading: false,
        dateRange: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        formatDate: { startDate: moment().subtract(1, 'day').format(dateFormat), endDate: moment().subtract(1, 'day').format(dateFormat) },
        dateOption: '',
        dateOptions: [
            { label: '昨天', value: 'yesterday' },
            { label: '7天', value: '7days' },
            { label: '30天', value: '30days' },
        ],
        pagination: {
            pageSize: 20,
            page: 1,
        },
    }
    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.dateRange.join(',') !== this.state.dateRange.join(',')) {
            console.log('fetch', prevState.dateRange);
            const startDate = this.state.dateRange[0].format(dateFormat);
            const endDate = this.state.dateRange[1].format(dateFormat);
            this.setState({
                formatDate: { startDate, endDate }
            });
            this.fetch({ startDate, endDate });
        }
    }

    async fetch({
        startDate,
        endDate
    } = this.state.formatDate) {
        console.log()
        const pagination = { ...this.state.pagination };
        this.setState({ loading: true });
        const strategies = await $get('/strategies/stats', { startDate, endDate });
        pagination.total = strategies.length;
        this.setState({ strategies, loading: false, pagination });
    }

    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'strategyId',
            },
            {
                title: '策略名称',
                dataIndex: 'title',
            },
            {
                title: '查询次数',
                dataIndex:'runNum',
                sorter: (a, b) => a.runNum - b.runNum,
            },
            {
                title: '满足条件次数',
                dataIndex:'meetNum',
                sorter: (a, b) => a.meetNum - b.meetNum,
            },
            {
                title: '执行成功次数',
                dataIndex:'successNum',
                sorter: (a, b) => a.successNum - b.successNum,
            },
            {
                title: '执行失败次数',
                dataIndex:'failNum',
                sorter: (a, b) => a.failNum - b.failNum,
            },
            {
                title: '不用执行次数',
                dataIndex:'emptyNum',
                sorter: (a, b) => a.emptyNum - b.emptyNum,
            },
        ];
        return columns;
    }
    render() {
        return <div className="page-admin-excel">
            <Radio.Group
                style={{margin: "10px"}}
                options={this.state.dateOptions}
                onChange={(e) => {
                    const value = e.target.value;
                    this.setState({
                        dateOption: value
                    });
                    let dateRange = [];
                    if (value === 'yesterday') {
                        dateRange = [moment().subtract(1, 'day'), moment().subtract(1, 'day')]; 
                    } else if (value === '7days') {
                        dateRange = [moment().subtract(8, 'day'), moment().subtract(1, 'day')]; 
                    } else if (value === '30days') {
                        dateRange = [moment().subtract(31, 'day'), moment().subtract(1, 'day')]; 
                    }
                    this.setState({
                        dateRange
                    });
                }}
                value={this.state.dateOption}
                optionType="button"
                buttonStyle="solid"
            />
            <RangePicker 
                allowClear={false}
                ranges={{
                    '昨天': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                }}
                value={this.state.dateRange}
                format={dateFormat}
                onChange={(e) => {
                    this.setState({
                        dateRange: [e[0], e[1]],
                    });
                }}
            />
            <Table
                columns={this.columns}
                rowKey={record => record.strategyId}
                dataSource={this.state.strategies}
                loading={this.state.loading}
                pagination={this.state.pagination}
            />
        </div>
    }
}