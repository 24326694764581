import React, { Component } from 'react';
import JobsContainer from '../../components/jobs';

export default class AdminJob extends Component {
    render() {
        return <div className="page-admin-job">
            <JobsContainer
                fetchUrl="/admin/jobs"
            />
        </div>
    }
}