import React, { Component } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export default class UploadItem extends Component {
    state = {
        file: null,
        files: [],
    }
    beforeUpload = () => {
        return false;
    }
    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    }
    onRemove = (e) => {
        const { files } = this.state;
        const newFiles = files.filter(file => e.uid !== file.uid);
        this.setState({ files: newFiles })
        this.triggerChange(newFiles);
    }
    handleChange = ({ fileList }) => {
        this.setState({ files: fileList });
        this.triggerChange(fileList);
    }
    render() {
        const { files } = this.state;
        return <Upload
            accept=".mp4,.jpg,.jpeg,.png"
            fileList={files}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
            onRemove={this.onRemove}
            multiple
        >
            <Button><UploadOutlined /> 选择图片或视频</Button>
        </Upload>
    }
}