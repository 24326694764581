import React, { useEffect, useRef, useState } from 'react';
import { PlusCircleOutlined, CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Input, Select, Radio, DatePicker, message, Popover } from 'antd';
import UUID from 'readableuuid';
import operations from './operations';
import moment from 'moment';

import './operation-group.less';

const { RangePicker } = DatePicker;

const timeList = new Array(25).fill(0).map((o, time) => time > 9 ? `${time}:00` : `0${time}:00`);

export default function OperationGroup({ value, onChange }) {
    const [groups, setGroups] = useState([]);
    const valueInitedRef = useRef(false);

    const handleAddGroup = () => {
        setGroups(prev => [...prev, {
            id: UUID(),
            operations: [
                {
                    id: UUID(),
                    error: true,
                }
            ],
        }]);
    }

    const handleAddOperation = (groupId) => {
        const group = groups.find(g => g.id === groupId);
        if (!group) {
            throw new Error('group未找到:' + groupId);
        }
        setGroups(prev => prev.map(g => {
            if (g.id !== groupId) {
                return g;
            }
            return {
                ...g,
                operations: [
                    ...g.operations,
                    {
                        id: UUID(),
                        error: true,
                    }
                ]
            }
        }))
    }

    const handleDelOperation = (groupId, operationId) => {
        setGroups(groups => {
            return groups.map(group => {
                if (group.id !== groupId) {
                    return group;
                }
                return {
                    ...group,
                    operations: group.operations.filter(operation => operation.id !== operationId) 
                }
            })
        });
        setGroups(prev => prev.filter(g => g.operations.length));
    }

    const checkRule = (rule, value, valueType) => {
        if (value === undefined) {
            return false;
        }
        if (rule) {
            if (valueType === 'date') {
                if (!Array.isArray(value) || value.length !== 2 || !value.every(o => rule.reg.test(o))) {
                    return false;
                }
                const today = moment().format(dateFormat);
                if (moment(value[0]) - moment(today) < 0) {
                    return false;
                }
            } else if (valueType === 'time') {
                if (!Array.isArray(value) || value.length !== 2 || !value.every(o => rule.reg.test(o))) {
                    return false;
                }
                if (Number(value[0].substr(0, 2)) - Number(value[1].substr(0,2)) >= 0) {
                    return false;
                }
            } else {
                if (!rule.reg.test(value)) {
                    return false;
                }
            }
        } else {
            if (valueType === 'string' && !value) {
                return false;
            }
        }
        return true;
    }

    const checkValue = ({ groupId, operationId, name, value, value2, value4 }) => {
        const operation = operations.find(o => o.name === name);
        const { rule, rule2, rule4, valueType, value2Type, value4Type } = operation;
        const operationData = groups.find(o => groupId === o.id).operations.find(o => o.id === operationId);
        let r = value === undefined ? rule2 : rule;
        const val = value === undefined ? operationData.value : value;
        const val2 = value2 === undefined ? operationData.value2 : value2;
        const val4 = value4 === undefined ? operationData.value4 : value4;
        let result = true;
        if (value2Type === undefined) {
            result = checkRule(rule, val, valueType);
        } else if (value4Type === undefined) {
            result = checkRule(rule, val, valueType) && checkRule(rule2, val2, value2Type);
        } else {
            result = checkRule(rule, val, valueType) && checkRule(rule2, val2, value2Type) && checkRule(rule4, val4, value4Type);
            if (!checkRule(rule2, val2, value2Type)) {
                r = rule2;
            } else {
                r = rule4;
            }
        }
        if (result) {
            handleOperationChange(groupId, operationId, 'error', false);
        } else {
            message.error((r && r.msg) || '请填写完整');
            handleOperationChange(groupId, operationId, 'error', true);
        }
    }

    const handleOperationChange = (groupId, operationId, key, value) => {
        setGroups(groups => {
            return groups.map(group => {
                if (group.id !== groupId) {
                    return group;
                }
                return {
                    ...group,
                    operations: group.operations.map(operation => {
                        if (operation.id !== operationId) {
                            return operation;
                        }
                        return {
                            ...operation,
                            [key]: value,
                        };
                    })
                }
            });
        })
    }

    useEffect(() => {
        onChange(groups);
    }, [groups, onChange]);

    useEffect(() => {
        if (!valueInitedRef.current) {
            valueInitedRef.current = true;
            setGroups(value || []);
        }
    }, [value]);
    const dateFormat = 'YYYY-MM-DD';
    return <div className="strategy-operation-groups">
        {groups.map((group, gindex) => <div className={`group ${gindex === groups.length - 1 ? 'last' : ''}`} key={group.id}>
            {group.operations.map((operation, cindex) => <div className="operation" key={operation.id}>
                { group.operations.length > 1 && <div className="label">{cindex !== 0 && '然后'}</div> }
                <Select 
                    className={operation.error ? 'error' : ''}
                    value={operation.name}
                    style={{ width: 240 }}
                    onChange={value => {
                        const OPERATION = operations.find(o => o.name === value);
                        handleOperationChange(group.id, operation.id, 'name', value);
                        if (OPERATION?.valueType === 'none') {
                            handleOperationChange(group.id, operation.id, 'error', false);
                        } else {
                            handleOperationChange(group.id, operation.id, 'error', true);
                        }
                        if (value === 'updateDate' || value === 'updateRelativeDate') {
                            handleOperationChange(group.id, operation.id, 'value', 2);
                        } else {
                            handleOperationChange(group.id, operation.id, 'value', '');
                        }
                        if (OPERATION?.value2Type) {
                            handleOperationChange(group.id, operation.id, 'value2', '');
                        }
                        if (OPERATION?.value3Type) {
                            handleOperationChange(group.id, operation.id, 'value3', 1);
                        }
                        if (OPERATION?.value4Type) {
                            handleOperationChange(group.id, operation.id, 'value4', ['00:00', '24:00']);
                        }
                    }}>
                    {operations.map(c => <Select.Option value={c.name} key={c.name}>{c.label}</Select.Option>)}
                </Select>
                <div className="space" />
                {operations.find(o => o.name === operation.name)?.valueType === 'string' && <Input
                    className={operation.error ? 'error' : ''}
                    value={operation.value}
                    onChange={(e) => {
                        e.persist();
                        handleOperationChange(group.id, operation.id, 'value', e.target.value);
                    }}
                    onBlur={e => {
                        checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value: e.target.value });
                    }}
                    placeholder={operations.find(o => o.name === operation.name)?.placeholder}
                />}
                {operations.find(o => o.name === operation.name)?.valueType === 'radio' &&  <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <Radio.Group value={operation.value}
                        onChange={(e) => {
                            handleOperationChange(group.id, operation.id, 'value', e.target.value);
                            handleOperationChange(group.id, operation.id, 'value2', '');
                            handleOperationChange(group.id, operation.id, 'error', true);
                        }}
                    >
                        <Radio value={1}>时间段</Radio>
                        <Radio value={2}>长期投放</Radio>
                    </Radio.Group>
                    {
                        operations.find(o => o.name === operation.name)?.value2Type === 'date' 
                        ? 
                            operation?.value === 1
                            ? 
                                <RangePicker 
                                    allowClear={false}
                                    className={operation.error ? 'error' : ''}
                                    ranges={{
                                        '今天': [moment(), moment()],
                                        '明天': [moment().add(1, 'day'), moment().add(1, 'day')],
                                    }}
                                    value={operation.value2 && [moment(operation.value2[0], dateFormat), moment(operation.value2[1], dateFormat)]}
                                    format={dateFormat}
                                    style={{flex: 1}}
                                    onChange={(e) => {
                                        const dateRange = [moment(e[0]).format(dateFormat), moment(e[1]).format(dateFormat)];
                                        handleOperationChange(group.id, operation.id, 'value2', dateRange);
                                        checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value2: dateRange });
                                    }}
                                />
                            :
                                <DatePicker 
                                    allowClear={false}
                                    className={operation.error ? 'error' : ''} 
                                    defaultValue={moment().add(1, 'day')}
                                    value={operation.value2 && moment(operation.value2[0], dateFormat)} 
                                    format={dateFormat}
                                    style={{flex: 1}}
                                    onChange={(e) => {
                                        const dateRange = [moment(e).format(dateFormat), 0];
                                        handleOperationChange(group.id, operation.id, 'value2', dateRange);
                                        checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value2: dateRange });
                                    }}
                                />
                        :   
                            ''
                    }
                    {/* 修改相对时间的输入框 */}
                    {operations.find(o => o.name === operation.name)?.value2Type === 'string' && <Input
                        className={operation.error ? 'error' : ''}
                        value={operation.value2}
                        onChange={(e) => {
                            e.persist();
                            handleOperationChange(group.id, operation.id, 'value2', e.target.value)
                        }}
                        onBlur={(e) => {
                            checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value2: e.target.value });
                        }}
                        placeholder={operations.find(o => o.name === operation.name)?.placeholder2}
                    />}
                    </div>
                    <hr style={{borderColor: '#fefefe'}} />
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio.Group value={operation.value3}
                            onChange={(e) => {
                                handleOperationChange(group.id, operation.id, 'value3', e.target.value);
                                if (e.target.value === 1) {
                                    handleOperationChange(group.id, operation.id, 'value4', ['00:00', '24:00']);
                                } else {
                                    handleOperationChange(group.id, operation.id, 'value4', '');
                                }
                            }}
                        >
                            <Radio value={1}>全天</Radio>
                            <Radio value={2}>选择时段</Radio>
                        </Radio.Group>
                        {
                            operations.find(o => o.name === operation.name)?.value4Type === 'time' 
                            ? 
                                operation?.value3 === 2
                                ? 
                                    <div>
                                        <Select 
                                            className={operation.error ? 'error' : ''}
                                            value={operation.value4 && operation.value4[0]}
                                            style={{ width: 90 }}
                                            placeholder="开始时间"
                                            onChange={value => {
                                                const timeRange = operation.value4 || [];
                                                timeRange[0] = value;
                                                handleOperationChange(group.id, operation.id, 'value4', timeRange);
                                                checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value4: timeRange });
                                            }}>
                                            {timeList.map(time => <Select.Option value={time} key={time}>{time}</Select.Option>)}
                                        </Select>
                                        <Select 
                                            className={operation.error ? 'error' : ''}
                                            value={operation.value4 && operation.value4[1]}
                                            style={{ width: 90 }}
                                            placeholder="结束时间"
                                            onChange={value => {
                                                const timeRange = operation.value4 || [];
                                                timeRange[1] = value;
                                                handleOperationChange(group.id, operation.id, 'value4', timeRange);
                                                checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value4: timeRange });
                                            }}>
                                            {timeList.map(time => <Select.Option value={time} key={time}>{time}</Select.Option>)}
                                        </Select>
                                    </div>
                                :
                                    ''
                            :   
                                ''
                        }
                    </div>
                </div>
                }
                {/* 飞书通知的输入框 */}
                {operations.find(o => o.name === operation.name)?.valueType === 'string' &&
                operations.find(o => o.name === operation.name)?.value2Type === 'string' &&
                <Input
                    style={{ marginLeft: 15 }}
                    className={operation.error ? 'error' : ''}
                    value={operation.value2}
                    onChange={(e) => {
                        e.persist();
                        handleOperationChange(group.id, operation.id, 'value2', e.target.value)
                    }}
                    onBlur={(e) => {
                        checkValue({ groupId: group.id, operationId: operation.id, name: operation.name, value2: e.target.value });
                    }}
                    placeholder={operations.find(o => o.name === operation.name)?.placeholder2}
                />}
                {
                    operations.find(o => o.name === operation.name)?.tips &&
                    <Popover content={operations.find(o => o.name === operation.name)?.tips} title="提示">
                        <InfoCircleFilled style={{ marginLeft: 5 }} />
                    </Popover>
                }
                <Button type="text" style={{marginLeft: 'auto'}} onClick={() => handleDelOperation(group.id, operation.id)} icon={<CloseOutlined />}></Button>
            </div>)}
            <Button onClick={() => handleAddOperation(group.id)} icon={<PlusCircleOutlined />}>添加动作</Button>
        </div>)}
        <div>
            <Button onClick={handleAddGroup} icon={<PlusCircleOutlined />}>添加动作组</Button>
        </div>
    </div>
}