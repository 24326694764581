import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, Result } from 'antd';
import { $get, $post } from '../../helpers/remote';

export default class AddOe extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    }
    state = {
        loading: false,
        visible: false,
        agencyName: '',
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetch();
        }
    }
    fetch = async () => {
        this.setState({ loading: true, image: null, uuid: null, agencyName: '' });
        const { image, uuid } = await $post('/providers/login/oe', {}, { hideLoading: true });
        this.setState({
            image, uuid,
            loading: false,
        });
        this.queryConfirm();
    }
    onClose = () => {
        this.props.onClose(this.state.agencyName ? true : false);
    }
    queryConfirm = async () => {
        const { uuid } = this.state;
        const { visible } = this.props;
        if (!uuid || !visible) {
            return;
        }
        let result;
        try {
            result = await $get('/providers/login/oe/confirm', { uuid }, { hideLoading: true, throwException: true });
        } catch (e) {
            console.log(e.response);
        }
        if (uuid !== this.state.uuid) {
            // 过期请求
            return;
        }
        if (!result) {
            return setTimeout(this.queryConfirm, 500);
        }
        this.setState({ agencyName: result.agencyName })
    }
    render() {
        const { visible } = this.props;
        const { image, loading, agencyName } = this.state;
        return (
            <Modal
                title="扫码添加/更新服务商"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <div className="page-home-add">
                    {
                        agencyName && <Result
                            status="success"
                            title="账户更新成功"
                            subTitle={agencyName}
                        />
                    }
                    {
                        !agencyName ? <Spin spinning={loading}>
                            <div className="qr-code">
                                {image && <img src={`${image}`} alt="二维码" />}
                            </div>
                        </Spin> : null
                    }
                </div>
            </Modal>
        )
    }
}
