import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, Result, Button } from 'antd';
import { $post, $get } from '../../helpers/remote';

export default class AddAdqWxProvider extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    }
    state = {
        loading: false,
        visible: false,
        swichLoading: false,
        providers: [],
        accountName: null,
        tokenType: 'normal'
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetch();
        }
    }
    fetch = async () => {
        this.setState({ loading: true, image: null, uuid: null, accountName: null });
        const { image, uuid } = await $post('/providers/login/adq-wx', {}, { hideLoading: true });
        this.setState({
            image, uuid,
            loading: false,
        });
        this.queryConfirm();
    }
    onClose = () => {
        this.props.onClose(!!this.state.accountName);
    }
    queryConfirm = async () => {
        const { uuid } = this.state;
        const { visible } = this.props;
        if (!uuid || !visible) {
            return;
        }
        let result;
        try {
            result = await $get('/providers/login/adq-wx/confirm', { uuid }, { hideLoading: true, throwException: true });
        } catch (e) {
            console.log(e.response);
        }
        if (uuid !== this.state.uuid) {
            // 过期请求
            return;
        }
        if (!result) {
            return setTimeout(this.queryConfirm, 5000);
        }
        this.setState({ token: {
            gdtToken: result.gdtToken,
            gdtProtect: result.gdtProtect
        } })
        this.setState({ providers: result.providers });
    }
    onSwitchAgency = async (id) => {
        const { providers, token, tokenType } = this.state;
        const provider = providers.find(o => o.account_id === id);
        await $post('/providers/login/switch/adq-wx', { token: { ...token, accountName: provider.account_name, accountType: provider.account_type }, bmId: id, tokenType, adType: 'adq-new-wx'});
        this.setState({ accountName: provider.account_name });
    }
    onSwitchAll = async() => {
        const { providers, token, tokenType } = this.state;
        this.setState({ swichLoading: true });
        for (const provider of providers) {
            await $post('/providers/login/switch/adq-wx', { token: { ...token, accountName: provider.account_name, accountType: provider.account_type }, bmId: provider.account_id, tokenType, adType: 'adq-new-wx'}, { hideLoading: true });
        }
        this.setState({
            swichLoading: false,
            accountName: '全部服务商',
        });
    }
    render() {
        const { visible } = this.props;
        const { image, loading, providers, accountName, swichLoading } = this.state;
        return (
            <Modal
                title="扫码添加/更新服务商"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <Spin spinning={swichLoading}>
                    <div className="page-home-add">
                        {
                            accountName && <Result
                                status="success"
                                title="服务商更新成功"
                                subTitle={accountName}
                            />
                        }
                        {
                            !accountName && providers.length ? <div>
                                <p> <Button onClick={() => this.onSwitchAll()}>更新全部</Button> </p>
                                {providers.map(o => <p key={o.account_id}>
                                    <Button onClick={() => this.onSwitchAgency(o.account_id)}>{o.account_name}</Button>
                                </p>)}
                            </div> : null
                        }
                        {
                            !accountName && !providers.length ? <Spin spinning={loading}>
                                <div className="qr-code">
                                    {image && <img src={`data:image/png;base64,${image}`} alt="二维码" />}
                                </div>
                            </Spin> : null
                        }
                    </div>
                </Spin>
            </Modal>
        )
    }
}
