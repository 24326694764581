import { Form, Select, Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { $put } from '../../helpers/remote';
import './game.less';

export default function BindStrategyAi({ strategyAi, onClose, games }) {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const { games } = strategyAi;
        form.setFieldsValue({ gameIds: games.map(o => o.gameId) });
    }, [form, strategyAi, visible])


    const handleSubmit = async () => {
        const { gameIds } = form.getFieldsValue();
        const filterGames = games.filter(o => gameIds.includes(o.id));
        setLoading(true);
        await $put(`/strategy-ai/${strategyAi.id}`, {
            ...strategyAi,
            games: filterGames.map(o => ({ gameId: o.id, gameName: o.game_name }))
        })
        setLoading(false);
        setVisible(false);
        onClose();
    }

    const handleClose = () => {
        setVisible(false);
    }

    return (
        <>
            <Button
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(true)}
            >绑定</Button>
            <Modal
                className="strategy-select-game"
                visible={visible}
                onCancel={() => handleClose()}
                destroyOnClose
                footer={null}
                width={800}
                title={`绑定策略#${strategyAi.id}`}
            >
                <Form form={form}>
                    <Form.Item
                        label="绑定游戏包"
                        name="gameIds"
                    >
                        <Select
                            placeholder="请选择游戏包"
                            mode="multiple"
                            style={{ width: 200, marginBottom: 10 }}
                            showSearch
                            filterOption={(input, option) => option.children.indexOf(input) >= 0}
                        >
                            {
                                games.map(o => {
                                    return <Select.Option key={o.id} value={o.id}>{o.game_name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Button onClick={handleSubmit} type="primary" loading={loading}>修改</Button>
                </Form>
            </Modal>
        </>
    )
}

