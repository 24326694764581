import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Progress, message, Result } from 'antd';
import { $get, $post } from '../../helpers/remote';

const PAGE_SIZE = 20;

export default class FetchAdvertiser extends Component {
    static propTypes = {
        providerId: PropTypes.string.isRequired,
        providerType: PropTypes.string,
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        userId: PropTypes.number,
        isShowMarketingButton: PropTypes.bool,
        isNewMpFetch: PropTypes.bool,
        isOeMarketingFetch: PropTypes.bool,
    }
    state = {
        total: 0,
        page: 1,
        pageSize: PAGE_SIZE,
        needLogin: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState({ total: 0, page: 1, needLogin: false }, () => this.fetch());
        }
    }
    fetch = async () => {
        const { page } = this.state;
        try {
            let requestData = { total: 0, pageSize: 0 };
            if (this.props.isOeMarketingFetch) {
                requestData.total = await $get(
                    `/providers/marketing/oe/advertisers`,
                    { page, providerId: this.props.providerId },
                    { hideLoading: true, throwException: true }
                )
                requestData.pageSize = 200;
            } else if (this.props.isNewMpFetch) {
                requestData.total = await $get(
                    `/providers/newAdq/advertisers`,
                    { providerId: this.props.providerId },
                    { hideLoading: true, throwException: true }
                )
                requestData.pageSize = requestData.total;
            } else if (this.props.providerType === 'adq-adq') {
                requestData = await $post(
                    `/providers/${this.props.providerId}/advertisers/fetch`,
                    { page },
                    { hideLoading: true, throwException: true }
                );
                requestData.pageSize = 100;
            } else {
                requestData = await $post(
                    `/providers/${this.props.providerId}/advertisers/fetch`,
                    { page },
                    { hideLoading: true, throwException: true }
                );
            }
            const { total, pageSize } = requestData;
            this.setState({
                total:  total || this.state.total,
                page: page + 1,
                pageSize: pageSize || PAGE_SIZE
            }, () => {
                const { pageSize, total } = this.state;
                if (page * pageSize < total) {
                    this.fetch();
                }
            });
        } catch (e) {
            if (e.response && e.response.data.message === '服务商用户token过期') {
                this.setState({ needLogin: true });
            } else if (e.response && /incorrect\sheader\scheck/.test(e.response.data.message)) {
                this.fetch();
            } else {
                message.error(e.response ? e.response.data.message : e.message);
            }
        }
    }
    onClose = () => {
        this.props.onClose();
    }
    render() {
        const { visible } = this.props;
        const { total, page, pageSize, needLogin } = this.state;
        let percent = total ? Math.floor(((page - 1) * pageSize) / total * 100) : 0;
        return (
            <Modal
                title="正在更新广告主列表"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <div className="page-provider-fetch" style={{ textAlign: 'center' }}>
                    { needLogin && <Result
                        status="error"
                        title="服务商登陆状态过期"
                        subTitle="请返回扫码更新状态"
                    /> }
                    { !needLogin && percent < 100 && <Progress type="circle" percent={percent} status="active" /> }
                    { !needLogin && percent >= 100 && <Result
                        status="success"
                        title="列表刷新完成"
                    /> }
                </div>
            </Modal>
        )
    }
}
