import React, { Component } from 'react';
import { Descriptions, Button, Switch, Modal, Popconfirm } from 'antd';
import prompt from 'antd-prompt';
import { $get, $patch, $post } from '../../helpers/remote';
import { EditOutlined } from '@ant-design/icons';
export default class UserPage extends Component {
    state = {
        phone: null,
        usage: null,
        limitation: null,
        offlineNotice: false,
        resubmit: false,
        openToken: false,
        autoStopReadyCampaign: false,
        isDisabled: false,
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        const { id, phone, offlineNotice, phone2, resubmit, autoStopReadyCampaign, notifyUrl, autoSyncReport } = await $get('/users/profile');
        this.setState({ phone, phone2, offlineNotice, resubmit, autoStopReadyCampaign, notifyUrl, autoSyncReport });
        const { job: { usage, limitation }} = await $get('/jobs/limitation', undefined, {hideLoading: true});
        this.setState({ usage, limitation });
        const openToken = await $get('/users/openToken', undefined, {hideLoading: true});
        this.setState({ openToken });
        const userInfo = await $get(`/users/${id}`, undefined, {hideLoading: true});
        if (userInfo.accessSync === false) {
            this.setState({ isDisabled : true })
        }
    }
    logout = () => {
        localStorage.removeItem('ad-platform-token');
        window.location.reload();
    }
    handleOfflineNoticeChange = (offlineNotice) => {
        this.setState({ offlineNotice });
        $patch('/users/profile', { offlineNotice });
    }
    handleStopTaskChange = (autoStopReadyCampaign) => {
        this.setState({ autoStopReadyCampaign });
        $patch('/users/profile', { autoStopReadyCampaign });
    }
    handleResubmitChange = (resubmit) => {
        this.setState({ resubmit });
        $patch('/users/profile', { resubmit });
    }
    editPhone2 = async () => {
        const phone2 = await prompt({
            title: '修改备用手机号码'
        });
        await $patch('/users/profile', { phone2 });
        this.fetch();
    }
    editNotifyUrl = async () => {
        const notifyUrl = await prompt({
            title: '输入推送接收地址'
        });
        await $patch('/users/profile', { notifyUrl }, { throwException: 'showMessage' });
        this.fetch();
    }
    changePassword = async () => {
        const password = await prompt({
            title: '请输入新密码'
        });
        await $patch('/users/profile', { password });
        this.logout();
    }
    generateOpenToken = async () => {
        const token = await $post('/users/openToken');
        Modal.success({
            title: '生成成功',
            content: '请妥善保存：' + token,
        });
        this.setState({ openToken: true });
    }
    render() {
        const {
            phone, limitation, usage, offlineNotice, phone2,
            resubmit, openToken, autoStopReadyCampaign,
            notifyUrl,
        } = this.state;
        return (
            <div className="page-user">
                <Descriptions title="用户信息">
                    <Descriptions.Item label="登录名">{ phone || '加载中...' }</Descriptions.Item>
                    <Descriptions.Item label="用量">{usage === null ? 'N/A' : usage} / {limitation === null ? 'N/A' : limitation}</Descriptions.Item>
                    <Descriptions.Item label="掉线短信提醒">
                        <Switch checked={offlineNotice} onChange={this.handleOfflineNoticeChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="备用通知手机">
                        {phone2} <Button icon={ <EditOutlined /> } onClick={this.editPhone2}></Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="自动提交3天内审核失败投放">
                        <Switch checked={resubmit} onChange={this.handleResubmitChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="开放token">
                        {openToken && <div>已生成 <Popconfirm title="重新生成后旧的token将失效" onConfirm={this.generateOpenToken}>
                        <Button>重新生成</Button></Popconfirm></div>}
                        {!openToken && <Button onClick={this.generateOpenToken}>生成</Button>}
                    </Descriptions.Item>
                    <Descriptions.Item label="自动暂停待投放任务">
                        <Switch checked={autoStopReadyCampaign} onChange={this.handleStopTaskChange} disabled={ this.state.isDisabled }/>
                    </Descriptions.Item>
                    <Descriptions.Item label="投放数据推送地址">
                        {notifyUrl} <Button icon={ <EditOutlined /> } onClick={this.editNotifyUrl}></Button>
                    </Descriptions.Item>
                </Descriptions>
                <Button type="default" onClick={this.changePassword}>修改密码</Button>&nbsp;
                <Button type="danger" onClick={this.logout}>退出账号</Button>
            </div>
        )
    }
}
