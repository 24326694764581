import React from 'react';
import { Form, Select, DatePicker, Input, Row, Col } from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

function FilterForm(props) {
    return (
        <Form className="job-filter" {...formItemLayout}
            onFieldsChange={(changedFields, allFields) => {
                const fielsArr = allFields.map(item => {
                    return [
                        item.name[0], item.value
                    ]
                })
                const data = Object.fromEntries(fielsArr);
                props.onChange && props.onChange(data);
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="任务状态" name="status">
                        <Select>
                            <Select.Option value="all">全部</Select.Option>
                            <Select.Option value="succeeded">成功</Select.Option>
                            <Select.Option value="failed">失败</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="审核状态" name="reviewStatus">
                        <Select>
                            <Select.Option value="all">全部</Select.Option>
                            <Select.Option value="1">审核通过</Select.Option>
                            <Select.Option value="0">审核中</Select.Option>
                            <Select.Option value="2">未通过</Select.Option>
                            <Select.Option value="-1">无效</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="创建时间" name="createdAt">
                        <DatePicker.RangePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="搜索计划名称" name="title">
                        <Input.Search />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="ID" name="id">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="自定义ID" name="customId">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                { props.userProfile && props.userProfile.groupId ? <Col span={12}>
                    <Form.Item label="投手" name="userId">
                        <Select allowClear={true} showSearch filterOption={(input, option) =>
                            option.children.indexOf(input) >= 0
                        }>
                            {
                                props.users.map(o => <Select.Option value={o.id} key={o.id}>{o.name || o.id}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col> : ''}
            </Row>
        </Form>
    )
}

export default FilterForm;
