import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'antd';

const toNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);

export default class MonitorGameTable extends Component {

    calcAverage = (arr) => {
        this.props.calcAverage(arr);
    }

    get columns() {
        const { dataSource, statusOptions, showUpdatedAt } = this.props;
        const items = [
            {
                title: '条数/总条数',
                dataIndex: 'index',
                render: (index, row) => `${index}/${dataSource.length}`,
            },
            {
                title: '计划名称',
                dataIndex: 'campaignName',
            },
            {
                title: '出价',
                dataIndex: 'bid',
            },
            {
                title: '消耗',
                dataIndex: 'paid',
                sorter: (a, b) => toNumber(a.paid) - toNumber(b.paid),
            },
            {
                title: '点击量',
                dataIndex: 'clk_pv',
                render: (value) => value && value !== '--' ? toNumber(value) : 0,
                sorter: (a, b) => toNumber(a.clk_pv) - toNumber(b.clk_pv),
            },
            {
                title: '点击率',
                dataIndex: 'ctr',
                render: (value) => value && value !== '--' ? (Number(value) * 100).toFixed(2) + '%' : '--',
                sorter: (a, b) => toNumber(a.ctr) - toNumber(b.ctr),
            },
            {
                title: '点击均价',
                dataIndex: 'cpc',
                sorter: (a, b) => toNumber(a.cpc) - toNumber(b.cpc),
            },
            {
                title: '转化目标量',
                dataIndex: 'conv_index',
                sorter: (a, b) => toNumber(a.conv_index) - toNumber(b.conv_index),
            },
            {
                title: '转化目标成本',
                dataIndex: 'conv_index_cpa',
                sorter: (a, b) => toNumber(a.conv_index_cpa) - toNumber(b.conv_index_cpa),
            },
            {
                title: '目标转化率',
                dataIndex: 'conv_index_cvr',
                render: (value) => value && value !== '--' ? (Number(value) * 100).toFixed(2) + '%' : '--',
                sorter: (a, b) => toNumber(a.conv_index_cvr) - toNumber(b.conv_index_cvr),
            },
            {
                title: '小游戏注册人数',
                dataIndex: 'weapp_reg_pv',
                sorter: (a, b) => toNumber(a.weapp_reg_pv) - toNumber(b.weapp_reg_pv),
            },
            {
                title: '小游戏注册成本（人数）',
                dataIndex: 'weapp_reg_cost',
                sorter: (a, b) => toNumber(a.weapp_reg_cost) - toNumber(b.weapp_reg_cost),
            },
            {
                title: '小游戏注册率',
                dataIndex: 'weapp_reg_rate',
                render: (value) => value && value !== '--' ? (Number(value) * 100).toFixed(2) + '%' : '--',
                sorter: (a, b) => toNumber(a.weapp_reg_rate) - toNumber(b.weapp_reg_rate),
            },
            {
                title: '小游戏付费金额（元）',
                dataIndex: 'weapp_purchase_amount',
                sorter: (a, b) => toNumber(a.weapp_purchase_amount) - toNumber(b.weapp_purchase_amount),
            },
            {
                title: '小游戏首日新增收入ROI',
                dataIndex: 'weapp_purchase_amount_roi',
                sorter: (a, b) => toNumber(a.weapp_purchase_amount_roi) - toNumber(b.weapp_purchase_amount_roi),
            },
            {
                title: '千次曝光成本',
                dataIndex: 'ecpm',
                sorter: (a, b) => toNumber(a.ecpm) - toNumber(b.ecpm),
            },
            {
                title: '均衡/优先',
                dataIndex: 'strategy2',
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (status) => {
                    const item = statusOptions.find(o => o.value === status);
                    return item ? item.label : status;
                }
            }
        ];
        if (showUpdatedAt) {
            items.push({
                title: '更新时间',
                dataIndex: 'updatedAt',
                render: (updatedAt) => updatedAt ? moment(updatedAt).format('MM-DD HH:mm') : '-',
            });
        }
        return items;
    }

    renderFooter = () => {
        const { dataSource: rows, targetLabel, statusLabel, advertiserId, status, calcAverage } = this.props;

        const summary = {
            bid: '--',
            paid: 0,
            clk_pv: 0,
            ctr: [],
            cpc: [],
            conv_index: 0,
            conv_index_cpa: [],
            conv_index_cvr: [],
            weapp_reg_pv: 0,
            weapp_reg_cost: [],
            weapp_reg_rate: [],
            weapp_purchase_amount: 0,
            weapp_purchase_amount_roi: [],
            ecpm: [],
            strategy2: '--',
            status: '--'
        };
        const calcSumArr = ['paid', 'clk_pv', 'conv_index', 'weapp_reg_pv', 'weapp_purchase_amount'];
        const calcAverageArr = ['ctr', 'cpc', 'conv_index_cpa', 'conv_index_cvr', 'weapp_reg_cost', 'weapp_reg_rate', 'weapp_purchase_amount_roi', 'ecpm'];
        const rateArr = ['ctr', 'conv_index_cvr', 'weapp_reg_rate'];
        const intArr = ['clk_pv', 'conv_index', 'weapp_reg_pv'];
        rows.forEach(item => {
            calcSumArr.forEach(key => {
                summary[key] += toNumber(item[key]);
            });
            calcAverageArr.forEach(key => {
                if (rateArr.includes(key)) {
                    summary[key].push(toNumber(item[key]) * 100);
                }
                else {
                    summary[key].push(toNumber(item[key]));
                }
            });
        });

        calcAverageArr.forEach(item => {
            summary[item] = toNumber(calcAverage(summary[item]));
        });

        let summaryCellArr = [
            <Table.Summary.Cell key="0">总数: {rows.length}</Table.Summary.Cell>,
            <Table.Summary.Cell key="1">--</Table.Summary.Cell>
        ];
        let index = 2;
        for (let key in summary) {
            let value = summary[key];
            if (typeof value === 'number') {
                value = intArr.includes(key) ? value : value.toFixed(2);
            }
            if (rateArr.includes(key)) {
                value = value + '%';
            }
            if (key === 'advertiserName' && advertiserId !== 0) {
                value = targetLabel;
            }
            if (key === 'status' && status !== 'all') {
                value = statusLabel;
            }
            summaryCellArr.push(<Table.Summary.Cell index={index++} key={index}>{value}</Table.Summary.Cell>);
        }
        return (
            <Table.Summary.Row>
                {summaryCellArr}
            </Table.Summary.Row>
        )
    }

    render() {
        const { dataSource } = this.props;

        return <div className="page-monitor">
            <Table
                rowKey={record => record.cid}
                dataSource={dataSource}
                columns={this.columns}
                summary={() => this.renderFooter()}
                size="small"
            />
        </div>
    }
}