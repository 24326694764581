import React, { Component } from 'react';
import { Table, Button } from 'antd';
import moment from 'moment';

import { $get } from '../../helpers/remote';

export default class AdminUser extends Component {
    state = {
        excels: [],
        loading: false,
        pagination: {
            pageSize: 10,
            page: 1,
        },
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch({
        page,
        pageSize
    } = this.state.pagination) {
        const pagination = { ...this.state.pagination };
        this.setState({ loading: true });
        const [excels, total] = await $get('/admin/excels', {page, pageSize});
        pagination.total = total;
        this.setState({ excels, loading: false, pagination });
    }
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current
        });
    }
    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '服务商名称',
                dataIndex: 'providerName',
            },
            {
                title: '用户手机号',
                dataIndex: 'userPhone',
            },
            {
                title: '添加时间',
                dataIndex: 'createdAt',
                render: time => time ? moment(time).format('YYYY-MM-DD HH:mm') : '无'
            },
            {
                title: '下载链接',
                dataIndex: 'url',
                render: url => <Button type="link">{url}</Button>
            }
        ];
        return columns;
    }
    render() {
        return <div className="page-admin-excel">
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.excels}
                loading={this.state.loading}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
            />
        </div>
    }
}