import React, { Component } from 'react';
import { Table, Button, Popconfirm, Switch as AntSwitch, message, Modal, DatePicker } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { $get, $delete, $put, $post } from '../../helpers/remote';
// import Add from './add';
import AddGdt from './add-gdt';
import AddOe from './add-oe';
import AddAdq from './add-adq';
import AddAdqWx from './add-adq-wx';
import AddAdqAdq from './add-adq-adq';
import AddKs from './add-ks';
import AddMarketingOe from './add-marketing-oe';
import AddNewMp from './add-new-mp';
import AddAdqNewWx from './add-adq-new-wx';
import Provider from './provider';
import Advertiser from './advertiser';
import Status from '../../components/home/status';
import moment from 'moment';

import './home.less';

class Home extends Component {
    state = {
        data: [],
        pagination: {
            pageSize: 20,
            page: 1,
        },
        loading: false,
        showAddModal: false,
        showGdtAddModal: false,
        isDisabled: false,
        dateModalVisible: false,
        providerId: '',
        showOceanAddModal: false,
        showAdqAddModal: false,
        showAdqWxAddModal: false,
        showAdqAdqAddModal: false,
        showKsAddModal: false,
        showMarketingOeAddModal: false,
        showNewMpAddModal: false,
        showAdqNewWxAddModal: false,
        isShowMarketingButton: false,
    }
    componentDidMount() {
        this.fetch();
    }
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current,
        });
    };
    get columns() {
        return [
            {
                title: '服务商ID',
                dataIndex: 'id',
            },
            {
                title: '类型',
                dataIndex: 'type',
                render: type => {
                    switch(type) {
                        case 'gdt':
                            return '广点通';
                        case 'adq-qq':
                            return 'adq(QQ)';
                        case 'adq-wx':
                            return 'adq(微信)';
                        case 'adq-adq':
                            return 'adq(ADQ)';
                        case 'oe':
                            return '巨量';
                        case 'ks':
                            return '快手';
                        case 'adq-new-wx':
                            return '新版mp';
                        default:
                            return '微信';
                    }
                }
            },
            {
                title: '服务商名称',
                dataIndex: 'agencyName',
            },
            {
                title: '服务商ID',
                dataIndex: 'agencyId',
            },
            {
                title: '自动同步投放数据',
                dataIndex: 'autoSync',
                render: (text, record) => <AntSwitch checked={text} onChange={(value) => this.onAutoSyncChange(record.id, value)} disabled={this.state.isDisabled}/>,
            },
            {
                title: '状态',
                render: item => <div className="status">
                        <div className="normal">
                            普通用户状态:
                            <Status token={item.normalToken} online={item.online}></Status>
                        </div>
                    </div>,
            },
            {
                title: '操作',
                render: provider => <div className="operations">
                    <Button onClick={() => window.location.href = `/providers/${provider.id}/${this.state.isShowMarketingButton ? 1 : 0}`}>进入</Button>&nbsp;
                    <Popconfirm title="解除绑定后不可回复，是否解绑？" okText="确认解绑" cancelText="点错了" onConfirm={() => this.deleteProvider(provider.id)}>
                        <Button type="danger">解绑</Button>
                    </Popconfirm>&nbsp;
                    <Button onClick={() => this.openDateModal(provider.id)}>数据检测</Button>
                    {/* <Popconfirm title="发起数据同步将会推送全量数据到指定的接口地址，耗时较长请勿频繁发起" okText="确认发起" cancelText="点错了" onConfirm={() => this.syncReport(provider.id)}>
                        <Button>批量数据同步</Button>
                    </Popconfirm> */}
                </div>,
            },
        ];
    }
    fetch = async (params) => {
        const { page, pageSize } = params || this.state.pagination;
        let userId;
        this.setState({ loading: true });
        const [providers, count] = await $get('/providers', { page, pageSize }, { hideLoading: true });
        const pagination = { ...this.state.pagination };
        pagination.total = count;
        this.setState({
            loading: false,
            data: providers,
            pagination
        });
        if (providers[0]) {
            userId = providers[0].userId;
            const userInfo = await $get(`/users/${providers[0].userId}`);
            if (userInfo.accessSync === false) {
                this.setState({ isDisabled : true })
            }
        } else {
            const user = await $get('/users/profile', {}, {
                hideLoading: true
            });
            userId = user.id;
        }
        this.setState({
            isShowMarketingButton: [1, 2, 125].includes(userId) ? true : false
        });
    };
    deleteProvider = async (id) => {
        await $delete(`/providers/${id}`, {}, { throwException: true });
        this.fetch();
    }
    syncReport = async (id) => {
        await $post(`/providers/${id}/batch-sync-report`, {}, { throwException: true });
        message.success('发起更新成功，请耐心等待');
    }
    onAutoSyncChange = (providerId, value) => {
        this.setState(({ data }) => ({
            data: data.map(p => {
                if (p.id === providerId) {
                    return { ...p, autoSync: value };
                }
                return p;
            })
        }));
        $put(`/providers/${providerId}/autoSync`, { autoSync: value });
    }
    onAddModalClose = (changed = false) => {
        this.setState({ showAddModal: false });
        changed && this.fetch();
    }
    onGdtAddModalClose = (changed = false) => {
        this.setState({ showGdtAddModal: false });
        changed && this.fetch();
    }
    openDateModal = (providerId = 0) => {
        this.setState({ dateModalVisible: true, providerId });
    }
    onDateModalConfirm = async() => {
        const {  providerId, date } = this.state;
        this.setState({ dateModalVisible: false });
        await $get(`/providers/${providerId}/check-data`, { date });
        message.success('提交成功，请稍后在企业微信群查看通知');
    }
    onDateModalClose = (e) => {
        this.setState({ dateModalVisible: false });
    }
    onChangeDate = (value) => {
        const date = moment(value).format('YYYY-MM-DD');
        this.setState({ date });
    }
    onOceanAddModalClose = (changed = false) => {
        this.setState({ showOceanAddModal: false });
        changed && this.fetch();
    }
    onAdqAddModalClose = (changed = false) => {
        this.setState({ showAdqAddModal: false });
        changed && this.fetch();
    }
    onAdqWxAddModalClose = (changed = false) => {
        this.setState({ showAdqWxAddModal: false });
        changed && this.fetch();
    }
    onAdqAdqAddModalClose = (changed = false) => {
        this.setState({ showAdqAdqAddModal: false });
        changed && this.fetch();
    }
    onKsAddModalClose = (changed = false) => {
        this.setState({ showKsAddModal: false });
        changed && this.fetch();
    }
    onMarketingOeAddModalClose = (changed = false) => {
        this.setState({ showMarketingOeAddModal: false });
        changed && this.fetch();
    }
    onNewMpAddModalClose = (changed = false) => {
        this.setState({ showNewMpAddModal: false });
        changed && this.fetch();
    }
    onAdqNewWxAddModalClose = (changed = false) => {
        this.setState({ showAdqNewWxAddModal: false });
        changed && this.fetch();
    }
    render() {
        return <div className="page-home">
            {/* <Button onClick={() => this.setState({ showAddModal: true })}>添加/更新服务商</Button>&nbsp; */}
            <Button onClick={() => this.setState({ showAdqWxAddModal: true })}>添加/更新服务商(adq-wx)</Button>&nbsp;
            <Button onClick={() => this.setState({ showAdqNewWxAddModal: true })}>添加/更新服务商(adq-new-wx)</Button>&nbsp;
            <Button onClick={() => this.setState({ showAdqAdqAddModal: true })}>添加/更新服务商(adq-adq)</Button>&nbsp;
            <Button onClick={() => this.setState({ showAdqAddModal: true })}>添加/更新服务商(adq-qq)</Button>&nbsp;
            <Button onClick={() => this.setState({ showGdtAddModal: true })}>添加/更新服务商(广点通)</Button>&nbsp;
            <Button onClick={() => this.setState({ showOceanAddModal: true })}>添加/更新服务商(巨量引擎)</Button>
            <Button onClick={() => this.setState({ showKsAddModal: true })}>添加/更新服务商(快手)</Button>
            { 
                this.state.isShowMarketingButton && <Button onClick={() => this.setState({ showMarketingOeAddModal: true })}>添加/更新服务商(巨量marketingApi)</Button>
            }
            { 
                this.state.isShowMarketingButton && <Button onClick={() => this.setState({ showNewMpAddModal: true })}>添加/更新服务商(新版MP)</Button>
            }
            {/* <Add visible={this.state.showAddModal} onClose={this.onAddModalClose} /> */}
            <AddGdt visible={this.state.showGdtAddModal} onClose={this.onGdtAddModalClose} />
            <AddOe visible={this.state.showOceanAddModal} onClose={this.onOceanAddModalClose} />
            <AddAdq visible={this.state.showAdqAddModal} onClose={this.onAdqAddModalClose} />
            <AddAdqWx visible={this.state.showAdqWxAddModal} onClose={this.onAdqWxAddModalClose} />
            <AddAdqAdq visible={this.state.showAdqAdqAddModal} onClose={this.onAdqAdqAddModalClose} />
            <AddKs visible={this.state.showKsAddModal} onClose={this.onKsAddModalClose} />
            <AddMarketingOe visible={this.state.showMarketingOeAddModal} onClose={this.onMarketingOeAddModalClose} isShowMarketingButton={this.state.isShowMarketingButton} />
            <AddNewMp visible={this.state.showNewMpAddModal} onClose={this.onNewMpAddModalClose} isShowMarketingButton={this.state.isShowMarketingButton} />
            <AddAdqNewWx visible={ this.state.showAdqNewWxAddModal } onClose={this.onAdqNewWxAddModalClose} />
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
            />
            <Modal
                title="请选择校验日期"
                okText="确定"
                visible={this.state.dateModalVisible}
                onCancel={this.onDateModalClose}
                onOk={this.onDateModalConfirm}
            >
                <div>
                    <DatePicker onChange={ (value) => this.onChangeDate(value)} />
                </div>
            </Modal>
        </div>
    }
}

export default class HomeRouter extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/providers" component={Home} />
                <Route exact path="/providers/:providerId/:isShowMarketingButton" component={Provider} />
                <Route path="/providers/:providerId/advertisers/:advertiserId" component={Advertiser} />
            </Switch>
        )
    }
}
