import React, { Component, createRef, forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Switch, Tabs, Button, Select, Tooltip, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Upload from './upload';
import TagGroup from './tag-group';
import { $upload, $post, $get } from '../../helpers/remote';

import './add.less';

const { TabPane } = Tabs;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


const UploadForm = forwardRef((props, ref) => {

    const [providers, setProviders] = useState([]);
    const [activeTab, setActiveTab] = useState('normal');
    const [providerLoaded, setProviderLoaded] = useState(true);
    const [pageImage, setPageImage] = useState(undefined);
    const formRef = useRef(React.Component(Form));

    useEffect(() => {
        getProviders();
    }, []);

    const getProviders = async () => {
        const [providers] = await $get('/providers', {
            page: 1,
            pageSize: 999
        });
        setProviderLoaded(false);
        setProviders(providers);
    };

    const upload = async () => {
        const tabMap = new Map([
            ['normal', ['title', 'tags', 'isVideo', 'files']],
            ['landingPage', ['providerId', 'advertiserName', 'landingPageName', 'canvas', 'tags']],
            ['text', ['title', 'content', 'tags']],
        ]);
        const fields = tabMap.get(activeTab);
        const values = await formRef.current.validateFields(fields);
        if (!values) {
            return;
        }
        let results = [];
        const { tags } = values;
        if (activeTab === 'normal') {
            const { title, isVideo, files } = values;
            for (const file of files) {
                const data = {
                    title: title || '',
                    tags: tags ? tags.join(',') : '',
                    type: isVideo ? 'VIDEO' : 'IMAGE',
                    file: file.originFileObj,
                };
                const result = await $upload(
                    '/materials',
                    data,
                    { hideLoading: true, throwException: true }
                );
                results.push(result);
            }
            return results;
        }
        else if (activeTab === 'landingPage') {
            if (!values.canvas) {
                message.error('请获取原生推广页');
                return;
            }
            results = await $upload(
                '/materials',
                {
                    canvas: JSON.stringify(values.canvas),
                    title: values.landingPageName,
                    url: pageImage,
                    type: 'PAGE',
                    tags: tags ? tags.join(',') : '',
                },
            );
        }
        else if (activeTab === 'text') {
            results = await $upload(
                '/materials',
                {
                    title: values.title || '',
                    content: values.content,
                    type: 'TEXT',
                    tags: tags ? tags.join(',') : '',
                },
            );
        }
        return [results];
    }

    useImperativeHandle(ref, () => ({
        resetFields: () => formRef.current.resetFields(),
        validateFields: () => formRef.current.validateFields(),
        upload: () => upload(),
        clear: () => setPageImage(undefined)
    }));

    const getLandingPage = () => {
        formRef.current.validateFields(['providerId', 'advertiserName', 'landingPageName'])
        .then(async (values) => {
            try {
                const canvas = await $post(`/materials/landingPage`, values);
                message.success('获取成功');
                const pageImage = JSON.parse(canvas.canvas_info).adCanvasInfo.shareThumbUrl;
                formRef.current.setFieldsValue({canvas});
                setPageImage(pageImage);
            } catch (error) {
                message.error('发生错误, 请联系管理员处理')
            }
        })
    }
    return (
        <Form
            className="page-material-add"
            {...formItemLayout}
            ref={formRef}>
            <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}>
                <TabPane tab="图片/视频" key="normal">
                    <Form.Item label="标题" name="title">
                        <Input placeholder="请填写标题" />
                    </Form.Item>
                    <Form.Item label="是否视频" name="isVideo" valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                    <Form.Item label="标签" name="tags">
                        <TagGroup />
                    </Form.Item>
                    <Form.Item label="文件" name="files" rules={[{ required: true, message: '请选择文件' }]} >
                        <Upload />
                    </Form.Item>
                </TabPane>
                <TabPane tab="文字" key="text">
                    <Form.Item label="标题" name="title">
                        <Input placeholder="请填写标题" />
                    </Form.Item>
                    <Form.Item label="内容" name="content">
                        <Input placeholder="请填写内容" />
                    </Form.Item>
                    <Form.Item label="标签" name="tags">
                        <TagGroup />
                    </Form.Item>
                </TabPane>
                <TabPane tab="原生推广页" key="landingPage">
                    <Form.Item label="服务商" name="providerId" rules={[{ required: true, message: '请选择服务商' }]}>
                        <Select
                            placeholder="请选择服务商"
                            allowClear
                            loading={providerLoaded}
                        >
                            {
                                providers.map((provider) =>
                                    <Option value={provider.id} key={provider.id}>{provider.agencyName}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="广告主" name="advertiserName" rules={[{ required: true, message: '请填写广告主名称' }]}>
                        <Input placeholder="请填写广告主名称" />
                    </Form.Item>
                    <Form.Item label="落地页名称" name="landingPageName" rules={[{ required: true, message: '请填写落地页名称' }]}>
                        <Input placeholder="请填写落地页名称" />
                    </Form.Item>
                    <Form.Item
                        name="canvas"
                        label={
                            <span>
                                原生推广页&nbsp;
                                <Tooltip title={
                                    <span>获取
                                        <a href="https://a.weixin.qq.com/" target="_blank" rel="noopener noreferrer">
                                            微信端
                                        </a>的原生推广页
                                    </span>
                                }>
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </span>
                        }
                    >
                        {
                            pageImage ? <img className="pageImage" src={pageImage} alt="原生推广页"></img>
                            : <Button onClick={getLandingPage}>
                                获取原生推广页
                            </Button>
                        }
                    </Form.Item>
                    <Form.Item label="标签" name="tags">
                        <TagGroup />
                    </Form.Item>
                </TabPane>
            </Tabs>
        </Form>
    )
});

export default class AddMaterial extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    }
    formRef = createRef(null)
    state = {
        loading: false,
    }
    onClose = (result) => {
        this.formRef.current.resetFields();
        this.formRef.current.clear();
        if (Array.isArray(result)) {
            this.props.onClose(result);
        } else {
            this.props.onClose();
        }
    }
    onOk = async () => {
        this.setState({ loading: true });
        const result = await this.formRef.current.upload();
        if (result) {
            this.onClose(result);
        }
        this.setState({ loading: false });
    }
    render() {
        const { visible } = this.props;
        const { loading } = this.state;
        return (
            <Modal
                className="add-material"
                title="添加素材"
                okText="上传"
                visible={visible}
                cancelText="取消"
                onCancel={this.onClose}
                onOk={this.onOk}
                loading={loading}
                okButtonProps={{ loading }}
            >
                <UploadForm
                    ref={this.formRef}
                    />
            </Modal>
        )
    }
}
