import React, { Component } from 'react';
import moment from 'moment';

import { DatePicker, Select, Button, Spin, Space, Input, Switch, Radio } from 'antd';
import { CSVLink } from "react-csv";
import { $get } from '../../helpers/remote';
import MonitorNovelTable from './novel';
import MonitorGameTable from './game';
import './index.less';

const { Option } = Select;

export default class UserPage extends Component {
    STATUSOPTIONS = [{
        value: 'all',
        label: '全部状态'
    }, {
        value: 'ADSTATUS_NORMAL',
        label: '投放中'
    }, {
        value: 'ADSTATUS_READY',
        label: '待投放'
    }, {
        value: 'ADSTATUS_SUSPEND',
        label: '暂停'
    }]

    STRATEGY2OPTIONS = [{
        value: '均衡跑量',
        label: '均衡跑量'
    }, {
        value: '优先跑量',
        label: '优先跑量'
    }]

    HEADER_NOVEL = [
        { label: "条数/总条数", key: "index" },
        { label: "出价", key: "bidAvg" },
        { label: "消耗", key: "paid" },
        { label: "粉数", key: "followPv" },
        { label: "粉本", key: "followCost" },
        { label: "点击率", key: "ctr" },
        { label: "千次曝光成本", key: "ecpm" },
        { label: "ROI", key: "orderRoi" },
        { label: "均衡/优先", key: "strategy2" },
        { label: "男/女", key: "gender" },
        { label: "落地页停留时长", key: "cvsViewtimeAvg" },
        { label: "下单金额", key: "orderAmount" },
        { label: "下单ARPU", key: "arpu" },
        { label: "公众号", key: "advertiserName" },
    ]

    HEADER_GAME = [
        { label: "条数/总条数", key: "index" },
        { label: "出价", key: "bidAvg" },
        { label: "消耗", key: "paid" },
        { label: "点击量", key: "clk_pv" },
        { label: "点击率", key: "ctr" },
        { label: "点击均价", key: "cpc" },
        { label: "转化目标量", key: "conv_index" },
        { label: "转化目标成本", key: "conv_index_cpa" },
        { label: "目标转化率", key: "conv_index_cvr" },
        { label: "小游戏注册人数", key: "weapp_reg_pv" },
        { label: "小游戏注册成本（人数）", key: "weapp_reg_cost" },
        { label: "小游戏注册率", key: "weapp_reg_rate" },
        { label: "小游戏付费金额（元）", key: "weapp_purchase_amount" },
        { label: "小游戏首日新增收入ROI", key: "weapp_purchase_amount_roi" },
        { label: "千次曝光成本", key: "ecpm" },
        { label: "均衡/优先", key: "strategy2" },
    ]

    state = {
        advertisers: [],
        advertiserId: 0,
        date: moment(),
        status: 'all',
        rows: [],
        loading: false,
        showUpdatedAt: false,
        type: 'game',
        showNoConv: false,
    }

    componentDidMount() {
        this.queryAdvertiser();
    }

    queryData = async () => {
        const { date, advertiserId } = this.state;
        try {
            this.setState({ loading: true })
            const query = {
                start: date.startOf('day').unix(),
                end: date.endOf('day').unix()
            };
            const rows = await $get(`/wx-data/advertisers/${advertiserId || 'all'}`, query);
            this.setState({
                loading: false,
                rows,
            });
        } catch (error) {
            throw error;
        }
    }
    calcCost = (originSource) => {
        let todayEffectCost = 0;
        let todayCost = 0;
        for (let i = 0; i < originSource; i++) {
            if (!this.isNumberValid(originSource[i].orderRoi)) {
                continue;
            }
            if (Number(originSource[i].orderRoi) >= 0.12) {
                todayEffectCost += originSource[i].orderRoi;
            }
            else {
                todayCost += originSource[i].orderRoi;
            }
        }
        this.setState({
            todayCost: {
                used: todayCost,
                effect: todayEffectCost,
                rate: (todayCost / (todayEffectCost + todayCost) * 100).toFixed(2)
            }
        });
    }

    queryAdvertiser = async () => {
        const advertisers = await $get('/advertisers');
        this.setState({ advertisers });
    }

    calcAverage = (arr) => {
        // 计算加权平均数
        if (arr.length === 0) {
            return '0.00';
        }
        function getFrequency(arr) {
            const map = new Map();
            arr.forEach(item => {
                if (map.has(item)) {
                    map.set(item, map.get(item) + 1);
                }
                else {
                    map.set(item, 1);
                }
            })
            return map;
        }
        const map = getFrequency(arr);
        let divisor = 0;
        let dividend = 0;
        map.forEach((value, key) => {
            divisor += key * value;
            dividend += value;
        });
        return (divisor / dividend).toFixed(2);
    }

    // 模糊查询
    searchByFuzzy = (keyword, searchValue) => {
        let keywordArr = keyword.split('');
        let searchWordArr = searchValue.split('');
        let keyLen = keywordArr.length;
        let searchLen = searchWordArr.length;
        if (keyLen > searchLen) {
            return false;
        }
        for (let i = keyLen - 1; i >= 0; i --) {
            if (searchWordArr[i] !== keywordArr[i]) {
                return false;
            }
        }
        return true;
    }
    get dataSource() {
        const { rows, status, strategy2, campaignId, customId, showNoConv } = this.state;
        return rows.filter(row => {
            if (status && status !== 'all') {
                if (row.status !== status) {
                    return false;
                }
            }
            if (strategy2) {
                if (row.strategy2 !== strategy2) {
                    return false;
                }
            }
            if (campaignId) {
                if (!this.searchByFuzzy(campaignId.toString(), row.campaignId.toString())) {
                    return false;
                }
            }
            if (customId) {
                if (!row.customId) {
                    return false;
                }
                if (!this.searchByFuzzy(customId.toString(), row.customId.toString())) {
                    return false;
                }
            }
            if (showNoConv) {
                if (row.conv_index !== 0) {
                    return false;
                }
            }
            return true;
        }).map((row, index) => {
            return {
                ...row,
                index: index + 1,
                length: rows.length,
                arpu: row.followPv ? (row.orderAmount / row.followPv).toFixed(2) : '--',
            };
        });
    }

    get targetLabel() {
        const { advertiserId, advertisers } = this.state;
        const targetItem = advertisers.find(item => item.id === advertiserId);
        return targetItem ? targetItem.name : '全部广告主';
    }

    get statusLabel() {
        const { status } = this.state;
        const statusItem = this.STATUSOPTIONS.find(item => item.value === status);
        return statusItem.label;
    }

    get fileName() {
        const { targetLabel, statusLabel } = this;
        const { date } = this.state;
        const dateLabel = moment(date).format('YYYY/MM/DD');
        return `${targetLabel}-${statusLabel}-${dateLabel}`;
    }

    render() {
        const { loading, type, advertisers, rows,
                advertiserId, campaignId, customId, strategy2, showUpdatedAt, showNoConv, status, date } = this.state;

        const { STRATEGY2OPTIONS, STATUSOPTIONS, HEADER_NOVEL, HEADER_GAME,
                dataSource, targetLabel, statusLabel, fileName } = this;
        const header = type === 'game' ? HEADER_GAME : HEADER_NOVEL;
        return <Spin tip="加载中" spinning={loading}>
            <div className="page-home monitor">
                <header>
                    <div className="left">
                        <Space>
                            <Radio.Group value={type} onChange={e => this.setState({ type: e.target.value })}>
                                <Radio.Button value="game">游戏</Radio.Button>
                                <Radio.Button value="novel">小说</Radio.Button>
                            </Radio.Group>
                            <Select
                                defaultValue="请选择广告主"
                                value={advertiserId}
                                style={{ width: 140 }}
                                onChange={value => this.setState({ advertiserId: value })}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value={0} key={0}>全部广告主</Option>
                                { advertisers.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>) }
                            </Select>
                            <Input placeholder="请输入计划ID" value={campaignId}
                                onChange={e => this.setState({ campaignId: e.target.value })}></Input>
                            <Input placeholder="请输入自定义ID" value={customId}
                                onChange={e => this.setState({ customId: e.target.value })}></Input>
                            <Select
                                defaultValue="请选择转化目标"
                                value={strategy2}
                                style={{ width: 140 }}
                                onChange={value => this.setState({ strategy2: value })}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value={0} key={0}>全部转化目标</Option>
                                { STRATEGY2OPTIONS.map(item => <Option value={item.value} key={item.value}>{item.label}</Option>) }
                            </Select>
                            <Switch checked={showUpdatedAt} onChange={showUpdatedAt => this.setState({ showUpdatedAt })} />
                            &nbsp;显示更新时间
                            <Switch checked={showNoConv} onChange={showNoConv => this.setState({ showNoConv })} />
                            &nbsp;仅显示转化量为0
                        </Space>
                    </div>
                    <div className="right">
                        <Select value={status} style={{ width: 100 }} onChange={(status) => this.setState({ status })}>
                            { STATUSOPTIONS.map(item => <Option value={item.value} key={item.value}>{item.label}</Option>) }
                        </Select>
                        <DatePicker
                            onChange={(value) => this.setState({ date: value })}
                            value={date}
                        />
                        <Button type="primary" onClick={this.queryData}>查询</Button>
                        <Button disabled={dataSource.length === 0} type="primary">
                            <CSVLink filename={`${fileName}.csv`} data={dataSource} headers={header}>导出本表</CSVLink>
                        </Button>
                    </div>
                </header>
                { type === 'game' &&
                <MonitorGameTable
                    statusOptions={ STATUSOPTIONS }
                    rows={ rows }
                    dataSource={ dataSource }
                    showUpdatedAt={ showUpdatedAt }
                    advertiserId={ advertiserId }
                    status={ status }
                    targetLabel={ targetLabel }
                    statusLabel={ statusLabel }
                    calcAverage={ this.calcAverage }
                     />
                }
                { type === 'novel' &&
                <MonitorNovelTable
                    statusOptions={ STATUSOPTIONS }
                    rows={ rows }
                    dataSource={ dataSource }
                    showUpdatedAt={ showUpdatedAt }
                    advertiserId={ advertiserId }
                    status={ status }
                    targetLabel={ targetLabel }
                    statusLabel={ statusLabel }
                    calcAverage={ this.calcAverage }
                />
                }
            </div>
        </Spin>
    }
}