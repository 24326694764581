import React, { Component } from 'react';
import { Modal, Button, Descriptions, Switch, message, Breadcrumb, Tag } from 'antd';
import MaterialPreview from '../../components/material-preview';
import {$post, $patch, $get, $delete} from '../../helpers/remote';
import moment from 'moment';
import prompt from 'antd-prompt';
import './index.less';
import STEPS from '../enums/steps';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import JobLogs from './logs';

export default class Detail extends Component {
    state = {
        data: [],
    }
    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        const info = await $get(`/jobs/${this.props.match.params.id}`);
        this.setState({ data: info })
    };
    runJob = async (jobId) => {
        let jobs = this.state.data;
        if( jobs.length === 0 ) {
            jobs = this.userList;
        }
        await $post(`/jobs/${jobId}/run`);
        jobs.step = STEPS[1];
        this.setState({
            data: jobs,
        });
    }
    updateAutoStop = async (jobId, value) => {
        let jobs = this.state.data;
        if( jobs.length === 0 ) {
            jobs = this.userList;
        }
        jobs.autoStop = value;
        await $patch(`/jobs/${jobId}/autoStop`, { autoStop: value });
        this.setState({
            data: jobs,
        });
    }
    showCampaign = (campaign) => {
        Modal.info({
            title: '计划详情',
            width: '80%',
            content: <div>
                <Descriptions>
                    {
                        Object.keys(campaign).filter(key => key !== 'id' && key !== 'material').map(key => {
                            let content = campaign[key];
                            return <Descriptions.Item label={key} key={key}>
                                {content}
                            </Descriptions.Item>
                        })
                    }
                </Descriptions>
            </div>
        });
    }
    updateCustomId = async () => {
        const customId = await prompt({
            title: '输入新ID'
        });
        await $patch(`/jobs/${this.props.match.params.id}/customId`, { customId: Number(customId) });
        this.fetch();
    }
    changeToSuccess = async () => {
        await $patch(`/jobs/${this.props.match.params.id}/succeeded`, { succeeded: true });
        this.fetch();
    }
    rerun = async () => {
        await $post(`/jobs/${this.props.match.params.id}/run`);
        message.success('提交成功，请稍后查看运行结果');
    }
    handleStrategyAdd = async () => {
        const raw = await prompt({
            title: '输入策略ID'
        });
        const id = Number(raw);
        if (!id) {
            message.error('ID无效');
            return;
        }
        await $patch(`/jobs/${this.props.match.params.id}/strategy-group`, { strategyGroupId: id });
        this.fetch();
    }
    handleStrategyDelete = async () => {
        await $delete(`/jobs/${this.props.match.params.id}/strategy-group`);
        this.fetch();
    }
    render() {
        const data = this.state.data;
        if (data.length !== 0) {
            const data = this.state.data;
            let userList;
            if( data.length === 0 ) {
                userList = this.userList;
            } else {
                userList = data;
            }
            let statusInfo;
            if (userList.reviewStatus === 2) {
                statusInfo = ( <span className="step-error">未通过</span> );
            } else if (userList.reviewStatus === 0) {
                statusInfo = ( <span className="step-warning">审核中</span> );
            } else if (userList.reviewStatus === 1) {
                statusInfo = ( <span className="step-success">已通过</span> );
            } else if (userList.reviewStatus === -1) {
                statusInfo = ( '无效' );
            } else{
                statusInfo = ( '未知' );
            }
            return (
                <div className="page-job-detail">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href="/jobs">投放任务</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{this.props.match.params.id}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Descriptions title="任务详情" style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="ID">{ userList.id }</Descriptions.Item>
                        <Descriptions.Item label="计划名称">{ userList.campain.campainName }</Descriptions.Item>
                        <Descriptions.Item label="自定义ID">{ userList.customId }</Descriptions.Item>
                        <Descriptions.Item label="广告主">{ userList.advertiser.name }</Descriptions.Item>
                        <Descriptions.Item label="素材预览">
                            {
                                userList.campain.material && <MaterialPreview url={ userList.campain.material.url ? userList.campain.material.url : '' } type={userList.campain.material.type} width={100} />
                            }</Descriptions.Item>
                        <Descriptions.Item label="状态">
                            {userList.succeeded && <span className="step-success">成功</span>}
                            {userList.failed && <span className="step-error">
                            失败 <InfoCircleOutlined onClick={() => Modal.error({
                                title: '失败原因',
                                content: userList.message,
                            })}/>
                        </span>}
                            {!userList.succeeded && !userList.failed && <span className="step-warning">
                            {
                                userList.step === STEPS[0]
                                    ? '队列中'
                                    : `${Math.floor(STEPS.indexOf(userList.step) / (STEPS.length) * 100)}%`
                            }
                                {userList.showRun && userList.step === STEPS[0] && <Button className="run-job" onClick={() => this.runJob(userList.id)}>手动执行</Button>}
                        </span>}
                        </Descriptions.Item>
                        <Descriptions.Item label="审核状态">{ statusInfo }</Descriptions.Item>
                        <Descriptions.Item label="重新提交ID">{ userList.resubmitId }</Descriptions.Item>
                        <Descriptions.Item label="创建时间">{ moment(userList.createdAt).format('MM-DD HH:mm') }</Descriptions.Item>
                        <Descriptions.Item label="自动暂停"><Switch checked={ userList.autoStop } onChange={value => this.updateAutoStop(userList.id, value)} /></Descriptions.Item>
                        <Descriptions.Item label="智能策略" span={2}>
                            {
                                data.strategyGroup
                                ?
                                    <Tag closable onClose={() => this.handleStrategyDelete()}>
                                        #{data.strategyGroup.id} {data.strategyGroup.title}
                                    </Tag>
                                :
                                    <Tag onClick={this.handleStrategyAdd} style={{ cursor: 'pointer' }}><PlusOutlined /> 添加</Tag>
                            }
                            
                        </Descriptions.Item>
                        <Descriptions.Item label="操作" span={3}>
                            <Button onClick={() => this.showCampaign(userList.campain)}>查看计划</Button>
                            &nbsp;
                            <Button onClick={this.updateCustomId}>更改自定义ID</Button>
                            &nbsp;
                            {data.remoteId && !data.succeeded && <Button onClick={this.changeToSuccess}>改为成功</Button>}
                            &nbsp;
                            <Button onClick={this.rerun}>重新运行</Button>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="日志" />
                    <JobLogs logs={data.logs} />
                </div>
            )
        } else {
            return null;
        }
    }
}