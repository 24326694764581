import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu } from 'antd';

class AdminSidebar extends React.Component {
    render() {
        const { pathname } = this.props.history.location;
        let activeKey = '';
        if (pathname === '/admin' || /^\/admin\/jobs/.test(pathname)) {
            activeKey = 'jobs';
        } else if (/^\/admin\/users/.test(pathname)) {
            activeKey = 'users';
        } else if (/^\/admin\/user-group/.test(pathname)) {
          activeKey = 'user-group';
        } else if (/^\/admin\/providers/.test(pathname)) {
            activeKey = 'providers';
        } else if (/^\/admin\/excel/.test(pathname)) {
            activeKey = 'excel'
        } else if (/^\/admin\/advertiser-role/.test(pathname)) {
            activeKey = 'advertiser-role'
        }
        return (
            <div className="ui-admin-sidebar">
                <Menu
                    selectedKeys={[activeKey]}
                >
                    <Menu.Item key="jobs"><NavLink to="/admin/jobs">任务管理</NavLink></Menu.Item>
                    <Menu.Item key="users"><NavLink to="/admin/users">用户管理</NavLink></Menu.Item>
                    <Menu.Item key="user-group"><NavLink to="/admin/user-group">用户组管理</NavLink></Menu.Item>
                    <Menu.Item key="providers"><NavLink to="/admin/providers">服务商管理</NavLink></Menu.Item>
                    <Menu.Item key="excel"><NavLink to="/admin/excel">excel表记录</NavLink></Menu.Item>
                    <Menu.Item key="advertiser-role"><NavLink to="/admin/advertiser-role">广告主权限</NavLink></Menu.Item>
                </Menu>
            </div>
        )
    }
}

export default withRouter(AdminSidebar);
