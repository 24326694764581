import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import StrategyAiEditorForm from './edit-ai-form'

export default function StrategyAiEditor({ strategyAiId: id, onFinish, isSelf }) {
    const [visible, setVisible] = useState(false);
    return (
        <>
            {id ? <Button onClick={() => setVisible(true)}>{isSelf ? '编辑' : '查看'}</Button> : <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setVisible(true)}
            >添加AI策略</Button>}
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                destroyOnClose
                footer={null}
                width={800}
                title={`${isSelf ? id ? '编辑' : '添加' : '查看'}Ai策略`}
            >
                <StrategyAiEditorForm strategyAiId={id} onFinish={onFinish} isSelf={isSelf} visible={visible} setVisible={setVisible} />
            </Modal>
        </>
    )
}
