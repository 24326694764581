const FloatPercentRule = {
    reg: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?%?$/,
    msg: '最多输入两位小数'
}
const DateRule = {
    reg: /(^\d{4}-\d{2}-\d{2}$)|(^0$)/,
    msg: '请选择日期，不能选过去的日期'
};
const IntRule = {
    reg: /^[+]{0,1}(\d+)$/,
    msg: '请输入正整数'
};
const Float2Rule = {
    reg: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,3})?$/,
    msg: '最多输入三位小数'
};
const TimeRule = {
    reg: /^\d{2}:\d{2}$/,
    msg: '开始时间不能晚于结束时间'
}
const SplitRule = {
    reg: /\S/,
    msg: '为空请填写`；`'
}
const operations = [
    {
        name: 'notify',
        label: '飞书通知',
        valueType: 'string',
        value2Type: 'string',
        placeholder: '群机器人webhook地址',
        placeholder2: '通知内容',
    },
    {
        name: 'stop',
        label: '暂停投放',
        valueType: 'none',
    },
    {
        name: 'open',
        label: '开启计划',
        valueType: 'none'
    },
    {
        name: 'delActive',
        label: '删除创意(仅腾讯)',
        valueType: 'none'
    },
    {
        name: 'updateBudget',
        label: '修改计划预算(在末尾加上%代表百分比)',
        valueType: 'string',
        rule: FloatPercentRule,
    },
    {
        name: 'updateDate',
        label: '修改投放时间(adq3.0为暂停)',
        valueType: 'radio',
        value2Type: 'date',
        value3Type: 'radio',
        value4Type: 'time',
        rule2: DateRule,
        rule4: TimeRule,
    },
    {
        name: 'updateRelativeDate',
        label: '修改投放相对时间(adq3.0为暂停)',
        valueType: 'radio',
        value2Type: 'string',
        value3Type: 'radio',
        value4Type: 'time',
        placeholder2: '天数',
        rule2: IntRule,
        rule4: TimeRule,
    },
    {
        name: 'updateBid',
        label: '修改出价(在末尾加上%代表百分比)',
        valueType: 'string',
        rule: FloatPercentRule,
    },
    {
        name: 'updateRoi',
        label: '修改首日付费ROI',
        valueType: 'string',
        rule: Float2Rule,
    },
    {
        name: 'copyAds',
        label: '复制广告',
        valueType: 'string',
        rule: IntRule,
        placeholder: '共需复制广告条数',
        value2Type: 'string',
        rule2: SplitRule,
        placeholder2: '要更换的定向包名称',
        tips: () => (
            <div>
                <p>多个定向包名称之间用 <font color="#f00">中文分号；</font> 分割，若均不需要修改定向包，请直接输入<font color="#f00">；</font></p>
                <hr color="#efefef" />
                <p>例1：需复制4条广告，且均需要修改定向，即输入“<strong><font color="#f00">定向包1；定向包2；定向包3；定向包4</font></strong>”。</p>
                <p>例2：需复制4条广告，且只有2个广告需要修改定向，即输入“<strong><font color="#f00">定向包1；定向包2</font></strong>”。</p>
                <p>例3：需复制4条广告，且均不修改定向，即输入“<strong><font color="#f00">；</font></strong>”。</p>
            </div>
        ),
    },
]
export default operations;
