export const ADSTATUS = [
    {
        value: 'ADSTATUS_NORMAL',
        title: '投放中',
    },
    {
        value: 'ADSTATUS_READY',
        title: '待投放',
    },
    {
        value: 'ADSTATUS_SUSPEND',
        title: '已暂停'
    }
];

const CAMPAIGN_STATUS = [
    {
        value: 0,
        title: '新计划'
    },
    {
        value: 1,
        title: '老计划'
    }
]

/**
 * @description: 组装广告位数据
 * @param {*} list 从数据库取出的扁平的数据
 * @return {*} 数组对象，value为其所有子和广告位的id
 [{
    value: '1,2,3',
    label: '朋友圈信息流'
 }]
 */
 export function createTree(list) {
    function getTrees(list, pId) {
        let items = {};
        // 获取每个节点的父节点
        for (let i = 0; i < list.length; i++) {
            let key = list[i].parentId;
            if (items[key]) {
                items[key].push(list[i]);
            } else {
                items[key] = [];
                items[key].push(list[i]);
            }
        }
        return formatTree(items, pId);
    }

    // 格式化每个节点
    function formatTree(items, parentId) {
        let result = [];
        if (!items[parentId]) {
            return result;
        }
        for (let t of items[parentId]) {
            if (t.config) {
                result.push({
                    label: t.posTypeName,
                    // mp朋友圈1/3/4/5图crtSize相同，用图片张数区分
                    // TODO：adq也有类似情况，尚未处理
                    value: t.crtSize === 666 ? JSON.parse(t.config).num : t.crtSize
                });
            } else {
                const values = getValues(formatTree(items, t.id).map(o => o.value));
                result.push({
                    label: t.posTypeName,
                    value: JSON.stringify(values),
                    children: formatTree(items, t.id)
                });
            }
        }
        return result;
    }
    return getTrees(list, 0);
}

// 递归将多维数组变成一维数组
function getValues(array) {
    const values = [];
    for (const arr of array) {
        if (Array.isArray(arr)) {
            values.push(...getValues(arr));
        } else {
            if (String(arr).includes('[')) {
                values.push(...getValues(JSON.parse(arr)));
            } else {
                values.push(arr);
            }
        }
    }
    return values;
}

const IntRule = {
    reg: /^[+]{0,1}(\d+)$/,
    msg: '请输入正整数'
};
const FloatRule = {
    reg: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
    msg: '最多输入两位小数'
};
const FloatRule3 = {
    reg: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,3})?$/,
    msg: '最多输入三位小数'
};
const PercentRule = {
    reg: /^((\d|[1-9]\d)(\.\d{1,2})?$)/,
    msg: '请输入0-100之间的数字，最多两位小数'
}
const StatusRule = {
    reg: new RegExp(ADSTATUS.map(o => o.value).join('|')),
    msg: '请选择状态'
}

const CampaignStatusRule = {
    reg: new RegExp(CAMPAIGN_STATUS.map(o => o.value).join('|')),
    msg: '请计划状态'
}

const PosTypeRule = {
    reg: /\S/,
    msg: '请选择版位'
}

export const conditionTypes = [
    { value: 'today', label: '当日数据', default: true },
    { value: 'acc', label: '累计数据' },
]

export const conditions = [
    {
        name: 'paid',
        label: '消耗(元)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'exp_pv',
        label: '曝光次数',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'clk_pv',
        label: '点击次数',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'ecpm',
        label: '千次曝光成本',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'ctr',
        label: '点击率(%)',
        rule: PercentRule,
        conditionType: 'today',
    },
    // {
    //     name: 'ctr_3',
    //     label: '3日点击率(%)',
    //     rule: PercentRule,
    //     conditionType: 'today',
    // },
    // {
    //     name: 'ctr_7',
    //     label: '7日点击率(%)',
    //     rule: PercentRule,
    //     conditionType: 'today',
    // },
    {
        name: 'conv_index',
        label: '转化目标量',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'conv_index_cpa',
        label: '转化目标成本(元)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'create_role_cost',
        label: '首日创角成本(元)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'create_role_rate',
        label: '首日创角率(%)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'new_pay_user',
        label: '首日付费人数',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'first_pay_num',
        label: '首次付费人数',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'create_role',
        label: '首日创角人数',
        rule: IntRule,
        conditionType: 'today',
    },
    {
        name: 'new_pay_rate',
        label: '首日付费率(%)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'new_pay_cost',
        label: '首日付费成本(元)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'new_pay_even',
        label: '首日人均付费金额(元)',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'ROI_1',
        label: '当日ROI',
        rule: FloatRule3,
        conditionType: 'today',
    },
    // {
    //     name: 'ROI_3',
    //     label: '3日ROI',
    //     rule: FloatRule3,
    //     conditionType: 'today',
    // },
    // {
    //     name: 'ROI_7',
    //     label: '7日ROI',
    //     rule: FloatRule3,
    //     conditionType: 'today',
    // },
    // {
    //     name: 'ROI_14',
    //     label: '14日ROI',
    //     rule: FloatRule3,
    //     conditionType: 'today',
    // },
    // {
    //     name: 'ROI_21',
    //     label: '21日ROI',
    //     rule: FloatRule3,
    //     conditionType: 'today',
    // },
    {
        name: 'bid',
        label: '当前出价（元）',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'ad_status',
        label: '状态',
        rule: StatusRule,
        type: 'enum',
        options: ADSTATUS,
        conditionType: 'today',
    },
    {
        name: 'validate_user_cost',
        label: '当日有效用户成本（元）',
        rule: FloatRule,
        conditionType: 'today'
    },
    {
        name: 'valid_user',
        label: '当日有效用户数',
        rule: IntRule,
        conditionType: 'today'
    },
    // {
    //     name: 'roi_ratio',
    //     label: 'ROI(24H)系数',
    //     rule: FloatRule3,
    //     conditionType: 'today'
    // },
    {
        name: 'new_user',
        label: '新增用户数',
        rule: IntRule,
        conditionType: 'today'
    },
    {
        name: 'new_user_cost',
        label: '新增用户成本',
        rule: FloatRule,
        conditionType: 'today'
    },
    {
        name: 'pay_times_cost',
        label: '付费成本',
        rule: FloatRule,
        conditionType: 'today'
    },
    {
        name: 'register_cost',
        label: '注册成本（腾讯）',
        rule: FloatRule,
        conditionType: 'today'
    },
    {
        name: 'ad_roi_first',
        label: '首日广告ROI',
        rule: FloatRule3,
        conditionType: 'today'
    },
    {
        name: 'mix_roi',
        label: '混合变现ROI',
        rule: FloatRule3,
        conditionType: 'today'
    },
    // {
    //     name: 'ad_roi_first_7',
    //     label: '七日广告ROI（腾讯）',
    //     rule: FloatRule3,
    //     conditionType: 'today'
    // },
    {
        name: 'acc_paid',
        label: '累计消耗(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_exp_pv',
        label: '累计曝光次数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_clk_pv',
        label: '累计点击次数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_ecpm',
        label: '累计千次曝光成本',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_ctr',
        label: '累计点击率(%)',
        rule: PercentRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_conv_index',
        label: '累计转化目标量',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_conv_index_cpa',
        label: '累计转化目标成本(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_new_pay_rate',
        label: '累计首日付费率(%)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_create_role_cost',
        label: '累计创角成本(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_create_role_rate',
        label: '累计创角率(%)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_new_pay_cost',
        label: '累计首日付费成本(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_new_pay_even',
        label: '累计首日人均付费金额(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_roi_1',
        label: '累计首日ROI',
        rule: FloatRule3,
        conditionType: 'acc'
    },
    {
        name: 'acc_open_days',
        label: '累计开启天数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'is_old',
        label: '新老计划',
        rule: CampaignStatusRule,
        type: 'enum',
        options: CAMPAIGN_STATUS,
        conditionType: 'acc',
    },
    {
        name: 'mp_type',
        label: 'MP版位',
        rule: PosTypeRule,
        type: 'pos_enum',
        options: [],
        conditionType: 'acc',
    },
    {
        name: 'new_mp_type',
        label: 'MP新版版位',
        rule: PosTypeRule,
        type: 'pos_enum',
        options: [],
        conditionType: 'acc',
    },
    {
        name: 'adq_type',
        label: 'ADQ版位',
        rule: PosTypeRule,
        type: 'pos_enum',
        options: [],
        conditionType: 'acc',
    },
    {
        name: 'oe_type',
        label: 'OE版位',
        rule: PosTypeRule,
        type: 'pos_enum',
        options: [],
        conditionType: 'acc',
    },
    {
        name: 'ks_type',
        label: 'KS版位',
        rule: PosTypeRule,
        type: 'pos_enum',
        options: [],
        conditionType: 'acc',
    },
    {
        name: 'acc_pay',
        label: '累计付费(元)',
        rule: FloatRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_valid_user',
        label: '累计有效用户数',
        rule: IntRule,
        conditionType: 'acc'
    },
    {
        name: 'acc_valid_user_cost',
        label: '累计有效用户成本',
        rule: FloatRule,
        conditionType: 'acc'
    },
    {
        name: 'acc_new_pay_user',
        label: '累计首日付费人数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_first_pay_num',
        label: '累计首次付费人数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_create_role',
        label: '累计创角人数',
        rule: IntRule,
        conditionType: 'acc',
    },
    {
        name: 'acc_new_user',
        label: '累计新增用户数',
        rule: IntRule,
        conditionType: 'acc'
    },
    {
        name: 'acc_new_user_cost',
        label: '累计新增用户成本',
        rule: FloatRule,
        conditionType: 'acc'
    },
    {
        name: 'acc_pay_times_cost',
        label: '累计付费成本',
        rule: FloatRule,
        conditionType: 'acc'
    },
    {
        name: 'acc_first_roi',
        label: '累计首日广告变现ROI',
        rule: FloatRule3,
        conditionType: 'acc'
    }
];

export const aiConditions = [
    {
        name: 'cost',
        label: '消耗（元）',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'first_roi',
        label: '首日roi',
        rule: FloatRule3,
        conditionType: 'today',
    },
    {
        name: 'first_ad_roi',
        label: '首日广告roi',
        rule: FloatRule3,
        conditionType: 'today',
    },
    {
        name: 'first_mix_roi',
        label: '首日混合roi',
        rule: FloatRule3,
        conditionType: 'today',
    },
    {
        name: 'create_role_cost',
        label: '创角成本（元）',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'pay_times_cost',
        label: '付费成本(每次)（元）',
        rule: FloatRule,
        conditionType: 'today',
    },
    {
        name: 'validate_user_cost',
        label: '有效用户成本（元）',
        rule: FloatRule,
        conditionType: 'today',
    },
]
