import { useEffect, useState } from "react";
import { Button, TimePicker } from "antd";
import moment from "moment";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

const TIME_FORMAT = 'HH:mm';

function deepCopy(arr) {
    return JSON.parse(JSON.stringify(arr));
}

export default function ExecTimeGroup({ value, onChange }) {
    const [execTimeList, setExecTimeList] = useState([]);
    useEffect(() => {
        onChange(execTimeList);
    }, [execTimeList, onChange]);

    useEffect(() => {
        if (value && value.length) {
            setExecTimeList(value);
        }
    }, [value]);

    const addExecTime = () => {
        setExecTimeList([...execTimeList, ['00:00', '23:59']]);
    }

    const removeExecTime = (index) => {
        const temp = deepCopy(execTimeList);
        temp.splice(index, 1);
        setExecTimeList(temp);
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
            {execTimeList.map((execTime, i) => (
                <div style={{ flexShrink: 0, marginTop: 3 }} key={i}>
                    <TimePicker.RangePicker
                        allowClear={false}
                        format={TIME_FORMAT}
                        defaultValue={[ moment(execTime[0], TIME_FORMAT), moment(execTime[1], TIME_FORMAT) ]}
                        style={{ width: 150 }}
                        value={execTime.map(v => moment(v, TIME_FORMAT))}
                        onChange={(e) => {
                            if (!e) return;
                            const temp = deepCopy(execTimeList);
                            temp[i] = [moment(e[0]).format(TIME_FORMAT), moment(e[1]).format(TIME_FORMAT)];
                            setExecTimeList(temp);
                        }}
                    />
                    <Button style={{color: 'red', padding: '4px 4px 4px 0'}} onClick={() => { removeExecTime(i) }} type="text"><DeleteOutlined /></Button>
                </div>
            ))}
            <Button style={{ marginTop: 3 }} onClick={addExecTime} icon={<PlusCircleOutlined />}>添加执行时间</Button>
        </div>
    )
}