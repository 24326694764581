import { Spin, Table, Result } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { $get } from '../../helpers/remote';

export default function PageStatus() {
    const [lines, setLines] = useState();
    const [providers, setProviders] = useState();
    const [queueLength, setQueueLength] = useState();
    useEffect(() => {
        $get('/status/sync', undefined, { hideLoading: true }).then(data => {
            setLines(data.lines);
            setProviders(data.providers);
            setQueueLength(data.queueLength);
        })
    }, []);
    const syncJobs = useMemo(() => {
        if (!lines) {
            return [];
        }
        const items = lines.filter(line => /^syncer\(\d+\)\s(.+)\s(.*)$/.test(line.content)).map(line => {
            const [, tag, content] = /^syncer\(\d+\)\s(.+)\s(.*)$/.exec(line.content);
            return { tag, content, time: line.time }
        });
        const syncJobs = [];
        items.forEach(item => {
            if (!syncJobs.length && item.tag !== 'start') {
                return;
            }
            console.log(item);
            if (item.tag === 'start') {
                let providerId = 0;
                let providerName = 0;
                let advertiserId = 0;
                let advertiserName = 0;
                try {
                    const job = JSON.parse(item.content);
                    providerId = job.providerId;
                    providerName = job.providerName;
                    advertiserId = job.advertiserId;
                    advertiserName = job.advertiserName;
                } catch (e) {
                    // noop
                }
                syncJobs.push({
                    providerId,
                    providerName,
                    advertiserId,
                    advertiserName,
                    createdAt: item.time,
                });
            } else if (item.tag === 'get-reports') {
                syncJobs[syncJobs.length - 1].reportsCount = Number(item.content);
            } else if (item.tag === 'get-hour-reports') {
                syncJobs[syncJobs.length - 1].hourReportsCount = Number(item.content);
            } else if (item.tag === 'FINISH') {
                syncJobs[syncJobs.length - 1].finishedAt = item.time;
            }
        });
        return syncJobs;
    }, [lines]);
    const providersWithStatus = useMemo(() => {
        if (!providers || !syncJobs) {
            return [];
        }
        const jobs = [...syncJobs].reverse();
        return providers.map(provider => {
            const job = jobs.find(job => job.providerId === provider.id && (job.reportsCount || job.hourReportsCount));
            let status = '正常';
            if (!provider.online) {
                status = '异常：已掉线';
            } else if (!job?.createdAt) {
                status = '异常：1小时内无数据';
            } else if (moment(job.createdAt).isBefore(moment().subtract(30, 'minutes'))) {
                status = '需要关注：同步速度过慢';
            }
            return {
                id: provider.id,
                name: provider.agencyName,
                online: provider.online,
                activeAdvertisersCount: provider.activeAdvertisersCount,
                latestTime: job?.createdAt,
                status,
            }
        });
    }, [syncJobs, providers]);
    const globalStatus = useMemo(() => {
        if (!providersWithStatus || !syncJobs) {
            return {};
        }
        if (queueLength > 1000) {
            return {
                status: 'error',
                title: 'sync-job队列积压过多',
            }
        }
        const jobs = [...syncJobs].reverse();
        const latestJob = jobs.find(job => job.reportsCount || job.hourReportsCount);
        if (!latestJob) {
            return {
                status: 'error',
                title: '近一小时无成功同步',
            };
        }
        if (moment(latestJob.createdAt).isBefore(moment().subtract(5, 'minutes'))) {
            return {
                status: 'info',
                title: '近5分钟无数据',
            }
        }
        const aProviders = providersWithStatus.filter(p => p.status === '正常');
        if (aProviders.length === providersWithStatus.length) {
            return {
                status: 'success',
                title: '同步服务运行正常',
            };
        }
        if (!aProviders.length) {
            return {
                status: 'error',
                title: '服务商全部异常',
            }
        }
        return {
            status: 'warning',
            title: '部分服务商异常',
        }
    }, [providersWithStatus, syncJobs, queueLength]);
    if (!lines || !providers) {
        return <div style={{ textAlign: 'center' }}><Spin size="large" spinning /></div>
    }
    return <div>
        <Result
            status={globalStatus.status}
            title={globalStatus.title}
        />
        <h2>服务商状态</h2>
        <Table
            pagination={false}
            rowKey="providerId"
            columns={[
                {
                    title: 'ID',
                    key: 'id',
                    dataIndex: 'id',
                },
                {
                    title: '服务商',
                    key: 'name',
                    dataIndex: 'name',
                    render: (name, row) => {
                        return `${name}(活主${row.activeAdvertisersCount}个)`
                    }
                },
                {
                    title: '状态',
                    key: 'status',
                    dataIndex: 'status',
                },
                {
                    title: '是否在线',
                    key: 'online',
                    dataIndex: 'online',
                    render: online => online ? '在线' : '掉线',
                },
                {
                    title: '最新有效同步时间',
                    key: 'latestTime',
                    dataIndex: 'latestTime',
                    render: time => {
                        if (!time) {
                            return '1小时内无记录';
                        }
                        return moment(time).format('MM-DD HH:mm:ss');
                    },
                },
            ]}
            dataSource={providersWithStatus}
        />
        <h2 style={{ marginTop: '32px' }}>当前sync-job队列长度</h2>
        <div>{queueLength}</div>
        <h2 style={{ marginTop: '32px' }}>最新同步记录</h2>
        <Table
            columns={[
                {
                    title: '时间',
                    key: 'time',
                    dataIndex: 'createdAt',
                    render: (time) => moment(time).format('MM-DD HH:mm:ss'),
                },
                {
                    title: '服务商',
                    key: 'provider',
                    render: (_, row) => `${row.providerName}(${row.providerId})`,
                },
                {
                    title: '广告主',
                    key: 'advertiser',
                    render: (_, row) => `${row.advertiserName}(${row.advertiserId})`,
                },
                {
                    title: '日数据条数',
                    key: 'reportsCount',
                    dataIndex: 'reportsCount',
                },
                {
                    title: '小时数据条数',
                    key: 'hourReportsCount',
                    dataIndex: 'hourReportsCount',
                },
                {
                    title: '耗时(s)',
                    key: 'duration',
                    render(_, row) {
                        return row.finishedAt ? moment(row.finishedAt).unix() - moment(row.createdAt).unix() : '-';
                    }
                },
            ]}
            rowKey="createdAt"
            dataSource={syncJobs.slice(-20).sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())}
            pagination={false}
        />
    </div>
}
