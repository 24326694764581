import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './header.less';
import { $get } from '../helpers/remote';
import moment from 'moment';
import { setWatermark } from '../utils/js/setWatermark';

const { Header } = Layout;

class HeaderComp extends React.Component {
    state = {
        phone: null,
        roles: [],
        isOwnUser: false,
    }
    async componentDidMount() {
        const { phone, roles, name, expireAt, groupId, id } = await $get('/users/profile', {}, {
            hideLoading: true
        });
        setWatermark(`${name}-${id}`);
        const isOwnUser = groupId ? true : false;
        this.setState({ phone, roles, name, expireAt, isOwnUser });
        if (this.state.expireAt == null) {
            this.setState ({
                describe: '正式版'
            });
        } else {
            const time1 = moment().format('YYYY-MM-DD');
            const time2 = moment(this.state.expireAt,'YYYY-MM-DD');
            this.setState ({
                describe: '试用版',
                expireAt: `剩余${time2.diff(time1, 'day')}天`
            });
        }
    }
    render() {
        const { pathname } = this.props.history.location;
        const { roles, isOwnUser } = this.state;
        const isAdmin = roles.includes('admin');
        let activeKey = '';
        if (pathname === '/' || /^\/providers/.test(pathname)) {
            activeKey = 'providers';
        } else if (/^\/jobs/.test(pathname)) {
            activeKey = 'jobs';
        } else if (/^\/auto-uploads/.test(pathname)) {
            activeKey = 'auto-uploads';
        } else if (/^\/materials/.test(pathname)) {
            activeKey = 'materials';
        } else if (/^\/tools/.test(pathname)) {
            activeKey = 'tools';
        } else if (/^\/user/.test(pathname)) {
            activeKey = 'user';
        } else if (/^\/monitor/.test(pathname)) {
            activeKey = 'monitor';
        } else if (/^\/admin/.test(pathname)) {
            activeKey = 'admin';
        } else if (/^\/reports/.test(pathname)) {
            activeKey = 'reports';
        } else if (/^\/strategy-stats/.test(pathname)) {
            activeKey = 'strategy-stats';
        } else if (/^\/strategy/.test(pathname)) {
            activeKey = 'strategy';
        }
        const style = {
            float: 'right'
        }
        return (
            <Header>
                <div className="logo">易起量</div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[activeKey]}
                    style={{ lineHeight: '64px' }}
                >
                    <Menu.Item key="providers"><NavLink to="/providers">服务商</NavLink></Menu.Item>
                    <Menu.Item key="jobs"><NavLink to="/jobs">投放任务</NavLink></Menu.Item>
                    { isOwnUser && <Menu.Item key="auto-uploads"><NavLink to="/auto-uploads">自动上传</NavLink></Menu.Item> }
                    <Menu.Item key="materials"><NavLink to="/materials">素材管理</NavLink></Menu.Item>
                    { isOwnUser && <Menu.Item key="monitor"><NavLink to="/monitor">监控中心</NavLink></Menu.Item> }
                    { isOwnUser && <Menu.Item key="strategy"><NavLink to="/strategy">智能策略</NavLink></Menu.Item> }
                    { isOwnUser && <Menu.Item key="strategy-stats"><NavLink to="/strategy-stats">策略统计</NavLink></Menu.Item> }
                    { isAdmin && <Menu.Item key="admin"><NavLink to="/admin">管理后台</NavLink></Menu.Item> }
                    { activeKey === 'reports' && <Menu.Item key="reports"><NavLink to="/reports/rounds/">同步日志</NavLink></Menu.Item> }
                    <Menu.Item style={style} key="user">
                        <NavLink to="/user">姓名：{this.state.name} {this.state.describe}  {this.state.expireAt}</NavLink>
                    </Menu.Item>
                </Menu>
            </Header>
        )
    }
}

export default withRouter(HeaderComp);
