import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Progress, message, Result } from 'antd';
import { $post } from '../../helpers/remote';

export default class FetchMetric extends Component {
    static propTypes = {
        providerId: PropTypes.string.isRequired,
        advertiserId: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    }
    state = {
        total: 0,
        needLogin: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState({ total: 0, page: 1, needLogin: false }, () => this.fetch());
        }
    }
    fetch = async () => {
        try {
            const items = await $post(
                `/providers/${this.props.providerId}/advertisers/${this.props.advertiserId}/metrics/fetch`,
                {},
                { hideLoading: true, throwException: true }
            );
            this.setState({
                total: items.length,
            });
        } catch (e) {
            if (e.response && /登录状态过期/.test(e.response.data.message)) {
                this.setState({ needLogin: true });
            } else if (e.response && /incorrect\sheader\scheck/.test(e.response.data.message)) {
                this.fetch();
            } else {
                message.error(e.response ? e.response.data.message : e.message);
            }
        }
    }
    onClose = () => {
        this.props.onClose();
    }
    render() {
        const { visible } = this.props;
        const { total, needLogin } = this.state;
        const percent = total ? 100 : 0;
        return (
            <Modal
                title="正在更新投放计划列表"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <div className="page-provider-fetch" style={{ textAlign: 'center' }}>
                    { needLogin && <Result
                        status="error"
                        title="服务商登陆状态过期"
                        subTitle="请返回扫码更新状态"
                    /> }
                    { !needLogin && percent < 100 && <Progress type="circle" percent={percent} status="active" /> }
                    { !needLogin && percent >= 100 && <Result
                        status="success"
                        title="列表刷新完成"
                    /> }
                </div>
            </Modal>
        )
    }
}
