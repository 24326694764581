import React, { Component } from 'react';
import { Row, Col, List, Button, Pagination, Select, Upload, message, Modal } from 'antd';
import { $get, $post, $upload } from '../../helpers/remote';

const { Option } = Select;
const AdvertiserPageSize = 10;

export default class UploadCampaign extends Component {
    state = {
        //两列素材的数据，arr[0]是数据列表，arr[1]是总条数
        excelDatas: [[], 0],
        advertiserDatas: [[], 0],
        //选中的素材id[]
        selectedExcelIds: [],
        selectedAdvertiserIds: [],
        //选中的素材的内容content[]
        selectedExcelItems: [],
        selectedAdvertiserItems: [],
        fileList: [],
        uploading: false,
        //当前选中的providerId
        providerId: 0,
        providerDatas: [[], 0],
        agencyName: '',
        advertiserPage: 1,
    }
    componentDidMount() {
        console.log('2: ', this.props.pages, this.props.group);
        this.fetch();
    }
    handleClick = (key, id, content) => {
        const ids = this.getSelected(key, 'selectedIds');
        const items = this.getSelected(key, 'selectedItems');
        if (ids.includes(id)) {
            const newIds = ids.filter(item => item !== id);
            const newItems = items.filter(item => item.id !== id);
            this.setSelected(key, 'selectedIds', newIds);
            this.setSelected(key, 'selectedItems', newItems);
        } else {
            ids.push(id);
            items.push({
                id,
                content
            });
            this.setSelected(key, 'selectedIds', ids);
            this.setSelected(key, 'selectedItems', items);
        }
    }
    handleProviderChange = async (e) => {
        const { advertiserPage } = this.state;
        const providerId = e;
        const advertiserDatas = await this.getAdvertisers(advertiserPage, providerId);
        this.setState({ advertiserDatas, providerId });
    }
    handleDelete = (e, id) => {
        e.stopPropagation();
        const { excelDatas, selectedExcelIds, selectedExcelItems } = this.state;
        if (selectedExcelIds.includes(id)) {
            const newIds = selectedExcelIds.filter(item => item !== id);
            const newItems = selectedExcelItems.filter(item => item.id !== id);
            this.setState({
                selectedExcelIds: newIds,
                selectedExcelItems: newItems
            });

        }
        excelDatas[0] = excelDatas[0].filter(item => item.id !== id);
        this.setState({ excelDatas });
    }
    getProviders = async () => {
        return await $get(`/providers?page=1&pageSize=50`);
    }
    getAdvertisers = async (page, providerId) => {
        return await $get(`/providers/${providerId}/advertisers?page=${page}&pageSize=${AdvertiserPageSize}`);
    }
    nextPage = async (page) => {
        const { providerId } = this.state;
        const data = await this.getAdvertisers(page, providerId);
        this.setState({
            advertiserDatas: data,
            advertiserPage: page
        });
    }
    submit = async () => {
        const { selectedExcelItems, selectedAdvertiserIds, excelDatas } = this.state;
        let { pages } = this.props;
        if (!pages || !pages.length) {
            message.error('请先选择素材，并生成落地页');
            return;
        }
        let result = [];
        if (!excelDatas[0].length) {
            message.error('请上传excel文件');
            return;
        }
        if (!selectedExcelItems || !selectedExcelItems.length) {
            message.error('请选择excel文件');
            return;
        }
        if (!selectedAdvertiserIds || !selectedAdvertiserIds.length) {
            message.error('请选择广告主');
            return;
        }
        let num = 0;
        for (const advertiserId of selectedAdvertiserIds) {
            result = [];
            for (const excel of selectedExcelItems) {
                for (let i = 0; i < pages.length; i++) {
                    result.push({
                        excelUrl: excel.content,
                        pageMaterialId: pages[i].id,
                        textMaterialId: pages[i].textMaterialId,
                        num: pages[i].num
                    });
                }
            }
            const res = await this.submitCampainExcels({ advertiserId, excels: result });
            num += res.length;
        }
        Modal.success({
            title: '上传成功',
            content: `已生成${num}个计划`
        });
    }
    submitCampainExcels = async (data) => {
        const { providerId } = this.state;
        const { advertiserId, excels } = data;
        return await $post(`/providers/${providerId}/campains/excels?advertiserId=${advertiserId}&metricId=0`, { excels });
    }
    fetch = async (params) => {
        let { advertiserDatas, advertiserPage, agencyName, providerId } = this.state;
        const providerDatas = await this.getProviders();
        if (providerDatas[0] && providerDatas[0].length) {
            providerId = providerDatas[0][0].id;
            agencyName = providerDatas[0][0].agencyName;
            advertiserDatas = await this.getAdvertisers(advertiserPage, providerId);
        }
        this.setState({ providerDatas, advertiserDatas, agencyName, providerId });
    };
    beforeUpload = file => {
        const { fileList } = this.state;
        const excelIndex = this.excelFileIndex;
        if (/\.xlsx?$/.test(file.name)) {
            if (excelIndex > -1) {
                fileList[excelIndex] = file;
            } else {
                fileList.push(file);
            }
        }
        this.setState({
            fileList,
        });
        return false;
    }
    handleUpload = async () => {
        const { fileList } = this.state;
        const excelIndex = this.excelFileIndex;
        const zipIndex = this.zipFileIndex;
        const data = {
            excel: fileList[excelIndex],
        };
        if (zipIndex > -1) {
            data.zip = fileList[zipIndex];
        }
        this.setState({ uploading: true });
        try {
            const url = await $upload(
                `/materials/uploadFile?filename=${data.excel.name}`,
                data,
                { hideLoading: true, throwException: true }
            );
            const { excelDatas } = this.state;
            excelDatas[0].unshift({id: excelDatas[0].length, name: data.excel.name, url })
            this.setState({ uploading: false, results: url.length });
        } catch (e) {
            this.setState({ uploading: false });
            message.error((e.response && e.response.data && e.response.data.message) || String(e));
        }
    }
    get excelFileIndex() {
        const { fileList } = this.state;
        let excelIndex = -1;
        fileList.forEach((file, index) => {
            if (/\.xlsx?$/.test(file.name)) {
                excelIndex = index;
            }
        });
        return excelIndex;
    }
    getSelected = (key, type) => {
        let content = [];
        if (type === 'selectedIds') {
            const { selectedExcelIds, selectedAdvertiserIds } = this.state;
            if (key === 'excel') {
                content = selectedExcelIds;
            } else if (key === 'advertiser') {
                content = selectedAdvertiserIds;
            }
        } else if (type === 'selectedItems') {
            const { selectedExcelItems, selectedAdvertiserItems } = this.state;
            if (key === 'excel') {
                content = selectedExcelItems;
            } else if (key === 'advertiser') {
                content = selectedAdvertiserItems;
            }
        }
        return content;
    }
    setSelected = (key, type, newContent) => {
        if (type === 'selectedIds') {
            if (key === 'excel') {
                this.setState({ selectedExcelIds: newContent });
            } else if (key === 'advertiser') {
                this.setState({ selectedAdvertiserIds: newContent });
            }
        } else if (type === 'selectedItems') {
            if (key === 'excel') {
                this.setState({ selectedExcelItems: newContent });
            } else if (key === 'advertiser') {
                this.setState({ selectedAdvertiserItems: newContent });
            }
        }
    }
    render() {
        const { selectedExcelIds, selectedAdvertiserIds,
                excelDatas, advertiserDatas,
                fileList, uploading,
                providerDatas, agencyName, advertiserPage } = this.state;
        return <div className='page-auto-upload'>
            <div className='input-box'>
                <Button type='primary' onClick={ this.submit }>上传</Button>
            </div>
            <Row gutter={8}>
                <Col span={8}>
                    <div className='col-header'>
                        <h4>计划模版</h4>
                        <Upload
                            accept='.xls,.xlsx'
                            fileList={fileList}
                            beforeUpload={this.beforeUpload}
                            disabled={uploading}
                            onChange={this.handleUpload}
                        >
                            <Button>添加</Button>
                        </Upload>
                    </div>
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={ excelDatas[0] }
                        renderItem={ (item, index) => (
                            <List.Item className={ `list-item-bt ${selectedExcelIds.includes(item.id) ? 'selected' : ''}` } onClick={ () => this.handleClick('excel', item.id, item.url) }>
                                <span>{ item.name }</span>
                                <Button danger type='text' onClick={ e => this.handleDelete(e, item.id) }>删除</Button>
                            </List.Item>
                        ) }
                    />

                </Col>
                <Col span={8}>
                    <div className='col-header'>
                        <h4>广告主</h4>
                        <Select key={ agencyName } defaultValue={ agencyName } style={{ width: 260 }} dropdownMatchSelectWidth={ false } onChange={ e => this.handleProviderChange(e) }>
                            {
                                providerDatas[0].map(val => {
                                    return <Option key={ val.id } value={ val.id }>{ val.agencyName }</Option>
                                })
                            }
                        </Select>
                    </div>
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={ advertiserDatas[0]}
                        renderItem={ item => (
                            <List.Item className={ `list-item-bt ${selectedAdvertiserIds.includes(item.id) ? 'selected' : ''}` } onClick={ () => this.handleClick('advertiser', item.id, item.name) } >
                                <span>{ item.name }</span>
                            </List.Item>
                        ) }
                    />
                    <Pagination current={ advertiserPage } simple onChange={ page => { this.nextPage(page) } }  total={ advertiserDatas[1] } pageSize={ AdvertiserPageSize } />
                </Col>
            </Row>
        </div>
    }
}
