import React, { Component } from 'react';
import { Collapse } from 'antd';

import './index.less';
import ChooseMaterial from './choose-material';
import UploadCampaign from './upload-campaign';

const { Panel } = Collapse;

export default class AutoUpload extends Component {
    state = {
        pages: [],
        group: null
    }
    handlePages = (data) => {
        const { pages, group } = data;
        this.setState({ pages, group });
    }
    render() {
        return <div className='page-auto-upload'>
            <Collapse defaultActiveKey={['1']} >
                <Panel header="选择素材" key="1">
                    <ChooseMaterial handlePages={ this.handlePages }/>
                </Panel>
                <Panel header="上传计划" key="2">
                    <UploadCampaign pages={ this.state.pages } group={ this.state.group }  />
                </Panel>
            </Collapse>
        </div>
    }
}
