import { Button, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import { $get, $post, $put } from '../../helpers/remote';
import ConditionGroup from './condition-group';
import OperationGroup from './operation-group';
import ExecTimeGroup from './exec-time-group';
import { conditions as _conditions, createTree } from './conditions';

export default function StrategyEditorForm({ strategyId: id, onFinish, visible, setVisible, getStrategyChange, copyData, title, isSelf }) {
    const [loading, setLoading] = useState(false);
    const [strategy, setStrategy] = useState(null);
    const [form] = Form.useForm();
    const [conditions, setConditions] = useState(_conditions);

    // 初始化平台的广告位
    useEffect(() => {
        for (const condition of conditions) {
            let adTypeId;
            if (condition.name === 'mp_type') adTypeId = 1;
            if (condition.name === 'adq_type') adTypeId = 2;
            if (condition.name === 'oe_type') adTypeId = 3;
            if (condition.name === 'ks_type') adTypeId = 4;
            if (condition.name === 'new_mp_type') adTypeId = 5;
            if (adTypeId) {
                $get('/ad-types/adPosType', { adTypeId })
                .then(adOptions => {
                    // oe的版位单独处理
                    if (adTypeId === 3 || adTypeId === 4) {
                        conditions.find(o => o.name === condition.name).options = [{
                            label: '全选',
                            value: JSON.stringify(createTree(adOptions).map(o => o.value))
                        }];
                    } else {
                        conditions.find(o => o.name === condition.name).options = createTree(adOptions);
                    }
                    setConditions(conditions);
                });
            }
        }
    }, [conditions])

    useEffect(() => {
        if (visible && id) {
            $get(`/strategies/${id}`).then(data => {
                if (data) {
                    // 过滤掉不存在的条件
                    const conditionGroups = data.conditionGroups.map(group => {
                        const conditions = group.conditions.filter(o => _conditions.find(c => c.name === o.name));
                        return { ...group, conditions };
                    }).filter(o => o.conditions.length);
                    setStrategy({ ...data, conditionGroups });
                }
            });
        }
    }, [visible, id]);

    const formData = useMemo(() => {
        if (!visible) {
            return null;
        }
        if (!id) {
            form.resetFields();
            if (copyData) {
                return copyData;
            }
            return { title: `新建策略${moment().format('YYYYMMDDHHmmss')}` };
        }
        return strategy;
    }, [strategy, id, visible, form, copyData]);

    const checkForm = (values) => {
        const { conditionGroups, operationGroups, execTimeGroups } = values;
        const [conditions, operations] = [conditionGroups, operationGroups].map((groups, index) => {
            return groups.map(group => {
                const key = index === 0 ? 'conditions' : 'operations';
                const datas = group[key];
                if (!datas) {
                    throw new Error('请填写完整');
                }
                return {
                    ...group,
                    [key]: datas.map(o => {
                        if (o.error) {
                            throw new Error('表单填写错误');
                        }
                        delete o.error;
                        return o;
                    })
                } 
            })
        })
        const execTimes = [];
        if (!execTimeGroups.length) {
            execTimes.push(['00:00', '23:59']);
        } else {
            for (const execTime of execTimeGroups) {
                if (!execTime[0] || !execTime[1]) {
                    throw new Error('执行时间选择有误');
                }
                if (moment(execTime[0]) >= moment(execTime[1])) {
                    throw new Error('开始时间不能晚于结束时间');
                }
                execTimes.push(execTime);
            }
        }
        return { conditionGroups: conditions, operationGroups: operations, execTimeGroups: execTimes };
    }

    const handleSubmit = async (values) => {
        let datas = {};
        try {
            datas = checkForm(values);
        } catch (err) {
            message.error(err.message);
            return;
        }
        const { conditionGroups, operationGroups } = datas;
        if (!conditionGroups.length) {
            message.error('请选择条件');
            return;
        }
        if (!operationGroups.length) {
            message.error('请选择动作');
            return;
        }
        datas.title = values.title;
        setLoading(true);
        let strategyId = 0;
        if (!id) {
            try {
                strategyId = await $post('/strategies', datas, { throwException: 'showMessage' });
                setLoading(false);
                setVisible(false);
                onFinish && onFinish();
            } catch (e) {
                setLoading(false);
            }
        } else {
            try {
                await $put(`/strategies/${id}`, datas, { throwException: 'showMessage' });
                setLoading(false);
                setVisible(false);
                onFinish && onFinish();
            } catch (e) {
                setLoading(false);
            }
        }
        const formData = form.getFieldValue();
        if (getStrategyChange) {
            const strategyData = { ...formData, id: id || strategyId };
            getStrategyChange(strategyData, title);
        }
        form.setFieldsValue(formData);
    }

    return (
        <>
            {(id && !formData) ? null : <div className="ui-strategy-editor">
                <Form initialValues={formData} form={form}>
                    <Form.Item 
                        label="标题" 
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: '请输入标题'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="main">
                        <Form.Item label="执行时间" name="execTimeGroups">
                            <ExecTimeGroup />
                        </Form.Item>
                        <Form.Item label="条件" name="conditionGroups">
                            <ConditionGroup conditions={conditions}/>
                        </Form.Item>
                        <Form.Item label="动作" name="operationGroups">
                            <OperationGroup />
                        </Form.Item>
                    </div>
                    {isSelf && <div>
                        <Button onClick={() => handleSubmit(form.getFieldsValue())} type="primary" loading={loading}>保存</Button>
                    </div>}
                </Form>
            </div>}
        </>
    )
}
