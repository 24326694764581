import React, { Component } from 'react';
import { Table, Button, Tag, Select, Popconfirm } from 'antd';
import moment from 'moment';
import Add from './add';
import Update from './update';
import { $get, $delete } from '../../helpers/remote';
import MaterialPreview from '../../components/material-preview';
import './index.less';

export default class Materials extends Component {
    state = {
        data: [],
        pagination: {
            pageSize: 20,
            page: 1,
        },
        loading: false,
        showAddModal: false,
        showUpdateModal: false,
        tags: [],
        selectedTags: [],
        originalData: [],
    }
    componentDidMount() {
        this.fetch();
        this.fetchTags();
    }
    handleTableChange = (pagination , _, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current,
            orderBy: sorter.field,
            direction: sorter.order === 'ascend' ? 'DESC' : 'ASC'
        });
    };

    deleteMater = async (id) => {
        await $delete(`/materials/${id}`);
        this.fetch();
    }

    get columns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
            },
            {
                title: '预览',
                render: (_, record) => {
                    if (record.type === 'TEXT') {
                        return record.content;
                    }
                    if (record.type === 'IMAGES') {
                        return <MaterialPreview urls={record.urls} type={record.type} />
                    }
                    if (record.url) {
                        return <MaterialPreview url={record.url} type={record.type} />
                    }
                }
            },
            {
                title: '标题',
                // width: 200,
                // textWrap: 'word-break',
                // ellipsis: true,
                dataIndex: 'title'
            },
            {
                title: '标签',
                dataIndex: 'tags',
                render: text => {
                    const tags = text.split(',').filter(o => o && o.length);
                    return tags.map(tag => <Tag key={tag}>{tag}</Tag>)
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createdAt',
                render: text => moment(text).format('YYYY-MM-DD HH:mm')
            },
            {
                title: '消耗',
                dataIndex: 'cost',
                sorter: true,
            },
            {
                title: '曝光次数',
                dataIndex: 'expose',
                sorter: true
            },
            {
                title: '使用频率',
                dataIndex: 'campainsCount',
                sorter: true
            },
            {
                title: '点击次数',
                dataIndex: 'click',
                sorter: true
            },
            {
                title: '点击单价',
                dataIndex: 'price',
                sorter: true
            },
            {
                title: '操作',
                render: (_, record) => {
                    return <div>
                        <Button onClick={() => this.setState({ showUpdateModal: true, originalData: record })}>编辑</Button>
                        <Popconfirm title="删除素材后不可恢复，是否删除？" onText="确认删除" cancelText="点错了" onConfirm={() => this.deleteMater(record.id)}>
                            <Button>删除</Button>
                        </Popconfirm>
                    </div>
                }
            }
        ];
    }
    fetch = async (params) => {
        const { selectedTags, pagination } = this.state;
        const { page, pageSize, orderBy, direction } = params || this.state.pagination;
        this.setState({ loading: true });
        const parameter = { page, pageSize, direction, tags: selectedTags };
        if ( orderBy !== 'id' ) {
            parameter.orderBy = orderBy;
        }
        const [providers, count] = await $get('/materials', parameter, { hideLoading: true });
        pagination.total = count;
        const data = providers.map((priv) => ({
                id:          priv.id,
                tags:        priv.tags,
                createdAt:   priv.createdAt,
                title:       priv.title,
                type:        priv.type,
                url:         priv.url,
                urls:        priv.urls,
                content:     priv.content,
                userId:      priv.userId,
                stat:        priv.stat,
                count:       priv.stat.count,
                campainsCount:priv.stat.campainsCount,
                cost:        priv.stat.cost,
                expose:      priv.stat.expose,
                click:       priv.stat.click,
                price:       priv.stat.price,
            })
        );
        this.setState({
            loading: false,
            data,
            pagination,
        });
    };
    fetchTags = async () => {
        const tags = await $get('/materials/tags', {}, { hideLoading: true });
        this.setState({ tags });
    }
    onAddFinish = (result) => {
        this.setState({ showAddModal: false });
        if (result) {
            this.setState(({ data }) => ({ data: [...result, ...data]}));
            this.fetchTags();
        }
    }
    onUpdateFinish = (result) => {
        this.setState({ showUpdateModal: false });
        this.fetch();
    }
    onTagsChange = (value) => {
        this.setState({ selectedTags: value }, this.fetch);
    }
    render() {
        const { showAddModal, showUpdateModal, tags, selectedTags } = this.state;
        return <div className="page-material">
            <Button onClick={() => this.setState({ showAddModal: true })}>添加素材</Button>
            <span className="tag-label">标签过滤</span>
            <Select mode="multiple" style={{ width: '300px' }} onChange={this.onTagsChange} value={selectedTags} >
                { tags.map(tag => tag.title && <Select.Option value={tag.title} key={tag.id}>{tag.title}</Select.Option>) }
            </Select>
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
            />
            <Add visible={showAddModal} onClose={this.onAddFinish} />
            <Update visible={showUpdateModal} onClose={this.onUpdateFinish} value={this.state.originalData}/>
        </div>
    }
}
