import React, { Component } from 'react';
import moment from 'moment';

class Status extends Component {
    render() {
        const { token, online } = this.props;
        const content = token ? online ? <span className="status-item status-online">{token.name} (上次登录: {moment(token.time).format('MM/DD HH:mm')})</span>
        : <span className="status-item status-offline">{token.name} (上次登录: {moment(token.time).format('MM/DD HH:mm')}) (已失效)</span>
        : <span className="status-item status-offline"> 无</span>;
        return (
            <>
                {content}
            </>
        )
    }
}

export default Status;