/*
 * 拷贝进剪切板
 *
 */
export function copyIntoClipboard(text) {
    const input = document.createElement('INPUT');
    document.body.appendChild(input);

    input.value = text;
    input.select();
    input.setSelectionRange(0, text.length);
    document.execCommand('copy');

    document.body.removeChild(input);
}