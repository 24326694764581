import React, { Component } from 'react';
import { Table, Button, Breadcrumb, Input, Popconfirm } from 'antd';
import { withRouter } from 'react-router-dom';
import Uploader from './upload';
import { $get, $delete } from '../../helpers/remote';
import FetchAdvertiser from './fetch-advertiser';
import FetchStatistic from './fetch-statistic';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';

import './provider.less';
const { Search } = Input;

class Provider extends Component {
    state = {
        data: [],
        pagination: {
            pageSize: 20,
            page: 1,
        },
        loading: false,
        providerName: null,
        fetchModalVisible: false,
        statisticModalVisible: false,
        uploadModalVisible: false,
        advertiserId: 0,
        searchValue: '',
        providerType: null,
        isNewMpFetch: false,
        isOeMarketingFetch: false,
    }
    async componentDidMount() {
        this.fetch();
        const providerId = this.props.match.params.providerId;
        const provider = await $get(`/providers/${providerId}`, {}, { hideLoading: true });
        this.setState({ providerName: provider.agencyName, providerType: provider.type, userId: provider.userId });
    }
    handleTableChange = (pagination) => {
        this.setState({
            pagination: pagination,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current,
        });
    };
    get pagination() {
        return {
            ...this.state.pagination,
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100', '500', '1000'],
        };
    }
    get columns() {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '名称',
                dataIndex: 'name',
                render: (text, record) => <div className="name">
                    {this.state.providerType !== 'adq-wx' ? record.portrait && <img src={`${record.portrait.replace('http://', 'https://')}/0/0`} alt={text} /> : ''}&nbsp;
                    {text}
                </div>
            },
            {
                title: '帐号ID',
                dataIndex: 'uid',
            },
            {
                title: 'appid',
                dataIndex: 'appId',
            },
            {
                title: '微信号',
                dataIndex: 'wxname',
            },
            {
                title: '新版mp',
                dataIndex: 'newAdvertiser',
                render: text => text ? '是' : '否'
            },
            {
                title: '操作',
                render: (text, record) => <div className="operations">
                    {(['wx', 'adq-wx', 'adq-adq', 'oe', 'ks', 'adq-new-wx'].includes(this.state.providerType)) && <>
                        <Button onClick={() => this.openUploadModal(record.id)}>投放</Button>&nbsp;&nbsp;
                        <Popconfirm title="删除广告主后不可恢复，是否删除？" onText="确认删除" cancelText="点错了" onConfirm={() => this.deleteAdvertiser(record.id)}>
                            <Button danger>删除</Button>
                        </Popconfirm></>}
                    {this.state.providerType === 'gdt' && <Button onClick={() => window.location.href = `/providers/${this.props.match.params.providerId}/advertisers/${text.id}`}>进入</Button>}
                    {/* &nbsp;{this.state.providerType === 'wx' && <Button onClick={() => this.onStatisticModalOpen(record.id)}>同步数据</Button>} */}
                </div>,
            },
        ];
    }
    fetch = async (params) => {
        const providerId = this.props.match.params.providerId;
        const { page, pageSize } = params || this.state.pagination;
        const paramsData = {
            page,
            pageSize,
        };
        // 如果不是纯数字则按照名字搜 否则是uid
        if (isNaN(Number(this.state.searchValue))) {
            paramsData.name = this.state.searchValue;
        } else {
            paramsData.advertiserUid = this.state.searchValue;
        }
        this.setState({ loading: true });
        const [providers, count] = await $get(`/providers/${providerId}/advertisers`, paramsData, { hideLoading: true });
        const pagination = { ...this.state.pagination };
        pagination.total = count;
        this.setState({
            loading: false,
            data: providers,
            pagination,
        });
    };
    onFetchModalClose = () => {
        this.setState({ fetchModalVisible: false, isNewMpFetch: false, isOeMarketingFetch: false });
        this.fetch();
    }
    onStatisticModalClose = () => {
        this.setState({ statisticModalVisible: false });
    }
    onStatisticModalOpen = (id) => {
        this.setState({ statisticModalVisible: true, advertiserId: id });
    }
    onCampaignModalClose = () => {
        this.setState({ campaignModalVisible: false });
    }
    openUploadModal = (advertiserId = 0) => {
        this.setState({ uploadModalVisible: true, advertiserId })
    }
    onUploadModalClose = () => {
        this.setState({ uploadModalVisible: false });
    }

    handleSearch = async (value) => {
        this.setState({
            searchValue: value,
        },
            () =>  this.fetch()
        );
    }
    deleteAdvertiser = async (id) => {
        await $delete(`/advertisers/${id}`, {}, { throwException: true });
        this.fetch();
    }

    render() {
        const { providerName, fetchModalVisible, statisticModalVisible, uploadModalVisible, advertiserId, providerType, userId } = this.state;
        const { providerId } = this.props.match.params;
        const isShowMarketingButton = Boolean(Number(this.props.match.params.isShowMarketingButton));
        return <div className="page-provider">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/providers">服务商列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{providerName || '详情'}</Breadcrumb.Item>
            </Breadcrumb>
            <Button icon={<ReloadOutlined />} onClick={() => this.setState({ fetchModalVisible: true })}>刷新列表</Button>&nbsp;
            {isShowMarketingButton && this.state.providerType === 'adq-wx' && <Button icon={<ReloadOutlined />} onClick={() => this.setState({ fetchModalVisible: true, isNewMpFetch: true })}>刷新新版MP列表</Button>}&nbsp;
            {isShowMarketingButton && this.state.providerType === 'oe' && <Button icon={<ReloadOutlined />} onClick={() => this.setState({ fetchModalVisible: true, isOeMarketingFetch: true })}>刷新OE MARKETING列表</Button>}&nbsp;
            <Button icon={<PlusOutlined />} onClick={() => this.openUploadModal()}>批量添加投放计划</Button>
            <Search
                placeholder="请输入广告主名称/账号Id"
                onSearch={value => this.handleSearch(value)}
                style={{ width: 200, marginLeft: '20px' }}
            />
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.data}
                pagination={this.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
            />
            <FetchAdvertiser
                visible={fetchModalVisible}
                providerId={providerId}
                providerType={providerType}
                userId={userId}
                onClose={this.onFetchModalClose}
                isShowMarketingButton={isShowMarketingButton}
                isNewMpFetch={this.state.isNewMpFetch}
                isOeMarketingFetch={this.state.isOeMarketingFetch}
            />
            <FetchStatistic visible={statisticModalVisible} providerId={providerId} advertiserId={advertiserId} onClose={this.onStatisticModalClose} />
            <Uploader visible={uploadModalVisible} onClose={this.onUploadModalClose} advertiserId={advertiserId} providerId={providerId} />
        </div>
    }
}
export default withRouter(Provider);
