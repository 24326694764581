const data = {};

// 推广目标 product_type
data.product_type = [
    { title: '推广品牌活动', value: 'PRODUCTTYPE_WECHAT_ARTICLE' },
    { title: '推广我的门店', value: 'PRODUCTTYPE_WECHAT_LBS' },
    { title: '收集销售线索', value: 'PRODUCTTYPE_LEAD_AD' },
    { title: '推广我的商品', value: 'PRODUCTTYPE_WECHAT_SHOP' },
    { title: '推广我的应用', value: 'PRODUCTTYPE_APPLE_APP_STORE' },
    { title: '派发优惠券', value: 'PRODUCTTYPE_WECHAT_CARD' },
    { title: '推广我的公众号', value: 'PRODUCTTYPE_WECHAT' },
    { title: '推广我的小游戏', value: 'PRODUCTTYPE_WECHAT_MINI_GAME' }
];

// 广告位 pos_type
data.pos_type = [
    { title: '朋友圈信息流', value: 999 },
    { title: '公众号文章底部', value: 0 },
    { title: '公众号文章中部', value: 5 },
    { title: '公众号互选广告', value: 4 },
    { title: '公众号文章视频贴片', value: 3 },
    { title: '小程序banner广告', value: 7 },
    { title: '激励式广告', value: 8 },
    { title: '沉浸式横版视频', value: 101 }
];

data.multi_slot_type = [
    { title: '', value: 0 },//MULTI_SLOT_TYPE_NONE
    { title: '展示Banner图片', value: 1 },
    { title: '优雅横版大图', value: 2 },
    { title: '沉浸式横版视频', value: 3 },
    { title: '模版聚合格子广告', value: 4 },
]

data.status = [
    { title: '审核中', value: 'ADSTATUS_PENDING' },
    { title: '未通过', value: 'ADSTATUS_DENIED' },
    { title: '待投放', value: 'ADSTATUS_READY' },
    { title: '投放中', value: 'ADSTATUS_NORMAL' },
    { title: '暂停投放', value: 'ADSTATUS_SUSPEND' },
    { title: '已结束', value: 'ADSTATUS_FINISH' },
]

data.contract_flag = [
    { title: '竞价购买广告', value: 2 },
]

data.dayHeader = [
    { title: '投放时间', value: 'begin_time' },
    { title: '广告预算（元）', value: 'budget' },
    { title: '点击次数', value: 'clk_pv' },
    { title: '', value: 'clk_target_f_pv' },
    { title: '', value: 'clk_target_t_pv' },
    { title: '转化指标', value: 'comindex' },
    { title: '购买类型', value: 'contract_flag' },
    { title: '转化目标量', value: 'conv_index' },
    { title: '转化目标成本（元）', value: 'conv_index_cpa' },
    { title: '', value: 'conv_index_cpa_target_f' },
    { title: '', value: 'conv_index_cpa_target_t' },
    { title: '目标转化率', value: 'conv_index_cvr' },
    { title: '', value: 'conv_index_cvr_target_f' },
    { title: '', value: 'conv_index_cvr_target_t' },
    { title: '', value: 'conv_index_target_f' },
    { title: '', value: 'conv_index_target_t' },
    { title: '点击均价（元）', value: 'cpc' },
    { title: '', value: 'cpc_target_f' },
    { title: '', value: 'cpc_target_t' },
    { title: '推广页按钮点击人数', value: 'cpnclk_button_dedup_pv' },
    { title: '点击率', value: 'ctr' },
    { title: '', value: 'ctr_target_f' },
    { title: '', value: 'ctr_target_t' },
    { title: '推广页人均曝光时长（秒）', value: 'cvs_viewtime_avg' },
    { title: '', value: 'date' },
    { title: '千次曝光成本', value: 'ecpm' },
    { title: '广告投放结束时间', value: 'end_time' },
    { title: '曝光次数', value: 'exp_pv' },
    { title: '', value: 'exp_target_f_pv' },
    { title: '', value: 'exp_target_t_pv' },
    { title: '公众号关注成本（次数）', value: 'follow_cost' },
    { title: '公众号关注次数', value: 'follow_pv' },
    { title: 'ID', value: 'id' },
    { title: '按形态投放', value: 'multi_slot_type' },
    { title: '投放计划名称', value: 'name' },
    { title: '下单金额', value: 'order_amount' },
    { title: '', value: 'order_amount_target_f' },
    { title: '', value: 'order_amount_target_t' },
    { title: '下单ROI', value: 'order_roi' },
    { title: '花费（元）', value: 'paid' },
    { title: '', value: 'paid_target_f' },
    { title: '', value: 'paid_target_t' },
    { title: '广告位', value: 'pos_type' },
    { title: '推广目标', value: 'product_type' },
    { title: '状态', value: 'status' },
    { title: '小游戏付费金额（元）', value: 'weapp_purchase_amount' },
    { title: '小游戏首日新增收入ROI', value: 'weapp_purchase_amount_roi' },
    { title: '小游戏注册成本（人数）', value: 'weapp_reg_cost' },
    { title: '小游戏注册人数', value: 'weapp_reg_pv' },
    { title: '小游戏注册率', value: 'weapp_reg_rate' },
]

data.hourHeader = [
    { title: '广告投放开始时间', value: 'begin_time' },
    { title: '广告预算（元）', value: 'budget' },
    { title: '点击次数', value: 'clk_pv' },
    { title: '', value: 'clk_target_f_pv' },
    { title: '', value: 'clk_target_t_pv' },
    { title: '购买类型', value: 'contract_flag' },
    { title: '转化目标量', value: 'conv_index' },
    { title: '转化目标成本(元)', value: 'conv_index_cpa' },
    { title: '', value: 'conv_index_cpa_target_f' },
    { title: '', value: 'conv_index_cpa_target_t' },
    { title: '', value: 'conv_index_target_f' },
    { title: '', value: 'conv_index_target_t' },
    { title: '推广页按钮点击人数', value: 'cpnclk_button_dedup_pv' },
    { title: '', value: 'date' },
    { title: '广告投放结合时间', value: 'end_time' },
    { title: '曝光次数', value: 'exp_pv' },
    { title: '', value: 'exp_target_f_pv' },
    { title: '', value: 'exp_target_t_pv' },
    { title: '公众号关注成本（次数）', value: 'follow_cost' },
    { title: '公众号关注次数', value: 'follow_pv' },
    { title: 'ID', value: 'id' },
    { title: '按形态投放', value: 'multi_slot_type' },
    { title: '投放计划名称', value: 'name' },
    { title: '下单金额', value: 'order_amount' },
    { title: '', value: 'order_amount_target_f' },
    { title: '', value: 'order_amount_target_t' },
    { title: '花费（元）', value: 'paid' },
    { title: '', value: 'paid_target_f' },
    { title: '', value: 'paid_target_t' },
    { title: '广告位', value: 'pos_type' },
    { title: '推广目标', value: 'product_type' },
    { title: '状态', value: 'status' }
]

module.exports = data;