import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';
import TagGroup from './tag-group';
import { $put } from '../../helpers/remote';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class UploadForm extends Component {
    render() {
        const title = this.props.value.title;
        const tags = this.props.value.tags.split(',');
        return (
            <Form
                onSubmit={this.handleSubmit}
                className="page-material-add"
                {...formItemLayout}
                ref={form => this.form = form}>
                <Form.Item
                    label="标题" name="title" initialValue={title}>
                    <Input placeholder="标题"/>
                </Form.Item>
                <Form.Item label="标签" name="tage" initialValue={tags}>
                    <TagGroup />
                </Form.Item>
            </Form>
        )
    }
}

export default class UpdateMaterial extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    }
    state = {
        loading: false,
    }
    onClose = (result) => {
        this.form.resetFields();
        if (result) {
            this.props.onClose(result);
        } else {
            this.props.onClose();
        }
    }
    onOk = () => {
        this.form.validateFields()
        .then(async (values) => {
            const { title, tags } = values;
            const results = [];
            const data = {
                title: title || '',
                tags: tags ? tags.join(',') : ''
            };
            this.setState({ loading: true });
            const result = await $put(
                `/materials/${this.props.value.id}`,
                data,
                { hideLoading: true, throwException: true }
            );
            results.push(result);
            this.setState({ loading: false });
            this.onClose(results);
        });
    }
    render() {
        const data = this.props.value;
        const { visible } = this.props;
        const { loading } = this.state;
        return (
            <Modal
                title="修改属性"
                visible={visible}
                cancelText="取消"
                onCancel={this.onClose}
                onOk={this.onOk}
                loading={loading}
                okButtonProps={{ loading }}
            >
                <UploadForm ref={form => this.form = form && form.form} value={data} />
            </Modal>
        )
    }
}
