import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Result, Spin, Button } from 'antd';
import { $post } from '../../helpers/remote';

const defaultProps = {
    count: 0,
    needLogin: false,
    loading: false,
    success: false,
    error: null,
};

export default class FetchAdvertiser extends Component {
    static propTypes = {
        advertiserId: PropTypes.number.isRequired,
        providerId: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };
    state = defaultProps;
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState(defaultProps);
        }
    }
    fetch = async () => {
        try {
            this.setState({ loading: true });
            const count = await $post(
                `/providers/${this.props.providerId}/advertisers/${this.props.advertiserId}/fetchReport`,
                {},
                { hideLoading: true, throwException: true }
            );
            this.setState({
                count, success: true, loading: false,
            });
        } catch (e) {
            this.setState({ loading: false });
            if (e.response && /登录状态过期/.test(e.response.data.message)) {
                this.setState({ needLogin: true });
            }
            this.setState({ error: e.response ? (e.response.data.message || e.response.data.error) : e.message });
        }
    }
    onClose = () => {
        this.props.onClose();
    }
    render() {
        const { visible } = this.props;
        const { needLogin, error, loading, success, count } = this.state;
        return (
            <Modal
                title="更新微信统计数据"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <Spin spinning={loading}>
                    <div className="page-statistic-fetch" style={{ textAlign: 'center' }}>
                        { needLogin && <Result
                            status="error"
                            title="服务商登陆状态过期"
                            subTitle="请返回扫码更新状态"
                        /> }
                        { error && !needLogin && <Result
                            status="error"
                            title="更新失败"
                            subTitle={error}
                        /> }
                        { success && <Result
                            status="success"
                            title="更新成功"
                            subTitle={`共更新成功${count}条数据`}
                        /> }
                        { !error && !success && <div>
                            <div>操作将会从微信公众平台数据报告页面获取数据，如果相应的投放计划是通过易起量投放的，将会更新易起量任务的统计数据，同时执行数据接口推送以及任务自动暂停（如果开启）</div>
                            &nbsp;<Button onClick={this.fetch}>同步</Button>
                        </div>}
                    </div>
                </Spin>
            </Modal>
        )
    }
}
