import React, { Component } from 'react';
import moment from 'moment';
import { Table, Space } from 'antd';

const toNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);

export default class MonitorNovelTable extends Component {

    calcAverage = (arr) => {
        this.props.calcAverage(arr);
    }

    get columns() {
        const { dataSource, statusOptions, showUpdatedAt } = this.props;
        const items = [
            {
                title: '条数/总条数',
                dataIndex: 'index',
                render: (index, row) => `${index}/${dataSource.length}`,
            },
            {
                title: '计划名称',
                dataIndex: 'campaignName',
            },
            {
                title: '出价',
                dataIndex: 'bid',
            },
            {
                title: '消耗',
                dataIndex: 'paid',
                sorter: (a, b) => toNumber(a.paid) - toNumber(b.paid),
            },
            {
                title: '粉数',
                dataIndex: 'followPv',
                sorter: (a, b) => a.followPv - b.followPv,
            },
            {
                title: '粉本',
                dataIndex: 'followCost',
                sorter: (a, b) => toNumber(a.followCost) - toNumber(b.followCost),
            },
            {
                title: '点击率',
                dataIndex: 'ctr',
                render: (value) => value && value !== '--' ? (Number(value) * 100).toFixed(2) + '%' : '--',
                sorter: (a, b) => toNumber(a.ctr) - toNumber(b.ctr),
            },
            {
                title: '千次曝光成本',
                dataIndex: 'ecpm',
                sorter: (a, b) => toNumber(a.ecpm) - toNumber(b.ecpm),
            },
            {
                title: 'ROI',
                dataIndex: 'orderRoi',
                sorter: (a, b) => toNumber(a.orderRoi) - toNumber(b.orderRoi),
            },
            {
                title: '均衡/优先',
                dataIndex: 'strategy2',
            },
            {
                title: '男/女',
                dataIndex: 'gender',
            },
            {
                title: '落地页停留时长',
                dataIndex: 'cvsViewtimeAvg',
                sorter: (a, b) => toNumber(a.cvsViewtimeAvg) - toNumber(b.cvsViewtimeAvg),
            },
            {
                title: '下单金额',
                dataIndex: 'orderAmount',
                sorter: (a, b) => toNumber(a.orderAmount) - toNumber(b.orderAmount),
            },
            {
                title: '下单ARPU',
                dataIndex: 'arpu',
                sorter: (a, b) => toNumber(a.arpu) - toNumber(b.arpu),
            },
            {
                title: '公众号',
                dataIndex: 'advertiserName',
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (status) => {
                    const item = statusOptions.find(o => o.value === status);
                    return item ? item.label : status;
                }
            },
        ];
        if (showUpdatedAt) {
            items.push({
                title: '更新时间',
                dataIndex: 'updatedAt',
                render: (updatedAt) => updatedAt ? moment(updatedAt).format('MM-DD HH:mm') : '-',
            });
        }
        return items;
    }

    get todayUnefficentCost() {
        const { rows } = this.props;
        return rows.filter(row => toNumber(row.orderRoi) < 0.12).reduce((p, row) => p + toNumber(row.paid) * 100, 0) / 100;
    }

    get todayEfficientCost() {
        const { rows } = this.props;
        return rows.filter(row => toNumber(row.orderRoi) >= 0.12).reduce((p, row) => p + toNumber(row.paid * 100), 0) / 100;
    }

    renderFooter = () => {
        const { dataSource: rows, targetLabel, statusLabel, advertiserId, status, calcAverage } = this.props;

        const summary = {
            bid: '--',
            paid: 0,
            followPv: 0,
            followCost: [],
            ctr: [],
            ecpm: [],
            orderRoi: [],
            strategy2: '--',
            gender: '--',
            cvsViewtimeAvg: [],
            orderAmount: 0,
            arpu: [],
            advertiserName: '--',
            status: '--'
        };
        const calcAverageArr = ['followCost', 'ctr', 'ecpm', 'orderRoi', 'cvsViewtimeAvg', 'arpu'];
        rows.forEach(item => {
            ['paid', 'followPv', 'orderAmount'].forEach(key => {
                summary[key] += toNumber(item[key]);
            });
            calcAverageArr.forEach(key => {
                if (key === 'ctr') {
                    summary[key].push(toNumber(item[key]) * 100);
                }
                else {
                    summary[key].push(toNumber(item[key]));
                }
            });
        });

        calcAverageArr.forEach(item => {
            summary[item] = calcAverage(summary[item]);
        });

        summary.orderRoi = toNumber(Number(summary.orderAmount / summary.paid).toFixed(2));

        let summaryCellArr = [
            <Table.Summary.Cell key="0">总数: {rows.length}</Table.Summary.Cell>,
            <Table.Summary.Cell key="1">--</Table.Summary.Cell>
        ];
        let index = 2;
        for (let key in summary) {
            let value = summary[key];
            if (typeof value === 'number') {
                value = value.toFixed(2);
            }
            if (key === 'ctr') {
                value = value + '%';
            }
            if (key === 'advertiserName' && advertiserId !== 0) {
                value = targetLabel;
            }
            if (key === 'status' && status !== 'all') {
                value = statusLabel;
            }
            summaryCellArr.push(<Table.Summary.Cell index={index++} key={index}>{value}</Table.Summary.Cell>);
        }
        return (
            <Table.Summary.Row>
                {summaryCellArr}
            </Table.Summary.Row>
        )
    }

    render() {
        const { dataSource } = this.props;

        return <div className="page-monitor">
            <Table
                rowKey={record => record.cid}
                dataSource={dataSource}
                columns={this.columns}
                summary={() => this.renderFooter()}
                size="small"
            />
            <Space className="today-cost" size="middle">
                <span>今日有效消耗: {this.todayEfficientCost}</span>
                <span>今日损耗: {this.todayUnefficentCost}</span>
                <span>损耗比: {toNumber(Math.floor(this.todayUnefficentCost * 100 / (this.todayEfficientCost + this.todayUnefficentCost)))} %</span>
            </Space>
        </div>
    }
}