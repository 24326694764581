import React, { Component } from 'react';
import { Table, Button, Progress } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import { $get } from '../../helpers/remote';
import moment from 'moment';
const enums = require('./enums');
const CVS_PATH = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/sync-report';
const getTitle = (key, value) => {
    const data = enums[key];
    if (!data) {
        return null;
    }
    const item = _.find(data, o => o.value === value);
    if (!item) {
        return null;
    }
    if (!item.title && (typeof(value) === 'string') && (value.indexOf('_target_') !== -1)) {
        let orginValue = '';
        let tail = '';
        if (value.indexOf('_target_f') !== -1) {
            orginValue = item.value.replace('_target_f', '');
            tail = '扩量';
        }
        if (value.indexOf('_target_t') !== -1) {
            orginValue = item.value.replace('_target_t', '');
            tail = '非扩量';
        }
        const orginTitle = getTitle(key, orginValue);
        return `${orginTitle}_${tail}`;
    }
    return item.title || item.value;
}

class ReportSyncJob extends Component {
    state = {
        loading: true,
        //round基础信息
        infos: [],
        //所有的jobs
        jobs: [],
        total: 0,
        roundId: this.props.match.params.roundId,
        //cvs导出的日数据
        dayDatas: [],
        dayHeader: [],
        dayPercent: 0,
        isDayDownloading: false,
        dayCount: 0,
        //cvs导出的小时数据
        hourDatas: [],
        hourHeader: [],
        hourPercent: 0,
        isHourDownloading: false,
        hourCount: 0,
    }
    componentDidMount() {
        this.fetch();
    }
    fetch = () => {
        this.getReports();
    }
    getReports = async() => {
        const { roundId } = this.state;
        const [jobs, total] = await $get(`/reports/${roundId}`, {}, { hideLoading: true, throwException: true });
        if (!jobs.length) {
            this.setState({ loading: false });
            return;
        }
        const info = {
            id: roundId,
            roundId,
            start_time: moment(jobs[jobs.length - 1].syncEndAt).format('YYYY-MM-DD HH:mm:ss'),
            end_time: moment(jobs[0].syncEndAt).format('YYYY-MM-DD HH:mm:ss'),
        }

        this.setState({
            infos: [info],
            jobs,
            total,
            loading: false,
        });
    }
    getCvs = async (id, type) => {
        const { roundId } = this.state;
        const response = await axios.get(`${CVS_PATH}/${roundId}/${id}-${type}.csv`);
        return response.data;
    }
    download = async (type) => {
        const { jobs, total, isDayDownloading, isHourDownloading } = this.state;
        if ((type === 'day' && isDayDownloading) ||
            (type === 'hour' && isHourDownloading)
        ) return;
        if (type === 'day') {
            this.setState({ isDayDownloading: true });
        } else {
            this.setState({ isHourDownloading: true });
        }
        let count = 0;
        let datas = [];
        let header = [];
        let originHeader = [];
        const convFun = (arr) => {
            return arr.map((val, key) => {
                const row = val.split(',');
                if (key === 0) {
                    if (!originHeader.length) {
                        originHeader = row;
                        header = originHeader.map(v => { return getTitle(`${type}Header`, v) });
                    }
                    return row;
                } else {
                    return row.map((v, k) => {
                        const value = isNaN(Number(v)) ? v : Number(v);
                        return getTitle(originHeader[k], value) || v;
                    });
                }
            });
        }
        for (const val of jobs) {
            count++;
            let data = '';
            try {
                data = await this.getCvs(val.id, type);
            } catch (err) {
                continue;
            }
            if (data) {
                const arr = data.split('\n');
                const rows = convFun(arr);
                const content = rows.slice(1, rows.length);
                datas = [...datas, ...content];
            }
            const rate = Math.floor(count * 100 / total);
            const { dayPercent, hourPercent } = this.state;
            if ((type === 'day') && (rate !== dayPercent)) {
                this.setState({ dayPercent: rate });
            }
            if ((type === 'hour') && (rate !== hourPercent)) {
                this.setState({ hourPercent: rate });
            }
        }
        if (type === 'day') {
            this.setState({ dayDatas: datas, dayHeader: header, isDayDownloading: false });
        } else {
            this.setState({ hourDatas: datas, hourHeader: header, isHourDownloading: false });
        }
    }
    get columns() {
        const { isDayDownloading, dayPercent, dayDatas, dayHeader,
                isHourDownloading, hourPercent, hourDatas, hourHeader,
                roundId,
        } = this.state;
        const columns = [
            {
                title: '轮次ID',
                dataIndex: 'roundId',
            },
            {
                title: '同步开始时间',
                dataIndex: 'start_time',
            },
            {
                title: '同步结束时间',
                dataIndex: 'end_time',
            },
            {
                title: '日数据',
                align: 'center',
                render: (_, record) => {
                    return <div>
                        { !dayDatas.length && <Button disabled={isDayDownloading} onClick={() => this.download('day')}>下载</Button> }
                        { dayDatas.length > 0 && <Button><CSVLink filename={`${roundId}_day.csv`} data={dayDatas} headers={dayHeader} >导出本表</CSVLink></Button> }
                        { isDayDownloading && <Progress  percent={dayPercent} size='small' /> }
                    </div>;
                }
            },
            {
                title: '小时数据',
                align: 'center',
                render: (_, record) => {
                    return <div>
                        { !hourDatas.length && <Button disabled={isHourDownloading} onClick={() => this.download('hour')}>下载</Button> }
                        { hourDatas.length > 0 && <Button><CSVLink filename={`${roundId}_hour.csv`} data={hourDatas} headers={hourHeader} >导出本表</CSVLink></Button> }
                        { isHourDownloading && <Progress percent={hourPercent} size='small' /> }
                    </div>;
                }
            },
        ];
        return columns;
    }
    render() {
        const { infos, loading } = this.state;
        return <div className='page-sync-report'>
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={infos}
                loading={loading}
                pagination={false}
            />
        </div>
    }
}
export default withRouter(ReportSyncJob);
