import React, { Component } from 'react';
import { Row, Col, Table, Modal } from 'antd';
import { $get } from '../../helpers/remote';

export default class ChooseAdvertiser extends Component {
    state = {
        visible: this.props.visible,
        providerId: 0,
        advertiserId: 0,
        providers: [],
        providerPagination: {
            pageSize: 5,
            page: 1,
            simple: true
        },
        advertisers: [],
        advertiserPagination: {
            pageSize: 5,
            page: 1,
            simple: true
        },
        loading: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetch();
        }
    }
    fetch = async () => {
        this.getProviders();
    }
    onSubmitAdvertiserId = () => {
        const { advertiserId } = this.state;
        this.props.handleAdvertiserId(advertiserId);
    }
    handleProviderChange = (pagination) => {
        const pager = { ...this.state.providerPagination };
        pager.current = pagination.current;
        this.setState({
            providerPagination: pager,
        });
        this.getProviders({
            pageSize: pagination.pageSize,
            page: pagination.current,
        });
    }
    handleAdvertiserChange = (pagination) => {
        const pager = { ...this.state.advertiserPagination };
        const { providerId } = this.state;
        pager.current = pagination.current;
        this.setState({
            advertiserPagination: pager,
        });
        this.getAdvertisers(providerId, {
            pageSize: pagination.pageSize,
            page: pagination.current,
        });
    }
    getProviders = async (pager) => {
        const pagination = pager || this.state.providerPagination;
        const { page, pageSize } =  pagination;
        const [providers, count] = await $get(`/providers?page=${page}&pageSize=${pageSize}`);
        pagination.total = count;
        this.setState({ providers, providerPagination: pagination });

        const { providerId } = this.state;
        if (providers.length && !providerId) {
            this.setState({ providerId: providers[0].id });
            this.getAdvertisers(providers[0].id);
        }
    }
    getAdvertisers = async (providerId, pager) => {
        const pagination = pager || this.state.advertiserPagination;
        const { page, pageSize } =  pagination;
        const [advertisers, count] = await $get(`/providers/${providerId}/advertisers?page=${page}&pageSize=${pageSize}`);
        pagination.total = count;
        this.setState({ advertisers, advertiserPagination: pagination });
    }
    handleProviderClick = (providerId) => {
        this.setState({ providerId });
        this.getAdvertisers(providerId);
    }
    handleAdvertiserClick = (advertiserId) => {
        this.setState({ advertiserId });
    }
    get providerColumns() {
        return [{
            title: '服务商名称',
            render: (_, record) => {
                const { providerId } = this.state;
                return <div className={ record.id === providerId ? 'selected' : '' } onClick={() => this.handleProviderClick(record.id)}>{ record.agencyName }</div>;
            }
        }];
    }
    get advertiserColumns() {
        return [{
            title: '广告主名称',
            render: (_, record) => {
                const { advertiserId } = this.state;
                return <div className={ record.id === advertiserId ? 'selected' : '' } onClick={() => this.handleAdvertiserClick(record.id)}>{ record.name }</div>;
            }
        }];
    }
    render() {
        const { visible } = this.props;
        const { advertiserId,
                providers, providerPagination,
                advertisers, advertiserPagination,
              } = this.state;
        return (
            <Modal
                className="chooseAdvertiserModal"
                title="选择广告主"
                okText="确定"
                visible={visible}
                cancelText="取消"
                onCancel={this.onSubmitAdvertiserId}
                onOk={this.onSubmitAdvertiserId}
                width={600}
                okButtonProps={{ disabled: advertiserId ? false : true }}
            >
                <p>请选择任意一个在线的广告主上传图片到微信服务器，上传后可以给任意广告主使用</p>
                <Row gutter={8}>
                    <Col span={12}>
                        <Table
                            columns={this.providerColumns}
                            rowKey={record => record.id}
                            dataSource={providers}
                            pagination={providerPagination}
                            onChange={this.handleProviderChange}
                        />
                    </Col>
                    <Col span={12}>
                        <Table
                            columns={this.advertiserColumns}
                            rowKey={record => record.id}
                            dataSource={advertisers}
                            pagination={advertiserPagination}
                            onChange={this.handleAdvertiserChange}
                        />
                    </Col>
                </Row>
            </Modal>
        )
    }
}
