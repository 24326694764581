import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './components/header';
import { isLogin } from './helpers/remote';
import Home from './pages/home/home';
import Job from './pages/job';
import Material from './pages/material';
import User from './pages/user';
import Login from './pages/login';
import Admin from './pages/admin';
import Monitor from './pages/monitor';
import Detail from './pages/job/detail';
import AutoUpload from './pages/auto-upload';
import ReportSyncJob from './pages/report';
import Strategy from './pages/strategy';
import StrategyStats from './pages/strategy/strategy-stats';
import Status from './pages/status';
import './App.less';

const { Content, Footer } = Layout;

function App() {
    if (!isLogin() && window.location.pathname !== '/status') {
        return <Login />
    }
    return (
        <Layout className="app" style={{
            '--color-warning': '#faad14',
            '--color-success': '#52c41a',
            '--color-success-bg': '#f6ffed',
            '--color-error': '#f5222d',
            '--color-error-bg': '#fff1f0',
            '--color-info': '#1890ff',
            }}>
            <Router>
                <Switch>
                    <Route path="/status" exact />
                    <Route component={Header} />
                </Switch>
                <Content>
                    <div className="layout-content">
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/providers" component={Home} />
                            <Route path="/jobs" component={Job} exact />
                            <Route path="/jobs/:id" component={Detail} />
                            <Route path="/materials" component={Material} />
                            <Route path="/user" component={User} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/monitor" exact component={Monitor} />
                            <Route path="/admin" component={Admin} />
                            <Route path="/auto-uploads" component={AutoUpload} />
                            <Route path="/reports/rounds/:roundId" component={ReportSyncJob} />
                            <Route path="/strategy" component={Strategy} />
                            <Route path="/strategy-stats" component={StrategyStats} />
                            <Route path="/status" component={Status} />
                        </Switch>
                    </div>
                </Content>
                <Footer>© 2019 - 2020</Footer>
            </Router>
        </Layout>

    );
}

export default App;
