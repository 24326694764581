import React, { Component } from 'react';
import { Input, Button, message } from 'antd';
import * as remote from '../helpers/remote';
import featureImage from '../assets/feature.png';
import logoImage from '../assets/logo.png';
import step1Image from '../assets/step1.png';
import step2Image from '../assets/step2.png';
import step3Image from '../assets/step3.png';
import step4Image from '../assets/step4.png';
import qrcodeImage from '../assets/qrcode.jpg';
import './login.less';

export default class Login extends Component {
    state = {
        phone: '',
        password: '',
    }
    onPhoneChange = e => this.setState({ phone: e.target.value });
    onPasswordChange = e => this.setState({ password: e.target.value });
    login = async () => {
        try {
            const { phone, password } = this.state;
            const result = await remote.$post('/users/login', { phone, password }, { throwException: true });
            localStorage.setItem('ad-platform-token', result.token);
            window.location.href = '/';
        } catch (e) {
            if (e.response && e.response.status === 403) {
                message.error('手机号或密码错误');
            } else {
                throw e;
            }
        }
    }
    render() {
        const { phone, password } = this.state;
        return <div className="page-login">
            <div className="main">
                <div className="background"><img src={featureImage} alt="background" /></div>
                <header>
                    <div className="header-main">
                        <div className="logo">
                            <img src={logoImage} alt="logo" />
                            <span>投放系统</span>
                        </div>
                        <div className="slogan">
                            <h2>花更少的精力，投放更好的效果</h2>
                            <h3>支持批量创建计划、数据统计分析、AI智能投放</h3>
                        </div>
                        <div className="login-card">
                            <h3 className="subtitle">LOGIN</h3>
                            <h3>登陆</h3>
                            <div className="form">
                                <div className="form-row">
                                    <Input onChange={this.onPhoneChange} value={phone} placeholder="输入手机号" />
                                </div>
                                <div className="form-row">
                                    <Input type="password" onChange={this.onPasswordChange} value={password} placeholder="输入密码" />
                                </div>
                                <div className="form-row">
                                    <Button disabled={!phone || !password} onClick={this.login} type="primary">登陆</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="features">
                    <div className="content">
                        <div className="feature-box">
                            <div className="title">批量创建计划</div>
                            <div className="content">跨广告主批量创建投放计划，高效快速投放广告</div>
                        </div>
                        <div className="feature-box">
                            <div className="title">数据统计分析</div>
                            <div className="content">根据投放平台数据和转化数据，提供转化数据看板，系统化分析投放人群/时段/素材</div>
                        </div>
                        <div className="feature-box">
                            <div className="title">AI智能投放</div>
                            <div className="content">基于转化数据和用户画像，利用人工智能动态创建/关闭计划，根据转化调整流量，有效提高投产比</div>
                        </div>
                    </div>
                </div>
                <div className="guide">
                    <div className="title">1分钟了解易起量</div>
                    <div className="intro-video">
                        <video controls>
                            <source src="https://static.yiqiliang.cn/ad-platform/intro.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="guide">
                    <div className="title">如何开通易起量账号</div>
                    <div className="steps">
                        <div className="step">
                            <div className="image"><img src={step1Image} alt="申请试用" /></div>
                            <div className="title">申请试用</div>
                            <div className="content">右下角扫码联系商务，登记相应资料获取试用账号</div>
                        </div>
                        <div className="step">
                            <div className="image"><img src={step2Image} alt="操作体验" /></div>
                            <div className="title">操作体验</div>
                            <div className="content">登陆试用账号，根据操作手册，体验易起量系统</div>
                        </div>
                        <div className="step">
                            <div className="image"><img src={step3Image} alt="签署合同" /></div>
                            <div className="title">签署合同</div>
                            <div className="content">签署相关协议，获取正式账号权限</div>
                        </div>
                        <div className="step">
                            <div className="image"><img src={step4Image} alt="使用系统" /></div>
                            <div className="title">使用系统</div>
                            <div className="content">使用易起量投放系统 让你的投放更高效</div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div>
                        <div className="card">
                            <h3>常见问题</h3>
                            <p><a href="#1">易起量投放系统有什么功能？</a></p>
                            <p><a href="#1">如何申请试用账号？</a></p>
                        </div>
                        <div className="card">
                            <h3><a href="https://shimo.im/docs/tWvhCKDHYD66pjGx">操作手册</a></h3>
                            <p><a href="#1">操作手册|批量创建计划</a></p>
                            <p><a href="#1">操作手册|数据统计分析</a></p>
                            <p><a href="#1">操作手册|AI智能投放</a></p>
                        </div>
                        <div className="card add-link">
                            <h3>备案</h3>
                            <p><a href="#1">https://www.yiqiliang.cn</a></p>
                            <p><a href="http://www.beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank" rel="noopener noreferrer">沪ICP备14048637号-6</a></p>
                            <p><a href="#1">上海盛也网络技术有限公司</a></p>
                            <h3>友情链接</h3>
                            <p><a className="link-color" href="https://www.jzl.com/">专业的微信公众号数据分析、公众号监控、假号监测与服务定制平台</a></p>
                        </div>
                        <div className="card qrcode">
                            <img src={qrcodeImage} alt="二维码" />
                            联系商务，获取试用账号，获取更多支持
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    }
}
