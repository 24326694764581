/**
 * 生成平铺水印函数
 * @param {*} text 生成水印的内容
 */
export const setWatermark = (text) => {
    const watermarkDiv = document.getElementById('watermark');
    let canvas = document.createElement('canvas');
    const height = text.length * 16 / 2;
    // 单个水印的长宽
    canvas.width = height > 200 ? height * 2 : 320;
    canvas.height = height > 200 ? height : 200;
    let ctx = canvas.getContext('2d');
    // 翻转30°
    ctx.rotate(-30 * Math.PI / 180);
    // 内容文字大小与字体
    ctx.font = '16px Vedana';
    // 文字颜色
    ctx.fillStyle = '#444';
    // 填充文字的起点
    ctx.fillText(text, 10, height + 30);
    // 水印已存在则替换背景
    if (watermarkDiv) {
        watermarkDiv.style.background = 'url(' + canvas.toDataURL('image/png') + ') left top repeat';
    } else {
        // 将画出的水印平铺到一个盒子中覆盖在页面上，并且点击穿透
        let div = document.createElement('div');
        div.id = 'watermark';
        div.style.pointerEvents = 'none';
        div.style.top = '0px';
        div.style.left = '0px';
        div.style.opacity = '0.1';
        div.style.position = 'fixed';
        div.style.zIndex = 99999999;
        div.style.width = document.documentElement.clientWidth + 'px';
        div.style.height = document.documentElement.clientHeight + 'px';
        div.style.background = 'url(' + canvas.toDataURL('image/png') + ') left top repeat';
        window.onresize = () => {
            div.style.width = document.documentElement.clientWidth + 'px';
            div.style.height = document.documentElement.clientHeight + 'px';
        }
        document.getElementById('root').appendChild(div);
    }
}