import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Modal, Button, Descriptions, Popconfirm, message, DatePicker } from 'antd';
import moment from 'moment';
import { $get, $post, $patch } from '../../helpers/remote';
import './index.less';
import MaterialPreview from '../material-preview';
import { withRouter, Link } from 'react-router-dom';
import STEPS from '../../pages/enums/steps';
import { InfoCircleOutlined } from '@ant-design/icons';

class Jobs extends Component {
    static propTypes = {
        fetchUrl: PropTypes.string,
        showRun: PropTypes.bool,
        showStatistic: PropTypes.bool,
        customData: PropTypes.bool,
        users: PropTypes.array,
        userProfile: PropTypes.any
    }
    static defaultProps = {
        fetchUrl: '/jobs',
        showRun: false,
        showStatistic: false,
        customData: false,
    }
    state = {
        data: [],
        pagination: {
            pageSize: 20,
            page: 1,
        },
        loading: false,
        selectedJobs: [],
        visible: false,
        whiteListDate: '',
        users: []
    }
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.fetch();
        }
    }
    fetch = async (params) => {
        const { fetchUrl, filter, users } = this.props;
        const { page, pageSize, sort, direction } = params || this.state.pagination;
        this.setState({ loading: true });
        const [jobs, count] = await $get(fetchUrl, { page, pageSize, ...filter, sort, direction }, { hideLoading: true });
        const pagination = { ...this.state.pagination };
        pagination.total = count;
        // 若没有传递users则主动获取
        if (!users) {
            await this.getAllUsers();
        }
        this.setState({
            loading: false,
            data: jobs,
            pagination,
        });
    };
    handleTableChange = (pagination, _, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current,
            sort: sorter.field,
            direction: sorter.order,
        });
    };
    get columns() {
        const { showStatistic, customData, userProfile } = this.props;
        const users = this.props.users || this.state.users;
        const infoColumns = [
            {
                title: '广告主',
                dataIndex: ['advertiser', 'name']
            },
            {
                title: '素材预览',
                dataIndex: ['campain', 'material', 'url'],
                render: (url, record) => record.campain.material && <MaterialPreview url={record.campain.material.url} type={record.campain.material.type} urls={record.campain.material.urls} width={100} />
            },
            {
                title: '状态',
                dataIndex: 'step',
                render: (step, record) => <div>
                    {record.succeeded && <span className="step-success">成功</span>}
                    {record.failed && <span className="step-error">
                        失败 <InfoCircleOutlined onClick={() => Modal.error({
                            title: '失败原因',
                            content: record.message,
                        })} />
                    </span>}
                    {!record.succeeded && !record.failed && <span className="step-warning">
                        {
                            step === STEPS[0]
                            ? '队列中'
                            : `${Math.floor(STEPS.indexOf(step) / (STEPS.length) * 100)}%`
                        }
                        {this.props.showRun && step === STEPS[0] && <Button className="run-job" onClick={() => this.runJob(record.id)}>手动执行</Button>}
                    </span>}
                </div>
            },
            {
                title: '审核状态',
                dataIndex: 'reviewStatus',
                render: status => {
                    if (status === 2) {
                        return <span className="step-error">未通过</span>;
                    } else if (status === 0) {
                        return <span className="step-warning">审核中</span>;
                    } else if (status === 1) {
                        return <span className="step-success">已通过</span>;
                    } else if (status === -1) {
                        return '无效';
                    }
                    return '未知';
                },
            },
            {
                title: '重新提交ID',
                dataIndex: 'resubmitId',
            },
            {
                title: '创建时间',
                dataIndex: 'createdAt',
                render: text => moment(text).format('MM-DD HH:mm'),
                sorter: true,
            },
        ];
        const basicColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: (_, record) => {
                    return <div>
                        {
                            record.campain.userId === (userProfile && userProfile.id)
                                ? <Link to={{pathname: `/jobs/${record.id}`, state: { data: record }}}>{ record.id }</Link>
                                : record.id 
                        }
                    </div>
                }
            },
            {
                title: '计划名称',
                dataIndex: ['campain', 'campainName']
            },
            {
                title: '自定义ID',
                dataIndex: 'customId',
                sorter: true,
            },
            {
                title: '投手',
                dataIndex: ['campain', 'userId'],
                render: (_, record) => {
                    return <div>
                        { users.find(o => o.id === record.campain.userId)?.name || record.campain.userId }
                    </div>}
            }
        ];
        const statisticColumns = [
            {
                title: '同步时间',
                dataIndex: 'syncAt',
                render: text => moment(text).format('MM-DD HH:mm'),
            },
            {
                title: '消耗',
                dataIndex: 'cost',
                render: (text) => text / 100,
                sorter: true,
            },
            {
                title: '曝光次数',
                dataIndex: 'expose',
                sorter: true,
            },
            {
                title: '点击次数',
                dataIndex: 'click',
                sorter: true,
            },
            {
                title: '点击率',
                dataIndex: 'clickRate',
                render: (text) => text ? `${text}%` : '--',
                sorter: true,
            },
            {
                title: '点击单价',
                dataIndex: 'price',
                render: (text) => text ? text / 100 : '--',
                sorter: true,
            },
        ];
        const customStatisticColumns = [
            {
                title: '页面点击数',
                dataIndex: ['customStatistic', 'click'],
            },
            {
                title: '新增注册数',
                dataIndex: ['customStatistic', 'user'],
            },
            {
                title: '新增创角数',
                dataIndex: ['customStatistic', 'role'],
            },
            {
                title: '有效角色数',
                dataIndex: ['customStatistic', 'validUser'],
            },
            {
                title: '付费额',
                dataIndex: ['customStatistic', 'price'],
                render: (text) => text ? text / 100 : '--',
            },
            {
                title: '充值成功人',
                dataIndex: ['customStatistic', 'validCharge'],
            },
            {
                title: '发起充值人',
                dataIndex: ['customStatistic' ,'charge'],
            },
        ];
        let columns = [...basicColumns, ...(customData ? [] : infoColumns)];
        if (showStatistic) {
            columns = [...columns, ...statisticColumns];
        }
        if (customData) {
            columns = [...columns, ...customStatisticColumns];
        }
        return columns;
    }
    runJob = async (jobId) => {
        const jobs = [...this.state.data];
        await $post(`/jobs/${jobId}/run`);
        const job = jobs.find(o => o.id === jobId);
        if (job) {
            job.step = STEPS[1];
        }
        this.setState({
            data: jobs,
        });
    }
    updateAutoStop = async (jobId, value) => {
        const jobs = [...this.state.data];
        const job = jobs.find(o => o.id === jobId);
        if (job) {
            job.autoStop = value;
        }
        await $patch(`/jobs/${jobId}/autoStop`, { autoStop: value });
        this.setState({
            data: jobs,
        });
    }
    showCampaign = (campaign) => {
        Modal.info({
            title: '计划详情',
            width: '80%',
            content: <div>
                <Descriptions>
                    {
                        Object.keys(campaign).filter(key => key !== 'id' && key !== 'material').map(key => {
                            let content = campaign[key];
                            return <Descriptions.Item label={key} key={key}>
                                {typeof content === 'boolean' ? content.toString() : content}
                            </Descriptions.Item>
                        })
                    }
                </Descriptions>
            </div>
        });
    }
    getAllUsers = async () => {
        const res = await $get('/users');
        this.setState({
            users: res[0] || []
        })
    }
    onSelectChange = (record,selected ) => {
        const result = this.state.selectedJobs;
        if(selected === true && result.indexOf(record.id) <= -1){
            this.setState(({ selectedJobs }) => ({ selectedJobs: [...selectedJobs, record.id] }));
        } else if (result.indexOf(record.id) > -1){
            this.setState(({ selectedJobs }) => ({ selectedJobs: selectedJobs.filter(selectedJob => selectedJob !== record.id) }));
        }
    }
    onSelectAll = (selected, selectedRows) => {
        const selectedJobs = selectedRows.map(item => item.id)
        this.setState({ selectedJobs })
    }
    deleteJobs = async () => {
        const { data: jobs, selectedJobs } = this.state;
        const { userProfile } = this.props;
        if ( selectedJobs.length > 0 ) {
            const notPassJObs = jobs.filter(o => (selectedJobs.includes(o.id) && userProfile && userProfile.id !== o.campain.userId));
            if (notPassJObs && notPassJObs.length) {
                message.error(`不能删除不是本人的计划`);
                this.setState({ visible: false });
                return;
            }
            await $post(`/jobs/deleteJobs`, { ids: selectedJobs }, { hideLoading: true } );
            this.setState({ selectedJobs: [], visible: false })
            this.fetch();
        } else {
            this.setState({ visible: false })
        }
    }
    clickDelete = async () => {
        this.setState({ visible: true })
    }
    addMpWhiteList = async () => {
        const date = (this.state.whiteListDate || moment()).format('YYYYMMDD');
        const res = await $get(`/jobs/mp/whiteList`, { date }, { hideLoading: true } );
        if (res.code) {
            message.error(res.message);
        } else {
            message.success('正在添加白名单，请10分钟后查看');
        }
    }
    cancel = async () => {
        this.setState({ visible: false })
    }
    render() {
        const rowSelection = {
            onSelect: this.onSelectChange,
            onSelectAll: this.onSelectAll,
        };
        const hasSelected = this.state.selectedJobs.length > 0;
        return <div>
            <div style={{ marginBottom: 16 }}>
                <Popconfirm title="删除任务后不可恢复，是否删除？" onText="确认删除" onCancel={() =>this.cancel()} cancelText="点错了" onConfirm={() => this.deleteJobs()} visible={ this.state.visible } >
                    <Button type="primary" disabled={!hasSelected} onClick={this.clickDelete}>删除计划</Button>
                </Popconfirm>
                <DatePicker 
                    allowClear={false}
                    defaultValue={moment()}
                    value={this.state.whiteListDate} 
                    format="YYYYMMDD"
                    onChange={(e) => {
                        this.setState({ whiteListDate: e })
                    }}
                />
                <Button style={{ marginLeft: 20 }} onClick={this.addMpWhiteList}>添加MP白名单</Button>
            </div>
            <Table
                rowSelection={rowSelection}
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                className="ui-job-list"
                size="small"
            />
        </div>
    }
}
export default withRouter(Jobs);
