import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Job from './job';
import User from './user';
import Provider from './provider';
import Sidebar from './menu';
import Excel from './excel';
import UserGroup from './user-group';
import AdvertiserRole from './advertiser-role';
import './style.less';

export default class AdminRouter extends Component {
    render() {
        return (
            <div className="page-admin">
                <Sidebar />
                <div className="ui-admin-body">
                    <Switch>
                        <Route exact path="/admin" component={Job} />
                        <Route path="/admin/jobs" component={Job} />
                        <Route path="/admin/users" component={User} />
                        <Route path="/admin/user-group" component={UserGroup} />
                        <Route path="/admin/providers" component={Provider} />
                        <Route path="/admin/excel" component={Excel} />
                        <Route path="/admin/advertiser-role" component={AdvertiserRole} />
                    </Switch>
                </div>
            </div>
        )
    }
}
