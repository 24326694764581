import React, { Component } from 'react';
import { DatePicker, Form, Table, Button, Modal, Switch, message, Pagination, Input } from 'antd';
import { $get, $patch, $post } from '../../helpers/remote';
import { copyIntoClipboard } from'../../utils/js/copy';

import prompt from 'antd-prompt';
import moment from 'moment';

export default class AdminUser extends Component {
    state = {
        name: '',
        users: [],
        loading: false,
        pagination: {
            pageSize: 10,
            page: 1,
        },
        groups: [],
        curGroupPage: 1,
        totalGroupPage: 1,
        groupPagination: {
            page: 1,
            pageSize: 10
        },
        changeGroupProps: true
    }

    componentDidMount() {
        this.fetch();
        this.getGroups();
    }
    async fetch({
        page,
        pageSize
    } = this.state.pagination) {
        this.setState({ loading: true });
        const data = { page, pageSize };
        if (this.state.name) {
            data['name'] = this.state.name;
        }
        const [users, count] = await $get('/admin/users', data, { hideLoading: true });
        const pagination = { ...this.state.pagination };
        pagination.total = count;
        this.setState({ users, loading: false, pagination });
    }
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current
        });
    };
    async setAdmin(userId, roleAdmin) {
        await $patch(`/admin/users/${userId}`, { roleAdmin });
        this.fetch();
    }
    async setLeader(userId, isLeader) {
        await $patch(`/admin/users/${userId}`, { isLeader });
        this.fetch();
    }
    async setBanned(userId, banned) {
        await $patch(`/admin/users/${userId}`, { banned });
        if (banned) {
            await $post(`/admin/users/turn-off-strategies`, { userId });
        }
        this.fetch();
    }
    async resetPassword(userId) {
        const password = String(Math.floor(Math.random() * 10000000000) + 10000000000).substr(1);
        await $patch(`/admin/users/${userId}`, { password });
        Modal.success({
            title: '重置成功',
            content: `新密码：${password}`,
        });
    }
    addUser = async () => {
        const phone = await prompt({
            title: '添加用户'
        });
        const password = String(Math.floor(Math.random() * 10000000000) + 10000000000).substr(1);
        await $post('/admin/users', { phone, password });
        await Modal.success({
            title: '添加成功',
            content: `密码：${password}`,
        });
        this.fetch();
    }
    setLimitation = async (userId) => {
        const limitation = await prompt({
            title: '输入限额',
            placeholder: '例如 1000',
        });
        await $patch(`/admin/users/${userId}`, { limitation });
        this.fetch();
    }
    setUsersName = async (userId) => {
        const name = await prompt({
            title: '输入姓名',
            placeholder: '例如：张三',
        });
        await $patch(`/admin/users/${userId}`, { name });
        this.fetch();
    }
    setClearTime = async (userId) => {
        const expireAt = null;
        await $patch(`/admin/users/${userId}`, { expireAt });
        this.fetch();
    }
    onTimerChange = async ( userId, value) => {
        const expireAt = moment(value).format('YYYY-MM-DD');
        await $patch(`/admin/users/${userId}`, { expireAt });
        this.fetch();
    }
    updateAccessSync = async ( userId, value) => {
        await $patch(`/admin/users/${userId}`, { accessSync: value });
        this.setState(({ users }) => ({
            users: users.map(p => {
                if (p.id === userId) {
                    return { ...p, accessSync: value };
                }
                return p;
            })
        }));
    }
    updateAccessBySingleWechat = async ( userId, value) => {
        await $patch(`/admin/users/${userId}`, { accessBySingleWechat: value });
        this.setState(({ users }) => ({
            users: users.map(p => {
                if (p.id === userId) {
                    return { ...p, accessBySingleWechat: value };
                }
                return p;
            })
        }));
    }
    setUserToken = async (userId) => {
        const userToken = await $get(`/admin/userToken/${userId}`);
        if (!userToken) {
            message.error('无法生成最新的user-token');
            return;
        }
        copyIntoClipboard(
            `localStorage.setItem('ad-platform-token', '${userToken.token}')`
        );
        message.success('复制成功，请粘贴到console后刷新页面');
    }
    getGroups = async () => {
        const [groups, totalGroupPage] = await $get('/usergroups', { ...this.state.groupPagination});
        this.setState({ totalGroupPage, groups });
    }
    handleGroupChange = async (current) => {
        this.state.groupPagination.page = current;
        this.getGroups();
    }
    addGroup = async (id) => {
        this.setState({
            cur_group_id: id,
            changeGroupProps: false
        })
    }
    setUserGroup = async (id) => {
        this.setState({
            visible: true,
            cur_user_id: id
        })
    }
    handleCancel = async () => {
        this.setState({
            visible: false,
            changeGroupProps: true
        })
    }
    handleOk = async () => {
        this.setState({
            visible: false
        })
        const userId = this.state.cur_user_id;
        const groupId = this.state.cur_group_id;
        if (!userId || !groupId) return;
        await $patch(`/users/group`, { userId, groupId });
        await Modal.success({
            title: '修改成功',
        });
        this.fetch();
    }
    search = async (e) => {
        const value = e.currentTarget.value;
        this.state.name = value.replace(/ /g, '');
        this.fetch();
    }
    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
            },
            {
                title: '角色',
                dataIndex: 'roleAdmin',
                render: (text) => text ? '管理员' : '普通用户',
            },
            {
                title: '状态',
                dataIndex: 'banned',
                render: (banned) => banned ? '已停用' : '正常',
            },
            {
                title: '每日限额',
                dataIndex: 'limitation',
            },
            {
                title: '今日已用',
                dataIndex: 'usage',
            },
            {
                title: '姓名',
                dataIndex: 'name',
            },
            {
                title: '所属用户组',
                dataIndex: 'groupName',
            },
            {
                title: '到期时间',
                dataIndex: 'expireAt',
                render: (expireAt) => expireAt == null ? '正式版': moment(expireAt).format('YYYY-MM-DD HH:mm'),
            },
            {
                title: '同步功能权限',
                dataIndex: 'accessSync',
                render: (text, record) => <Switch checked={text} onChange={value => this.updateAccessSync(record.id, value)} />,
            },
            {
                title: '仅允许一个微信扫码',
                dataIndex: 'accessBySingleWechat',
                render: (text, record) => <Switch checked={text} onChange={value => this.updateAccessBySingleWechat(record.id, value)} />,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (_, record) => {
                    return <div>
                        {
                            record.roleAdmin
                            ? <Button onClick={() => this.setAdmin(record.id, false)}>取消管理员</Button>
                            : <Button onClick={() => this.setAdmin(record.id, true)}>设为管理员</Button>
                        }
                        {
                            record.banned
                            ? <Button onClick={() => this.setBanned(record.id, false)}>恢复用户</Button>
                            : <Button onClick={() => this.setBanned(record.id, true)}>禁用用户</Button>
                        }
                        <Button onClick={() => this.resetPassword(record.id)}>重置密码</Button>
                        <Button onClick={() => this.setLimitation(record.id)}>设置限额</Button>
                        <Button onClick={() => this.setUsersName(record.id)}>设置姓名</Button>
                        <Button onClick={() => this.setClearTime(record.id)}>清空过期时间</Button>
                        <Button onClick={() => this.setUserToken(record.id)}>快速登录</Button>
                        <Button onClick={() => this.setUserGroup(record.id)}>修改用户组</Button>
                        {
                            record.isLeader
                            ? <Button onClick={() => this.setLeader(record.id, false)}>取消组长</Button>
                            : <Button onClick={() => this.setLeader(record.id, true)}>设为组长</Button>
                        }
                        <Form.Item label="设置到期时间">
                                <div>
                                    <DatePicker onChange={ (value) => this.onTimerChange( record.id, value) }  />
                                </div>
                        </Form.Item>
                    </div>
                }
            }
        ];
        return columns;
    }
    render() {
        return <div className="page-admin-user">
            <Button onClick={this.addUser} className="add-user">添加用户</Button>
            <Input type="text" placeholder="请输入用户名" onBlur={(e) => this.search(e)} style={{ width: 200 }} />
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.users}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                loading={this.state.loading}
            />
            <Modal
                title="请选择用户组"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: this.state.changeGroupProps }}
            >
                <div>
                    {this.state.groups.map((item) => {
                        return (
                            <Button style={{ margin: '0 10px 10px 0' }} onClick={() => this.addGroup(item.id)} key={item.id}>{item.groupName}</Button>
                        )
                    })}
                </div>
                <Pagination style={{ textAlign: 'right', marginTop: '20px' }} onChange={this.handleGroupChange} defaultCurrent={this.state.curGroupPage} total={this.state.totalGroupPage} />
            </Modal>
        </div>
    }
}