import React, { Component } from 'react';
import { Table, Button, Modal, Input, Tooltip } from 'antd';
import { $get, $patch, $post, $delete, $put } from '../../helpers/remote';

import prompt from 'antd-prompt';

const { Search } = Input;

export default class AdminUser extends Component {
    state = {
        groups: [],
        loading: false,
        visible: false,
        cur_group_id: '',
        visible2: false,
        cur_user_id: '',
        pagination: {
            pageSize: 10,
            page: 1,
        },
        searchGroupName: '',
    }

    componentDidMount() {
        this.fetch();
    }
    async fetch({
        page,
        pageSize
    } = this.state.pagination) {
        this.setState({ loading: true });
        const pagination = this.state.pagination;
        const name = this.state.searchGroupName || '';
        const [groups, count] = await $get('/usergroups', {page, pageSize, name}, { hideLoading: true });
        pagination.total = count;
        this.setState({ groups, loading: false, pagination });
    }
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetch({
            pageSize: pagination.pageSize,
            page: pagination.current
        });
    };
    addUserGroup = async () => {
        const groupName = await prompt({
            title: '添加用户组'
        });
        await $post('/usergroups', { groupName });
        await Modal.success({
            title: '添加成功',
        });
        this.fetch();
    }
    delUserGroup = async () => {
        const id = this.state.cur_group_id
        if (!id) {
            Modal.error({
                title: '错误',
                content: '无法获得当前用户组的ID',
            });
            return
        }
        await $delete(`/usergroups/${id}`);
        await Modal.success({
            title: '删除成功',
        });
        this.fetch();
    }
    delGroup = async (id) => {
        this.setState({
            visible: true,
            cur_group_id: id
        });
    }
    updateGroup = async (id) => {
        const groupName = await prompt({
            title: '修改用户组名称'
        });
        if (!groupName) {
            alert('请输入用户组名称');
            return;
        }
        await $put(`/usergroups/${id}`, { groupName });
        await Modal.success({
            title: '修改成功',
        });
        this.fetch();
    }
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
        this.delUserGroup();
    };
    handleCancel = e => {
        this.setState({
            visible: false,
            cur_group_id: ''
        });
    };
    removeUser = async (id) => {
        this.setState({
            visible2: true,
            cur_user_id: id
        });
    }
    removeUserFromGroup = async () => {
        const userId = this.state.cur_user_id;
        if (!userId) return;
        await $patch(`/users/group`, { userId });
        await Modal.success({
            title: '修改成功',
        });
        this.fetch();
    }
    handleOk2 = e => {
        console.log(e);
        this.setState({
            visible2: false,
        });
        this.removeUserFromGroup();
    };
    handleCancel2 = e => {
        this.setState({
            visible2: false,
            cur_user_id: ''
        });
    };
    handleSearch = async (value) => {
        this.setState({
                searchGroupName: value,
            },
            () =>  this.fetch()
        );
    }
    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '用户组名称',
                dataIndex: 'groupName',
            },
            {
                title: '用户组成员',
                dataIndex: 'groupMember',
                render: (arr) => {
                    return <div>
                        {arr.map((item) => {
                            return (
                                <Tooltip placement='top' title='移除用户' key={item.id}>
                                    <Button onClick={() => this.removeUser(item.id)} type='dashed' >{item.name}</Button>
                                </Tooltip>
                            );
                        })}
                    </div>
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (_, record) => {
                    return <div>
                        <Button onClick={() => this.updateGroup(record.id)}>修改用户组名</Button>
                        <Button danger onClick={() => this.delGroup(record.id)}>删除用户组</Button>
                    </div>
                }
            }
        ];
        return columns;
    }
    render() {
        return <div className="page-admin-user">
            <Button onClick={this.addUserGroup} className="add-user">添加用户组</Button>
            <Search
              placeholder="请输入用户组名称"
              onSearch={value => this.handleSearch(value)}
              style={{ width: 200, margin: '20px' }}
            />
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.groups}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                loading={this.state.loading}
            />
            <Modal
                title="确定删除用户组吗？"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
            </Modal>
            <Modal
                title="确定移除该用户吗？"
                visible={this.state.visible2}
                onOk={this.handleOk2}
                onCancel={this.handleCancel2}
            >
            </Modal>
        </div>
    }
}