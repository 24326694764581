import React, { Component } from 'react';
import { Row, Col, List, Button, Tag, Input, Pagination, Typography, Form, message, Modal } from 'antd';
import { $get, $post, $upload } from '../../helpers/remote';
import MaterialPreview from '../../components/material-preview';
import ChooseAdvertiser from './choose-advertiser';

const { CheckableTag } = Tag;
const { Text } = Typography;
const layout = {
    labelCol: {
        span: 18,
    },
    wrapperCol: {
        span: 13,
    },
};
//按使用频率排序
const Order = { direction: 'DESC', orderBy: 'campainsCount' };
//三列素材的pageSize
const ImagePageSize = 12;
const TextPageSize = 10;
const PagePageSize = 10;
const TagsData = ['使用频率', '时间顺序'];

export default class ChooseMaterial extends Component {
    state = {
        pageName: '',
        groupName: '',
        //三列素材的数据，arr[0]是数据列表，arr[1]总条数
        imageDatas: [[], 0],
        textDatas: [[], 0],
        pageDatas: [[], 0],
        //图片素材、文案素材选中的Tag
        selectedTags: ['使用频率', '使用频率'],
        //选中的素材id[]
        selectedImageIds: [],
        selectedTextIds: [],
        selectedPageIds: [],
        //选中的素材的内容content[]
        selectedImageItems: [],
        selectedTextItems: [],
        selectedPageItems: [],
        //三列素材的page
        imagePage: 1,
        textPage: 1,
        pagePage: 1,
        //原生推广页的id -> 原生推广页描述textMaterialId 的映射
        descMap: {},
        visible: false,
        advertiserId: 0,
    }
    componentDidMount() {
        this.fetch();
    }
    handleClick = (key, id, content) => {
        const ids = this.getSelected(key, 'selectedIds');
        const items = this.getSelected(key, 'selectedItems');
        if (ids.includes(id)) {
            const newIds = ids.filter(item => item !== id);
            const newItems = items.filter(item => item.id !== id);
            this.setSelected(key, 'selectedIds', newIds);
            this.setSelected(key, 'selectedItems', newItems);
        } else {
            ids.push(id);
            items.push({
                id,
                content
            })
            this.setSelected(key, 'selectedIds', ids);
            this.setSelected(key, 'selectedItems', items);
        }
    }
    handleTagChange = async (tag, checked, key) => {
        const selectedTag = this.getSelectedTag(key);
        if (tag === selectedTag) return;
        const order = tag === TagsData[0] ? Order : {};
        await this.getMaterialsByType(1, key, order);
        this.setSelectedTag(key, tag);
        this.setPage(key, 1);
    }
    handleDescChange = (e, index) => {
        const { pageDatas } = this.state;
        const data = pageDatas[0];
        data[index].description = e.target.value;
        this.setState(pageDatas);
    }
    handleDescSave = async (index) => {
        const { pageDatas } = this.state;
        const data = pageDatas[0];
        const title = data[index].title;
        const content = data[index].description;
        if (content) {
            const result = await $post(`/materials`, { title, content });
            const { descMap } = this.state;
            descMap[data[index].id + ''] = result.id;
            this.setState({ descMap });
            message.success('保存成功');
        }
    }
    nextPage = async (page, key) => {
        const selectedTag = this.getSelectedTag(key);
        const order = selectedTag === TagsData[0] ? Order : {};
        this.setPage(key, page);
        await this.getMaterialsByType(page, key, order);
    }
    fetch = async (params) => {
        const { imagePage, textPage, pagePage } = this.state;
        this.getMaterialsByType(imagePage, 'image', Order);
        this.getMaterialsByType(textPage, 'text', Order);
        this.getMaterialsByType(pagePage, 'page', Order);
    };
    submit = async (e) => {
        const { pageName, groupName } = e;
        const { selectedImageItems, selectedTextItems, selectedPageItems, advertiserId } = this.state;
        if (!selectedPageItems.length) {
            message.error('请选择原生推广模版');
            return;
        }
        if (!selectedImageItems.length && !selectedTextItems.length) {
            message.error('请选择图片／视频素材 或 文案素材');
            return;
        }
        if (!advertiserId) {
            message.error('请选择广告主');
            return;
        }
        const num = Math.max(selectedImageItems.length, 1) * Math.max(selectedTextItems.length, 1) * selectedPageItems.length;
        Modal.confirm({
            content: `即将生成${num}个原生推广页`,
            onOk: () => {
                this.setState({
                    pageName,
                    groupName
                }, () => this.submitMaterialGroup() );
            }
        })

    };
    submitMaterialGroup = async () => {
        const hide = message.loading('生成中...', 0);

        //图片素材转微信图片
        const uploadResult = await this.uploadToWx();
        if (!uploadResult) {
            hide();
            return;
        }

        //排列组合，替换素材
        const result = await this.mixAndReplace();

        //上传生成的PAGE
        const pages = await this.createPages(result);

        //创建素材分组，并将生成的原生落地页放在分组下
        const group = await this.createGroup(pages);

        this.props.handlePages({ pages, group });

        hide();
        Modal.success({
            title: '生成成功',
            content: `已生成${pages.length}个原生推广页`,
        });
    }
    mixAndReplace = async () => {
        const { selectedImageItems, selectedTextItems, selectedPageItems, descMap, pageName} = this.state;
        const result = [];
        let index = 1;
        //替换素材
        const mix = (imageItem, textItem, pageItem, parse) => {
            const { content, canvas, arr } = parse;
            if (imageItem) arr[0].pureImageUrl = imageItem.wx_url;
            if (textItem) arr[1].content = textItem.content;
            canvas.adCanvasInfo.PageList.Page.componentItemList.componentItem = arr;
            const num = index > 9 ? String(index) : `0${index}`;
            canvas.adCanvasInfo.canvasName = `${pageName}-${num}`;
            content.canvas_name = `${pageName}-${num}`;
            content.canvas_info = JSON.stringify(canvas);
            result.push({ canvas: JSON.stringify(content), textMaterialId: descMap[pageItem.id], num });
            index++;
        }
        //排列组合
        for (const pageItem of selectedPageItems) {
            try {
                const content = JSON.parse(pageItem.content);
                const canvas = JSON.parse(content.canvas_info);
                const arr = canvas.adCanvasInfo.PageList.Page.componentItemList.componentItem;
                const parse = { content, canvas, arr };
                if (selectedImageItems.length && selectedTextItems.length) {
                    for (const textItem of selectedTextItems) {
                        for (const imageItem of selectedImageItems) {
                            mix(imageItem, textItem, pageItem, parse);
                        }
                    }
                } else if (selectedTextItems.length) {
                    for (const textItem of selectedTextItems) {
                        mix(null, textItem, pageItem, parse);
                    }
                } else {
                    for (const imageItem of selectedImageItems) {
                        mix(imageItem, null, pageItem, parse);
                    }
                }

            } catch (err) {
                console.log(err);
            }
        }
        return result;
    }
    uploadToWx = async () => {
        const { selectedImageItems, advertiserId } = this.state;
        for (const imageItem of selectedImageItems) {
            if (imageItem.content) {
                try {
                    const wxImg = await $post('/materials/uploadImageToWeixin', {url: imageItem.content, advertiserId}, { hideLoading: true, throwException: true });
                    imageItem.wx_url = wxImg.image_url;
                } catch (e) {
                    if (e.response && /登录状态过期/.test(e.response.data.message)) {
                        message.error('服务商用户token过期，请重新扫码。');
                    } else {
                        message.error(e.response ? e.response.data.message : e.message);
                    }
                    return false;
                }
            }
        }
        return true;
    }
    createPages = async (result) => {
        const { pageName } = this.state;
        let pages = [];
        for (let i = 0; i < result.length; i++) {
            const { canvas, num } = result[i];
            const shareThumbUrl = JSON.parse(JSON.parse(canvas).canvas_info).adCanvasInfo.shareThumbUrl;
            const res = await $upload(
                '/materials',
                {
                    canvas: canvas,
                    title: `${pageName}-${num}`,
                    url: shareThumbUrl,
                    type: 'PAGE',
                },
                { hideLoading: true }
            );
            res.textMaterialId = result[i].textMaterialId;
            res.num = num;
            pages.push(res);
        }
        return pages;
    }
    createGroup = async (pages) => {
        const { groupName } = this.state;
        const materialIds = pages.map(o => o.id);
        return await $post('/material-groups', { groupName, materialIds: JSON.stringify(materialIds) }, { hideLoading: true });
    }
    getMaterialsByType = async (page, key, order = {}) => {
        const type = key.toUpperCase();
        const pageSize = this.getPage(key, 'pageSize');
        const data = await $get('/materials', { page, pageSize, type, ...order });
        this.setDatas(key, data);
    }
    getPage = (key, type) => {
        let content = 0;
        if (type === 'page') {
            const { imagePage, textPage, pagePage } = this.state;
            if (key === 'image') {
                content = imagePage;
            } else if (key === 'text') {
                content = textPage;
            } else {
                content = pagePage;
            }
        } else if (type === 'pageSize') {
            if (key === 'image') {
                content = ImagePageSize;
            } else if (key === 'text') {
                content = TextPageSize;
            } else {
                content = PagePageSize;
            }
        }
        return content;
    }
    setPage = (key, newPage) => {
        if (key === 'image') {
            this.setState({ imagePage: newPage });
        } else if (key === 'text') {
            this.setState({ testPage: newPage });
        } else {
            this.setState({ pagePage: newPage });
        }
    }
    getSelectedTag = (key) => {
        const { selectedTags } = this.state;
        const tag = key === 'image' ? selectedTags[0] : selectedTags[1];
        return tag;
    }
    setSelectedTag = (key, newTag) => {
        const { selectedTags } = this.state;
        if (key === 'image') {
            selectedTags[0] = newTag;
        } else {
            selectedTags[1] = newTag;
        }
        this.setState({ selectedTags });
    }
    getSelected = (key, type) => {
        let content = [];
        if (type === 'selectedIds') {
            const { selectedImageIds, selectedTextIds, selectedPageIds } = this.state;
            if (key === 'image') {
                content = selectedImageIds;
            } else if (key === 'text') {
                content = selectedTextIds;
            } else {
                content = selectedPageIds;
            }
        } else if (type === 'selectedItems') {
            const { selectedImageItems, selectedTextItems, selectedPageItems } = this.state;
            if (key === 'image') {
                content = selectedImageItems;
            } else if (key === 'text') {
                content = selectedTextItems;
            } else {
                content = selectedPageItems;
            }
        }
        return content;
    }
    setSelected = (key, type, newContent) => {
        if (type === 'selectedIds') {
            if (key === 'image') {
                this.setState({ selectedImageIds: newContent });
            } else if (key === 'text') {
                this.setState({ selectedTextIds: newContent });
            } else {
                this.setState({ selectedPageIds: newContent });
            }
        } else if (type === 'selectedItems') {
            if (key === 'image') {
                this.setState({ selectedImageItems: newContent });
            } else if (key === 'text') {
                this.setState({ selectedTextItems: newContent });
            } else {
                this.setState({ selectedPageItems: newContent });
            }
        }
    }
    setDatas = (key, newData) => {
        if (key === 'image') {
            this.setState({ imageDatas: newData });
        } else if (key === 'text') {
            this.setState({ textDatas: newData });
        } else {
            this.setState({ pageDatas: newData });
        }
    }
    handleAdvertiserId = (advertiserId) => {
        this.setState({ advertiserId, visible: false });
    }
    render() {
        const { selectedTags, visible,
                imageDatas, textDatas, pageDatas,
                selectedImageIds, selectedTextIds, selectedPageIds,
                imagePage, textPage, pagePage,
                } = this.state;
        return <div className='page-auto-upload'>
            <Form {...layout} layout='inline' name='basic' className='input-box' initialValues={{ remember: true }}
                onFinish={ this.submit }
            >
                <Form.Item
                    label='原生推广页名称' name='pageName'
                    rules={ [{ required: true, message: '请输入原生推广页名称' }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='原生推广页分组名称' name='groupName'
                    rules={ [{ required: true, message: '请输入原生推广页分组名称'}] }
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button onClick={ () => { this.setState({ visible: true }) } }>选择广告主</Button>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>生成</Button>
                </Form.Item>
            </Form>

            <Row gutter={8}>
                <Col span={8}>
                    <div className='col-header'>
                        <h4>图片／视频素材</h4>
                        <div className='div-sort'>
                            { TagsData.map(tag => (
                                <CheckableTag
                                    key={ tag + '0' }
                                    checked={ selectedTags[0].includes(tag) }
                                    onChange={ checked => this.handleTagChange(tag, checked, 'image') }
                                >
                                    { tag }
                                </CheckableTag>
                            )) }
                        </div>
                    </div>
                    <List
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={ imageDatas[0] }
                        renderItem={ item => (
                            <List.Item className={ `${selectedImageIds.includes(item.id) ? 'selected' : ''} imgBox` } onClick={ () => this.handleClick('image', item.id, item.url) }>
                                <MaterialPreview url={ item.url || '' } type={ item.type } width={100} />
                            </List.Item>
                        ) }
                    />
                    <Pagination current={ imagePage } simple onChange={ page => { this.nextPage(page, 'image') } }  total={ imageDatas[1] } pageSize={ImagePageSize} />
                </Col>
                <Col span={8}>
                    <div className='col-header'>
                        <h4>文案素材</h4>
                        <div className='div-sort'>
                            { TagsData.map(tag => (
                                <CheckableTag
                                    key={ tag + '1' }
                                    checked={ selectedTags[1].includes(tag) }
                                    onChange={ checked => this.handleTagChange(tag, checked, 'text') }
                                >
                                    {tag}
                                </CheckableTag>
                            )) }
                        </div>
                    </div>
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={ textDatas[0] }
                        renderItem={ item => (
                            <List.Item className={ selectedTextIds.includes(item.id) ? 'selected' : '' } onClick={ () => this.handleClick('text', item.id, item.title) }>{ item.title }</List.Item>
                        ) }
                    />
                    <Pagination current={ textPage } simple onChange={ page => { this.nextPage(page, 'text') } }  total={ textDatas[1] } pageSize={ TextPageSize } />
                </Col>
                <Col span={8}>
                    <div className='col-header'>
                        <h4>原生推广页模版</h4>
                    </div>
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={ pageDatas[0] }
                        renderItem={ (item, index) => (
                            <List.Item className='pageItem'>
                                <span className={ `pageId ${selectedPageIds.includes(item.id) ? 'selected' : ''}` } onClick={ () => this.handleClick('page', item.id, item.canvas) }>{ item.id }</span>
                                <Input placeholder='原生推广页模版描述' value={ item.description } onChange={ (e) => this.handleDescChange(e, index) } />
                                <Button type='text' onClick={ () => this.handleDescSave(index) }><Text type='secondary'>保存</Text></Button>
                            </List.Item>
                        ) }
                    />
                    <Pagination current={ pagePage } simple onChange={ page => { this.nextPage(page, 'page' ) } }  total={ pageDatas[1] } pageSize={ PagePageSize } />
                </Col>
            </Row>
            <ChooseAdvertiser handleAdvertiserId={ this.handleAdvertiserId } visible={ visible } />
        </div>
    }
}
