import { Table, Radio, Switch, Button, message, Popconfirm, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { $delete, $get, $patch, $post } from '../../helpers/remote';
import StrategyEditor from './edit';
import StrategyAiEditor from './edit-ai';
import StrategyGroupEditor from './edit-group';
import BindStrategyGroup from './bind-strategy-group';
import BindStrategyAi from './bind-strategy-ai';
import './index.less';

const PAGE_SIZE = 10;

export default function StrategyList() {
    const [items, setItems] = useState([]);
    const [groupItems, setGroupItems] = useState([]);
    const [offset, setOffset] = useState(0);
    const [groupOffset, setGroupOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [groupTotal, setGroupTotal] = useState(0);
    const [type, setType] = useState('ALL');
    const [groupType, setGroupType] = useState('ALL');
    const [option, setOption] = useState('strategyGroup');
    const [profile, setProfile] = useState({});
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(0);
    const [games, setGames] = useState([]);
    const [gameId, setGameId] = useState();
    const [strategyAis, setStrategyAis] = useState([]);
    const [strategyAiTotal, setStrategyAiTotal] = useState(0);
    
    const getProfile = useCallback(async () => {
        if (!profile.id) {
            const user = await $get('/users/profile', {}, {
                hideLoading: true
            });
            setProfile(user);
        }
    }, [profile])
    
    const getAllUsers = useCallback(async () => {
        if (!users.length) {
            const res = await $get('/users');
            setUsers(res[0] || []);
        }
    }, [users])
    const getGames = useCallback(async () => {
        if ((['PITCHER', 'BASIC'].includes(type) || option === 'strategyAI') && !games.length) {
            const games = await $get('/strategies/gamePackages');
            setGames(games);
        }
    }, [type, option, games])
    // 初始化用户
    useEffect(() => {
        getProfile();
        getAllUsers();
        getGames();
    }, [getProfile, getAllUsers, getGames])

    const fetch = useCallback(async (offset, curType, userId) => {
        const data = await $get('/strategies', { limit: PAGE_SIZE, offset, type: curType || type, userId });
        setItems(data[0].map(o => ({ ...o, groupName: (o.strategyGroup && o.strategyGroup.title) || '' })));
        setTotal(data[1]);
    }, [type]);

    const groupFetch = useCallback(async (offset, curType, userId) => {
        const type = curType || groupType;
        const data = await $get('/strategy-groups', { limit: PAGE_SIZE, offset, type, userId, gameId });
        data[0].forEach(o => {
            if (type === 'ADVERTISER') {
                o.bindingName = o.advertisers.map(o => `${o.name}-${o.uid}`).join(',');
            } else if (type === 'PITCHER' || type === 'BASIC') {
                o.bindingName = String(o.game && (o.game.gameName || o.game.gameId)) || '';
            } else if (type === 'JOB') {
                o.bindingName = o.campainNames;
            } else if (type === 'AI') {
                o.bindingName = o.strategyAis.map(o => o.title).join(',');
            }
        });
        setGroupItems(data[0]);
        setGroupTotal(data[1]);
    }, [groupType, gameId]);

    const aiFetch = useCallback(async (page) => {
        const [strategyAis, total] = await $get('/strategy-ai', { page, pageSize: 10, gameId, userId });
        setStrategyAis(strategyAis);
        setStrategyAiTotal(total);
    }, [gameId, userId])

    const switchStatus = async(id, on) => {
        await $patch(`/strategies/${id}/off`, { off: !on });
        await fetch(offset, '', userId);
    }

    const copyStrategy = async(strategyId) => {
        const strategy = await $get(`/strategies/${strategyId}`);
        if (!strategy) {
            message.error('复制失败');
            return;
        }
        const { id, title, conditionGroups, operationGroups, execTimeGroups } = strategy;
        await $post('/strategies', { title: `${title}【复制#${id}】`, conditionGroups, operationGroups, execTimeGroups: execTimeGroups || [] });
        message.success(`复制成功!`);
        reload();
    }

    const copyStrategyGroup = async(strategyGroupId, notify = true) => {
        const strategyGroup = await $get(`/strategy-groups/${strategyGroupId}`);
        if (!strategyGroup) {
            message.error('复制失败');
            return;
        }
        const { strategies } = strategyGroup;
        const strategyIds = [];
        for (const strategy of strategies) {
            const { id, title, conditionGroups, operationGroups, execTimeGroups } = strategy;
            const newId = await $post('/strategies', { title: `${title}【复制#${id}】`, conditionGroups, operationGroups, execTimeGroups: execTimeGroups || [] }, { hideLoading: true });
            strategyIds.push(newId);
        }
        const { id, title } = strategyGroup;
        const newStrategyGroup = await $post('/strategy-groups', { title: `${title}【复制#${id}】`, strategyIds });
        if (!notify) {
            return newStrategyGroup;
        }
        message.success(`复制成功!`);
        reload();
    }

    const copyStrategyAi = async (strategyAiId) => {
        const strategyAi = await $get(`/strategy-ai/${strategyAiId}`, { hideLoading: true });
        if (!strategyAi) {
            message.error('复制失败');
            return;
        }
        const { strategyGroups, title, id, conditionGroups } = strategyAi;
        let strategyGroup;
        if (strategyGroups && strategyGroups.length) {
            strategyGroup = await copyStrategyGroup(strategyGroups[0].id, false);
        }
        await $post('/strategy-ai', { title: `${title}【复制#${id}】`, strategyGroups: [strategyGroup], conditionGroups });
        message.success(`复制成功!`);
        reload();
    }
    
    const delStrategyGroup = async(strategyGroupId) => {
        await $delete(`/strategy-groups/${strategyGroupId}`);
        reload();
    }
    const delStrategy = async(strategyId) => {
        await $delete(`/strategies/${strategyId}`);
        reload();
    }

    const delStrategyAi = async (strategyAiId) => {
        await $delete(`/strategy-ai/${strategyAiId}`);
        reload();
    }

    const selectChange = (userId) => {
        setUserId(userId);
    }

    useEffect(() => {
        groupFetch(0, '', userId);
    }, [groupFetch, userId])

    useEffect(() => {
        fetch(0, '', userId);
    }, [fetch, userId])

    useEffect(() => {
        aiFetch(1);
    }, [aiFetch, userId])

    const reload = (type) => {
        if ((type || option) === 'strategyGroup') {
            groupFetch(0, '', userId);
        } else if ((type || option) === 'strategyAI') {
            aiFetch(1);
        } else {
            fetch(0, '', userId);
        }
    }

    const filterOption = (inputValue, option) => {
        return option.children && option.children.includes(inputValue);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '所属投手',
            key: 'user',
            render(_, row) {
                return <span style={{ cursor: 'pointer' }} onClick={() => selectChange(row.user.id)}>{ row.user.name }</span>
            }
        },
        {
            title: '策略分组名称',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: '状态',
            key: 'off',
            render(_, row) {
                return <Switch checked={row.off ? false : true} onChange={(e) => switchStatus(row.id, e)} />
            }
        },
        {
            title: '操作',
            key: 'operations',
            render(_, row) {
                return <>
                    <StrategyEditor strategyId={row.id} onFinish={reload} isSelf={row.user.id === profile.id} />
                    <Button style={{marginLeft: '10px'}} onClick={() => copyStrategy(row.id)}>复制</Button>
                    {
                        type === 'FREE' && row.user.id === profile.id
                        ? <Popconfirm
                                title="确定删除此策略吗？"
                                onConfirm={() => delStrategy(row.id)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button danger style={{marginLeft: '10px'}}>删除</Button>
                            </Popconfirm>
                        : ''
                    }
                </>
            }
        },
    ]
    
    const aiColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '绑定游戏包',
            key: 'games',
            render(_, row) {
                return <div>
                    {
                        row.games && row.games.length
                            ? row.games.map(game => {
                                return <p style={{ cursor: 'pointer' }} onClick={() => setGameId(game.gameId)} key={game.gameId}>{ game.gameName }</p>
                            })
                            : ''
                    }
                </div>
            }
        },
        {
            title: '所属投手',
            key: 'user',
            render(_, row) {
                return <span style={{ cursor: 'pointer' }} onClick={() => selectChange(row.userId)}>{users.find(o => o.id === row.userId)?.name}</span>
            }
        },
        {
            title: '操作',
            key: 'operations',
            render(_, row) {
                return <>
                    <StrategyAiEditor strategyAiId={row.id} onFinish={reload} isSelf={row.userId === profile.id} />
                    {
                        row.userId === profile.id &&
                        <BindStrategyAi strategyAi={row} games={games} onClose={() => reload()} />
                    }
                    <Button style={{ marginLeft: '10px' }} onClick={() => copyStrategyAi(row.id)}>复制</Button>
                    {
                        (!row.games || !row.games.length) && row.userId === profile.id
                            ? <Popconfirm
                                title="确定删除此Ai策略吗？"
                                onConfirm={() => delStrategyAi(row.id)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button danger style={{ marginLeft: '10px' }}>删除</Button>
                            </Popconfirm>
                            : ''
                    }
                </>
            }
        },
    ]

    const groupColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '所属投手',
            key: 'user',
            render(_, row) {
                return <div style={{ cursor: 'pointer' }} onClick={() => selectChange(row.userId)}>{ users.find(o => o.id === row.userId)?.name }</div>
            }
        },
        {
            title: '绑定对象',
            dataIndex: 'bindingName',
            key: 'bindingName',
            render(_, row) {
                return <div style={{ cursor: 'pointer' }} onClick={row.game ? () => setGameId(row.game.gameId) : ''}>
                {
                    row.bindingName
                    ? (row.bindingName.indexOf(',') === -1)
                        ? row.bindingName
                        : row.bindingName.split(',').map(o => <div>{o}</div>)
                    : ''
                }
                </div>
            } 
        },
        {
            title: '操作',
            key: 'operations',
            render(_, row) {
                return <>
                    <StrategyGroupEditor strategyGroupId={row.id} onFinish={reload} isSelf={row.userId === profile.id} />
                    <Button style={{ marginLeft: '10px' }} onClick={() => copyStrategyGroup(row.id)}>复制</Button>
                    {
                        row.userId === profile.id && (!row.strategyAis || !row.strategyAis.length) &&
                            <BindStrategyGroup strategyGroupId={row.id} strategyGroupType={groupType} onClose={() => groupFetch(offset, '', userId)} />
                    }
                    {
                        groupType === 'FREE' && row.userId === profile.id
                        ? <Popconfirm
                                title="确定删除此策略分组吗？"
                                onConfirm={() => delStrategyGroup(row.id)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button danger style={{marginLeft: '10px'}}>删除</Button>
                            </Popconfirm>
                        : ''
                    }
                </>
            }
        },
    ]

    return (
        <div className="page-strategy-list">
            <Radio.Group
                onChange={(e) => {
                    setOption(e.target.value);
                    reload(e.target.value);
                }}
                value={option}
                optionType="button"
                style={{marginBottom: '10px'}}
            >
                <Radio value={'strategyGroup'}>策略分组</Radio>
                <Radio value={'strategyAI'}>智能策略</Radio>
                <Radio value={'strategy'}>策略</Radio>
            </Radio.Group>
            <div>
                <Select placeholder="请选择用户" defaultValue={0} value={userId} style={{ width: 200, marginBottom: 10 }} showSearch onChange={selectChange} filterOption={filterOption}>
                    <Select.Option value={0}>全部</Select.Option>
                    {
                        users.map(o => {
                            return <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                        })
                    }
                </Select>
                {((option === 'strategyGroup' && ['PITCHER', 'BASIC'].includes(groupType)) || option === 'strategyAI')
                    && <Select placeholder="请选择游戏包" value={gameId} style={{ width: 200, marginBottom: 10 }} showSearch allowClear onChange={(gameId) => setGameId(gameId)} filterOption={filterOption}>
                    {
                        games.map(o => {
                            return <Select.Option key={o.id} value={o.id}>{o.game_name}</Select.Option>
                        })
                    }
                </Select>}
            </div>
            {
                option === 'strategyGroup'
                ?
                    <>
                        <div className="operations">
                            <StrategyGroupEditor onFinish={reload} isSelf={true} />
                            <Radio.Group style={{marginLeft: '20px'}} onChange={e => {
                                setGroupType(e.target.value);
                                setOffset(0);
                            }} value={groupType}>
                                <Radio value={'ALL'}>全部</Radio>
                                <Radio value={'JOB'}>计划策略分组</Radio>
                                <Radio value={'ADVERTISER'}>广告主策略分组</Radio>
                                <Radio value={'AI'}>智能策略</Radio>
                                <Radio value={'PITCHER'}>我的游戏包策略分组</Radio>
                                <Radio value={'BASIC'}>游戏包基本策略分组</Radio>
                                <Radio value={'FREE'}>未使用策略分组</Radio>
                            </Radio.Group>
                        </div>
                        <Table
                            columns={groupColumns}
                            dataSource={groupItems}
                            rowKey="id"
                            pagination={{
                                current: Math.floor(groupOffset / PAGE_SIZE) + 1,
                                total: groupTotal,
                                pageSize: PAGE_SIZE,
                                showSizeChanger: false,
                                onChange(page) {
                                    const o = (page - 1) * PAGE_SIZE;
                                    setGroupOffset(o);
                                    groupFetch(o, '', userId);
                                }
                            }}
                        />
                    </>
                : option === 'strategyAI'
                    ? 
                        <>
                            <div className="operations">
                                <StrategyAiEditor onFinish={reload} isSelf={true} />
                                <Table
                                    columns={aiColumns}
                                    dataSource={strategyAis}
                                    rowKey="id"
                                    pagination={{
                                        current: Math.floor(offset / PAGE_SIZE) + 1,
                                        total: strategyAiTotal,
                                        pageSize: PAGE_SIZE,
                                        showSizeChanger: false,
                                        onChange(page) {
                                            const o = (page - 1) * PAGE_SIZE;
                                            setOffset(o);
                                            aiFetch(page);
                                        }
                                    }}
                                />
                            </div>
                        </>
                    :
                        <>
                            <div className="operations">
                                <StrategyEditor onFinish={reload} isSelf={true} />
                                <Radio.Group style={{marginLeft: '20px'}} onChange={e => {
                                    setType(e.target.value);
                                    setOffset(0);
                                }} value={type}>
                                    <Radio value={'ALL'}>我的策略</Radio>
                                    <Radio value={'BASIC'}>游戏包基本策略</Radio>
                                    <Radio value={'FREE'}>未使用策略</Radio>
                                </Radio.Group>
                                <Table
                                    columns={columns}
                                    dataSource={items} 
                                    rowKey="id"
                                    pagination={{
                                        current: Math.floor(offset / PAGE_SIZE) + 1,
                                        total,
                                        pageSize: PAGE_SIZE,
                                        showSizeChanger: false,
                                        onChange(page) {
                                            const o = (page - 1) * PAGE_SIZE;
                                            setOffset(o);
                                            fetch(o, '', userId);
                                        }
                                    }}
                                />
                            </div>
                        </>
            }
        </div>
    )
}
