import React, { Component } from 'react'
import { Table, Select, Button, Popconfirm, message, Modal } from 'antd'
import { $get, $patch } from '../../helpers/remote'

const { Option } = Select
export default class AdminUser extends Component {
    state = {
        users: [],
        userId: '',
        providerId: '',
        isShow: false,
        providers: [],
        advertisers: [],
        selectArr: [],
        selectAdvertisers: [],
        loading: false,
        modalPagination: {
            page: 1,
            pageSize: 5
        },
        appIdArr: []
    }

    componentDidMount() {
        this.fetch()
    }

    async fetch() {
        this.setState({ loading: true })
        const [users] = await $get('/admin/users', { page: 1, pageSize: 1000 }, { hideLoading: true })
        this.setState({ users, loading: false })
    }
    // 获取投手，更新广告主
    async getSelectUser(userId = 1, outerVisible = 1) {
        this.setState({ loading: true })
        const data = await $get('/advertisers', {
            userId,
            outerVisible: outerVisible
        }, { hideLoading: true })
        const advertisers = data.reverse().map(o => {
            o.providerName = o.provider?.agencyName || '';
            return o;
        });
        this.setState({
            advertisers,
            loading: false,
            userId: userId
        });
    }

    // 获取服务商下的广告主
    async getSelectProvider(providerId) {
        this.setState({ loading: true })
        let pagination = this.state.modalPagination
        const [data, total] = await $get(`/providers/${providerId}/advertisers`, {
            page: this.state.modalPagination.page,
            pageSize: this.state.modalPagination.pageSize,
            userId: this.state.userId,
            outerVisible: 0
        }, { hideLoading: true })
        pagination.total = total
        this.setState({
            selectAdvertisers: data,
            loading: false,
            modalPagination: pagination
        })

    }

    //对话框内表格改变
    handleModalTableChange = async (pagination) => {
        const pager = this.state.modalPagination
        pager.current = pagination.current
        pager.page = pagination.current
        pager.pageSize = pagination.pageSize
        const [data] = await $get(`/providers/${this.state.providerId}/advertisers`, {
            page: pager.page,
            pageSize: pager.pageSize,
            userId: this.state.userId,
            outerVisible: 0
        })
        this.setState({
            selectAdvertisers: data,
            modalPagination: pager
        })
    }

    // 确定移除权限的操作
    async removeEnter(record) {
        const res = await $patch('/advertisers/batch-update-outer-visible', { ids: [record.id], outerVisible: false }, { hideLoading: true })
        if (res.raw.changedRows === 1) {
            message.success('修改成功')
            await this.getSelectUser(this.state.userId)
        } else {
            message.error('修改失败')
        }
    }

    // 选择投手之后的操作
    selectChange = (value) => {
        this.setState({
            userId: value,
        })
        this.getSelectUser(value)
    }

    // 选择服务商之后的操作
    addProviderChange = (value) => {
        const temp = this.state.modalPagination
        temp.current = 1
        temp.page = 1
        this.setState({
            providerId: value,
            modalPagination: temp
        })
        this.getSelectProvider(value)
    }

    showModal = async() => {
        const modalPagination = {
            page: 1,
            pageSize: 5
        }
        if (this.state.userId === '') {
            message.warn('请选择投手！')
        } else {
            const [providers] = await $get('/providers', { page: 1, pageSize: 1000, userId: this.state.userId }, { hideLoading: true })
            this.setState({ modalPagination, isShow: true, providers })
        }
    }

    modalOk = async () => {
        const selectArr = this.state.selectArr
        if (selectArr.length === 0) {
            message.error('广告主不存在或者未选择任何广告主！')
        } else {
            const res = await $patch('/advertisers/batch-update-outer-visible', { ids: selectArr, outerVisible: true }, { hideLoading: true })
            message.success(`添加成功${res.raw.changedRows}条！`)
            await this.getSelectUser(this.state.userId)
            this.setState({
                selectAdvertisers: []
            })
            this.setState({ isShow: false })
        }
    }

    modalCancel = () => {
        this.setState({
            selectAdvertisers: []
        })
        this.setState({ isShow: false })
    }

    // 对话框表格选择
    tabChange = value => {
        this.setState({ selectArr: value })
    }

    get columns() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '广告主名称',
                dataIndex: 'name',
            },
            {
                title: '服务商名称',
                dataIndex: 'providerName'
            },
            {
                title: '微信名称',
                dataIndex: 'wxname',
            },
            {
                title: '操作',
                render: (text, record) => (
                    <Popconfirm placement="right" title={'您确定移除权限'} onConfirm={this.removeEnter.bind(this, record)} okText="Yes" cancelText="No">
                        <Button danger>移除权限</Button>
                    </Popconfirm>
                )
            }
        ]
        return columns
    }

    get modalColumns() {
        const modalColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: '广告主名称',
                dataIndex: 'name',
            },
            {
                title: '微信名称',
                dataIndex: 'wxname'
            }
        ]
        return modalColumns
    }

    render() {
        return <div className="page-admin-role">
            <div className="title">
                <span>请选择投手：</span>
                <Select style={{ width: 200 }} placeholder="请选择投手" onSelect={this.selectChange} disabled={this.state.loading}>
                    {
                        this.state.users.map((item) => {
                            return (<Option value={item.id} key={item.id}>{item.name}</Option>)
                        })
                    }
                </Select>
                <Button className="button" type="primary" onClick={this.showModal}>添加</Button>
                <Modal
                    title="添加广告主权限"
                    visible={this.state.isShow}
                    onOk={this.modalOk}
                    onCancel={this.modalCancel}
                    okText="添加"
                    width={600}
                    destroyOnClose
                >
                    <span>请选择服务商：</span>
                    <Select style={{ width: 200, marginBottom: 20 }} placeholder="请选择服务商" onSelect={this.addProviderChange}>
                        {
                            this.state.providers.map((item) => {
                                return (<Option value={item.id} key={item.id}>{item.agencyName}</Option>)
                            })
                        }
                    </Select>
                    <Table
                        rowSelection={{ type: 'checkout', onChange: this.tabChange }}
                        columns={this.modalColumns}
                        rowKey={record => `${record.id}`}
                        dataSource={this.state.selectAdvertisers}
                        loading={this.state.loading}
                        pagination={this.state.modalPagination}
                        onChange={this.handleModalTableChange}
                    />
                </Modal>
            </div>
            <Table
                columns={this.columns}
                rowKey={record => record.id}
                dataSource={this.state.advertisers}
                loading={this.state.loading}
            />
        </div>
    }
}