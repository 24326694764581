import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Upload, Button, Result, Spin, message, Switch } from 'antd';
import { $upload, $get } from '../../helpers/remote';
import { UploadOutlined } from '@ant-design/icons';

const TEMPLATE_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/template.xlsx';
const TEMPLATE_PYQ_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/xinxiliu.xlsx';
const TEMPLATE_PYQ_6TU_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/pyq-6tu.xlsx';
const TEMPLATE_PYQ_SP_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/pyq-sp.xlsx';
const TEMPLATE_PYQ_KP_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/pyq-kp.xlsx';
const TEMPLATE_PYQ_GZH_URL = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/pyq-gzh.xlsx';
const TEMPLATE_GDT = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/gdt-template.xlsx';
const TEMPLATE_DIVE_VIDEO = 'https://y1y-src.oss-cn-shanghai.aliyuncs.com/ad-platform/templates/%E6%B2%89%E6%B5%B8%E5%BC%8F%E6%A8%AA%E6%9D%BF%E8%A7%86%E9%A2%91.xlsx';

export default class UploadCampaigns extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        advertiserId: PropTypes.number.isRequired,
        metricId: PropTypes.number.isRequired,
        providerId: PropTypes.string.isRequired,
    }
    state = {
        fileList: [],
        uploading: false,
        results: 0,
        loading: false,
        tokenError: false,
        checkCustomId: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState({
                fileList: [],
                results: 0,
                uploading: false,
                tokenError: false,
            });
            this.fetch();
        }
    }
    fetch = async () => {
        this.setState({ loading: true });
        const { providerId } = this.props;
        try {
            await $get(
                `/providers/${providerId}/status`,
                {},
                { hideLoading: true, throwException: true }
            );
        } catch (e) {
            if (e.response && /服务商用户token过期/.test(e.response.data.message)) {
                this.setState({ tokenError: true });
            } else {
                message.error(e.message);
            }
        }
        this.setState({ loading: false });
    }
    onClose = () => {
        this.props.onClose();
    }
    beforeUpload = file => {
        const { fileList } = this.state;
        const excelIndex = this.excelFileIndex;
        if (/\.xlsx?$/.test(file.name)) {
            if (excelIndex > -1) {
                fileList[excelIndex] = file;
            } else {
                fileList.push(file);
            }
        }
        this.setState({
            fileList,
        });
        return false;
    }
    get excelFileIndex() {
        const { fileList } = this.state;
        let excelIndex = -1;
        fileList.forEach((file, index) => {
            if (/\.xlsx?$/.test(file.name)) {
                excelIndex = index;
            }
        });
        return excelIndex;
    }
    handleUpload = async () => {
        const { providerId, advertiserId, metricId = 0 } = this.props;
        const { fileList, results, checkCustomId } = this.state;
        if (results) {
            return window.location.href = '/jobs';
        }
        const excelIndex = this.excelFileIndex;
        const zipIndex = this.zipFileIndex;
        const data = {
            excel: fileList[excelIndex],
        };
        if (zipIndex > -1) {
            data.zip = fileList[zipIndex];
        }
        this.setState({ uploading: true });
        try {
            const result = await $upload(
                `/providers/${providerId}/campains/excel?advertiserId=${advertiserId}&metricId=${metricId}&filename=${data.excel.name}&checkCustomId=${checkCustomId}`,
                data,
                { hideLoading: true, throwException: true }
            );
            this.setState({ uploading: false, results: result.length });
        } catch (e) {
            this.setState({ uploading: false });
            message.error((e.response && e.response.data && e.response.data.message) || String(e));
        }
    }
    render() {
        const { visible } = this.props;
        const { fileList, uploading, results, loading, tokenError } = this.state;
        return (
            <Modal
                title="添加投放计划"
                okText={results ? '前往查看' : '添加'}
                cancelText="关闭"
                visible={visible}
                onCancel={this.onClose}
                onOk={this.handleUpload}
                okButtonProps={{
                    disabled: this.excelFileIndex === -1,
                    loading: uploading,
                }}
            >
                <Spin spinning={loading}>
                {
                    tokenError ? <Result
                        status="error"
                        title="服务商登陆状态过期"
                        subTitle="请返回扫码更新状态"
                    /> : <div className="page-home-upload">
                        {
                            !results ? <div>
                                <p>
                                    <a href={TEMPLATE_URL} target="_blank" rel="noopener noreferrer">下载模板（通用）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_PYQ_URL} target="_blank" rel="noopener noreferrer">下载模板（朋友圈信息流）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_PYQ_6TU_URL} target="_blank" rel="noopener noreferrer">下载模板（朋友圈6图）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_PYQ_SP_URL} target="_blank" rel="noopener noreferrer">下载模板（朋友圈视频）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_PYQ_KP_URL} target="_blank" rel="noopener noreferrer">下载模板（朋友圈卡片）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_PYQ_GZH_URL} target="_blank" rel="noopener noreferrer">下载模板（朋友圈公众号）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_DIVE_VIDEO} target="_blank" rel="noopener noreferrer">下载模板（沉浸式横板视频）</a>
                                </p>
                                <p>
                                    <a href={TEMPLATE_GDT} target="_blank" rel="noopener noreferrer">下载模板（广点通）</a>
                                </p>
                                <p>自定义ID查重：<Switch onChange={(checked) => this.setState({ checkCustomId: checked })} /></p>
                                <Upload
                                    accept=".xls,.xlsx"
                                    fileList={fileList}
                                    beforeUpload={this.beforeUpload}
                                    disabled={uploading}
                                >
                                    <Button><UploadOutlined />选择Excel文件</Button>
                                </Upload>
                            </div> : <Result
                                status="success"
                                title="投放任务添加成功"
                                subTitle={`已添加${results}条投放任务，稍后可查看结果`} />
                        }
                    </div>
                }
                </Spin>
            </Modal>
        )
    }
}
