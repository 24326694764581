import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Result, Button } from 'antd';
import { $get, $post } from '../../helpers/remote';

export default class AddNewMp extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired
    }
    state = {
        loading: false,
        visible: false,
        providers: [],
        agencyName: null
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetch();
        }
    }
    fetch = async () => {
        this.setState({ loading: true, agencyName: null });
        const providers = await $get('/providers/login/providers/newMp', {}, { hideLoading: true });
        this.setState({ providers, loading: false });
    }
    onClose = () => {
        this.props.onClose(!!this.state.agencyName);
    }
    onSwitchAgency = async (id) => {
        const { providers } = this.state;
        const provider = providers.find(o => Number(o.providerId) === id);
        await $post('/providers/login/switch/newMp', { 
            token: { 
                agencyName: provider.agency_name,
                agency: Number(provider.providerId)
            },
        });
        this.setState({ agencyName: provider.agency_name });
    }
    render() {
        const { visible } = this.props;
        const { providers, agencyName } = this.state;
        return (
            <Modal
                title="添加/更新服务商"
                okText="关闭"
                visible={visible}
                cancelButtonProps={{ style: { display: 'none' }}}
                onCancel={this.onClose}
                onOk={this.onClose}
            >
                <div className="page-home-add">
                    {
                        agencyName && <Result
                            status="success"
                            title="服务商更新成功"
                            subTitle={agencyName}
                        />
                    }
                    {
                        !agencyName && providers.length ? <div>
                            {providers.map(o => <p key={o.provider_account_id}>
                                <Button onClick={() => this.onSwitchAgency(Number(o.providerId))}>
                                    {o.agency_name}-{o.providerId}
                                </Button>
                            </p>)}
                        </div> : null
                    }
                </div>
            </Modal>
        )
    }
}
