import { Button, Form, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import { $get, $post } from '../../helpers/remote';
import ConditionGroup from './condition-group';
import { aiConditions } from './conditions';

export default function StrategyAiEditorForm({ strategyAiId: id, onFinish, visible, setVisible, getStrategyAiChange, copyData, title, isSelf }) {
    const [loading, setLoading] = useState(false);
    const [strategyAi, setStrategyAi] = useState(null);
    const [form] = Form.useForm();
    const [conditions] = useState(aiConditions);
    const [strategyGroups, setStrategyGroups] = useState([]);

    useEffect(() => {
        $get('/strategy-ai/free/strategyGroups').then(groups => {
            setStrategyGroups(groups);
            if (visible && id) {
                $get(`/strategy-ai/${id}`).then(strategyAi => {
                    if (strategyAi.strategyGroups && strategyAi.strategyGroups.length) {
                        setStrategyGroups([
                            ...groups,
                            { id: strategyAi.strategyGroups[0].id, title: strategyAi.strategyGroups[0].title }
                        ]);
                        form.setFieldsValue({ ...form.getFieldsValue(), strategyGroupId: strategyAi.strategyGroups[0].id });
                    }
                    setStrategyAi(strategyAi);
                });
            }
        });
    }, [visible, id, form]);

    const formData = useMemo(() => {
        if (!visible) {
            return null;
        }
        if (!id) {
            form.resetFields();
            if (copyData) {
                return copyData;
            }
            return { title: `新建Ai策略${moment().format('YYYYMMDDHHmmss')}` };
        }
        return strategyAi;
    }, [strategyAi, id, visible, form, copyData]);

    const checkForm = (values) => {
        const { conditionGroups, title, strategyGroupId } = values;
        if (!title) {
            throw new Error('请填写标题');
        }
        const [conditions] = [conditionGroups].map((groups) => {
            return groups.map(group => {
                const key = 'conditions';
                const datas = group[key];
                if (!datas) {
                    throw new Error('请填写完整');
                }
                return {
                    ...group,
                    [key]: datas.map(o => {
                        if (o.error) {
                            throw new Error('表单填写错误');
                        }
                        delete o.error;
                        return o;
                    })
                }
            })
        })
        let strategyGroup;
        if (strategyGroupId) {
            strategyGroup = strategyGroups.find(o => o.id === strategyGroupId);
        }
        return { conditionGroups: conditions, title, strategyGroups: strategyGroup ? [strategyGroup] : null };
    }

    const handleSubmit = async (values) => {
        let datas = {};
        try {
            datas = checkForm(values);
        } catch (err) {
            message.error(err.message);
            return;
        }
        const { conditionGroups } = datas;
        if (!conditionGroups.length) {
            message.error('请选择条件');
            return;
        }
        setLoading(true);
        let strategyAiId = 0;
        if (id) {
            datas.id = id;
        }
        try {
            strategyAiId = await $post('/strategy-ai', datas, { throwException: 'showMessage' });
            setLoading(false);
            setVisible(false);
            onFinish && onFinish();
        } catch (e) {
            setLoading(false);
        }
        const formData = form.getFieldValue();
        if (getStrategyAiChange) {
            const strategyAiData = { ...formData, id: id || strategyAiId };
            getStrategyAiChange(strategyAiData, title);
        }
        form.setFieldsValue(formData);
    }

    return (
        <>
            {(id && !formData) ? null : <div className="ui-strategy-editor">
                <Form initialValues={formData} form={form}>
                    <Form.Item
                        label="标题"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: '请输入标题'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="main">
                        <Form.Item label="条件" name="conditionGroups">
                            <ConditionGroup conditions={conditions} isAi={true} />
                        </Form.Item>
                        <Form.Item label="添加策略分组" name="strategyGroupId">
                            <Select
                                placeholder="请选择策略分组"
                                style={{ width: 200, marginBottom: 10 }}
                                showSearch
                                allowClear
                                filterOption={(input, option) => option.children.indexOf(input) >= 0}
                            >
                                {
                                    strategyGroups.map(o => {
                                        return <Select.Option key={o.id} value={o.id}>{o.title}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    {isSelf && <div>
                        <Button onClick={() => handleSubmit(form.getFieldsValue())} type="primary" loading={loading}>保存</Button>
                    </div>}
                </Form>
            </div>}
        </>
    )
}
